<h3 style="margin-top:130px; "> </h3>
<div class="control-section">
    <div class="col-lg-12">
        <h4 class="text-center text-primary" style="color:rgba(0, 160, 227)">Wygeneruj nowe hasło</h4><h5 style="text-align:center; "></h5>
        <hr>       
        
        <div class="control_wrapper" id="control_wrapper" style="margin: 25px auto;">
    

              <div class="form-group" style="padding-top: 26px;">
               
                    <div id="mobile-no" class="e-float-input" [ngClass]="{'e-success': email.valid}">
                        <input    type="text" id="email" name="email" [(ngModel)]="passWordRemaind.toEmail" #email="ngModel" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                       
                        <label class="e-float-text" [ngClass]="{'e-label-top': passWordRemaind.toEmail !== '' , 'e-label-bottom': passWordRemaind.toEmail == ''}" for="name">Wprowadź adres e-mail podany przy rejestracji</label>                                                
                    </div>
                    <span class="e-error" *ngIf="email.errors && email.errors.required && (email.dirty || email.touched)" >
                        *  Wprowaź adres e-mail
                    </span>
                    <span  class="e-error" *ngIf="email.errors && email.errors.pattern && (email.dirty || email.touched)" >
                        *  Wprowaź poprawny adres e-mail
                    </span>
            
              </div>

            
          
              <div class="form-group" style="padding-top: 11px;">
                <span  style="font-size: 13px;  font-weight: 400;   color: rgba(0, 0, 0, 0.42); ">
                    *  Na podany adres e-mail zostanie przesłany link do zmiany hasła. 
                </span>
                <div class="submitBtn"  style="margin-top:10px;">
                 <!-- <button class="submit-btn e-btn" id="submit-btn"  (click)= "Submit()">Wyślij prośbę zmian</button>-->
                  <button  style="   margin-right: 20px; margin-bottom: 30px;" class="btn btn-success mr-2" (click)="passwordRemaind()" type="submit">Zatwierdź</button> 

                  <div class="desc"><span></span></div>
                </div>
              </div>


         
      </div>
    </div>
</div>