import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map , filter, switchMap, retry, subscribeOn} from 'rxjs/operators';
import { Order } from '../_models/order';
import { User } from '../_models/user';
import { SearchResults } from '../_models/SearchResults'; 
import { company } from '../_models/company';
import { UserRegister } from '../_models/userRegister'; 
import { FileInfo } from '@syncfusion/ej2-angular-inputs';
import { DownLoadedFile } from '../_models/downLoadedFile';
import { ConfigService } from './configService';

 @Injectable({
  providedIn: 'root'
})


export class FilesService {
   baseUrl: string;

  
  constructor(private http: HttpClient, private conf:ConfigService) {  
    this.baseUrl= conf.getApi("baseUrl") ;
   }

   LoadFile(listaZalacznikowId: Number[]): import("@syncfusion/ej2-inputs").FileInfo[] {
     
    let filesData  : import("@syncfusion/ej2-inputs").FileInfo[] 
    listaZalacznikowId.forEach(function (zalacznikId) 
    {
          console.log(zalacznikId); 
 

      this.skasuj = this.orderService.download(zalacznikId)
          .subscribe(  (blob: Blob) => {           

          if(blob instanceof Blob )
          {   
              let f: FileInfo  =  {
              "name": "jak to widzisz to jestes niezły.pdf",
              "rawFile": blob,
              "size": 11091,
              "status": "File uploaded successfully",
              "type": "pdf",
              "validationMessages": {
                  "minSize": "",
                  "maxSize": ""
              },
              "statusCode": "2",
              "id": "zestaw_sym_0.png",
              "fileSource":"" 
              }  ;
            
               filesData.push(f); 
          } 
      }) 
    });
      
    return   filesData;

   }
 

     
      async GetFileList(zlecenieId: Number)  {
           return  await this.http.get<DownLoadedFile[]>(this.baseUrl + 'file/zlecenie/' + zlecenieId).toPromise(); 
          } 
 

      async downloadAll(innerOrderId: any)  {
        return await this.http.get(this.baseUrl + 'file/GetAllAttachments/Order/' + innerOrderId , {
          responseType: 'blob'
        }).toPromise();
      }

      async downloadZlecenie(innerOrderId: any)  {
        return await this.http.get(this.baseUrl + 'file/' + innerOrderId , {
          responseType: 'blob'
        }).toPromise();
      }
 


      async GetattachmentsByZlecenieId(zlecenieId: Number)  {
        const data = await this.http.get<string[]>(this.baseUrl + 'file/getattachmentsbyzlecenieid=' + zlecenieId).toPromise();
        //console.log("Data: " + JSON.stringify(data)); 
        return data;
      } 
}