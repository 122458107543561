import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/_services/account.service';

@Component({
  selector: 'app-email-confirm',
  templateUrl: './email-confirm.component.html',
  styleUrls: ['./email-confirm.component.css']
})
export class EmailConfirmComponent implements OnInit {
  accountConfimed: Boolean;

  constructor(private activatedRoute: ActivatedRoute, private accountService: AccountService) { 


  }

  ngOnInit(): void { 
    
    this.activatedRoute.queryParams.subscribe(async params => {
      let ec = params['ec'];
      let isok = await this.accountService.postemailconfirm(ec)
      
      if(isok as unknown as Boolean == true)
      {
        this.accountConfimed = true;
      }
      else
     {
      this.accountConfimed = false;
     }
        
  });
  }

} 