 
 
 
  <div style="margin-top: 130px;"></div>  <hr> 
  <h4  class="text-center text-primary">Moja flota</h4><h5 style="margin-top: 20px; text-align:center; "></h5>
           
  
  
  <figure class="img-container" style="margin-top: 30px;">

   
      <div  class="control_wrapper" id="control_wrapper" style="margin: 25px auto;">  
          <div   class="container">

            <div class="row" style="margin-top: 25px;">
              <div class="col" style="width:130px;">                         
              
                <img    width="115" height="35" src="/assets/images/zestaw_sym.png?" >                         
          </div>
          <div class="col-5">                        
                     <h4 style="  text-align: center; font-size: 15px;color:#696c77;">JUMBO 7,7+7,7</h4>   <!--Ciągnik trójosiowy-->
               </div>
               <div class="col" style="float:right">   
                    <div class="stepper-input stepper__input">
                        <button class="toggle-less"  (click)="toggleLess_ciagnik_trojosiowy()">
                          <img src="https://angular-stepper-input.stackblitz.io/assets/minus.svg" [attr.alt]="ariaLabelLess" />
                        </button>
                        <div class="stepper-input-field"  aria-atomic="true" aria-live="polite" aria-relevant="all">
                           {{renderedValue_ciagnik_trojosiowy}}  
                        </div>
                        <button class="toggle-more" (click)="toggleMore_ciagnik_trojosiowy()">
                          <img src="https://angular-stepper-input.stackblitz.io/assets/plus.svg" [attr.alt]="ariaLabelMore" />
                        </button>
                    </div> 
                </div>
              </div> 


              <div class="row" style="margin-top: 25px;">
                <div class="col" style="width:130px;">                        
                 
                  <img  width="115" height="35" src="/assets/images/zestaw_niesym.png?" >  
                </div>
                <div class="col-5">                            
                        <h4 style=" text-align: center; font-size: 15px; color:#696c77;">JUMBO 8+7 (38 EUROPALET)</h4>   <!--Ciągnik typu mega-->                            
                  </div>
                  <div class="col" style="float:right">   
                        <div class="stepper-input stepper__input">
                            <button class="toggle-less"  (click)="toggltoggleLess_ciagnik_trojosiowy_typu_mega()">
                              <img src="https://angular-stepper-input.stackblitz.io/assets/minus.svg" [attr.alt]="ariaLabelLess" />
                            </button>
                            <div class="stepper-input-field"  aria-atomic="true" aria-live="polite" aria-relevant="all">
                               {{renderedValue_ciagnik_typu_mega}}  
                            </div>
                            <button class="toggle-more" (click)="toggleMore_ciagnik_trojosiowy_typu_mega()">
                              <img src="https://angular-stepper-input.stackblitz.io/assets/plus.svg" [attr.alt]="ariaLabelMore" />
                            </button>
                        </div> 
                    </div>
              </div> 


              <div class="row" style="margin-top: 25px; margin-bottom: 30px;">
                <div class="col" style="width:130px;"> 
               
               
                  <img  width="115" height="35" src="/assets/images/zestaw_niesym.png?" >  
                </div>
                <div class="col-5"> 
                         <h4 style=" text-align: center; font-size: 15px; color:#696c77;">JUMBO 9,1+6,3</h4>  <!--Ciągnik typu mega</h4><p>regul. wysokość sidła)-->
                     </div>
                     <div class="col" style="float:right">  
                        <div class="stepper-input stepper__input">
                            <button class="toggle-less"  (click)="toggltoggleLess_ciagnik_trojosiowy_typu_mega_regul()">
                              <img src="https://angular-stepper-input.stackblitz.io/assets/minus.svg" [attr.alt]="ariaLabelLess" />
                            </button>
                            <div class="stepper-input-field"  aria-atomic="true" aria-live="polite" aria-relevant="all">
                               {{renderedValue_ciagnik_typu_mega_regul}}  
                            </div>
                            <button class="toggle-more" (click)="toggleMore_ciagnik_trojosiowy_typu_mega_regul()">
                              <img src="https://angular-stepper-input.stackblitz.io/assets/plus.svg" [attr.alt]="ariaLabelMore" />
                            </button>
                        </div> 
                    </div> 
               </div> 


            <div class="row" style="margin-top: 25px;">

              <div class="col" style="width:130px; ">                                
                <img  width="115" height="35" src="/assets/images/mega.png?" >
              </div> 

              <div class="col-5"> 
                <h4 style=" text-align: center; font-size: 15px; color:#696c77;">STANDARD</h4>  <!--Ciągnik typu mega</h4><p>regul. wysokość sidła)-->
            </div>
                  <div class="col" style="float:right">  
                      <div class="stepper-input stepper__input">
                          <button class="toggle-less"  (click)="toggleLess_ciagnik_siodlowy()">
                            <img src="https://angular-stepper-input.stackblitz.io/assets/minus.svg" [attr.alt]="ariaLabelLess" />
                          </button>
                          <div class="stepper-input-field"  aria-atomic="true" aria-live="polite" aria-relevant="all">
                             {{renderedValue_ciagnik_siodlowy}}  
                          </div>
                          <button class="toggle-more" (click)="toggleMore_ciagnik_siodlowy()">
                            <img src="https://angular-stepper-input.stackblitz.io/assets/plus.svg" [attr.alt]="ariaLabelMore" />
                          </button>
                      </div> 
                  </div>
            </div> 

          


                      <div class="row" style="margin-top: 25px; margin-bottom: 30px;">
                        <div class="col" style="width:130px;"> 
                          <img  width="115" height="35" src="/assets/images/mega_standard.png?" >                         

                        </div>
                        <div class="col-5"> 
                                 <h4 style=" text-align: center; font-size: 15px; color:#696c77;">MEGA (3m)</h4>  <!--Ciągnik typu mega</h4><p>regul. wysokość sidła)-->
                             </div>
                             <div class="col" style="float:right">  
                                <div class="stepper-input stepper__input">
                                    <button class="toggle-less"  (click)="toggltoggleLess_mega3m()">
                                      <img src="https://angular-stepper-input.stackblitz.io/assets/minus.svg" [attr.alt]="ariaLabelLess" />
                                    </button>
                                    <div class="stepper-input-field"  aria-atomic="true" aria-live="polite" aria-relevant="all">
                                       {{renderedValue_mega3m}}  
                                    </div>
                                    <button class="toggle-more" (click)="toggleMore_mega3m()">
                                      <img src="https://angular-stepper-input.stackblitz.io/assets/plus.svg" [attr.alt]="ariaLabelMore" />
                                    </button>
                                </div> 
                            </div> 
                          </div> 
 
                      <div class="row justify-content-end">
                        <div class="col-3">
                          <button  style="float:right; margin-bottom: 20px;" class="btn btn-success mr-2" (click)="aktualizujFlote()" type="submit">Zatwierdź</button> 

                        </div>
                    </div>
          </div> 


      </div> 

   
  </figure>
 
 