<h4 style="margin-top:130px; "> </h4> <hr>  
<h4 class="text-center text-primary">Transporty</h4><h5 style="text-align:center; "></h5> 
  
<ejs-tab id="element"  overflowMode='Popup'  (selecting)="select($event)">
  <e-tabitems>
    <e-tabitem [header]='headerText[0]'>
      <ng-template #content> 

        <ejs-grid #gridOpen [dataSource]='dataplaced'  (rowDataBound)="rowDataBound($event)"
  [allowSorting]='true'   [allowPdfExport]='true' [toolbar]='toolbar'  
  [allowFiltering]='true' [pageSettings]='pageSettings' [allowPaging]='true' [filterSettings]='filterOptions' 
  [allowTextWrap]='true' [textWrapSettings]='wrapSettings' [sortSettings]='sortOptions' [showColumnChooser]= 'true'  
  [editSettings]='editSettings' 
[allowGrouping]='false' 
[allowExcelExport]='true'  
  (queryCellInfo)='queryCellInfoEvent($event)' 
  (rowSelected)='rowSelected($event)'
  (actionBegin)='actionBegin($event)' 
  (commandClick)='onOpenDialog($event)' 
  (actionComplete)="actionComplete($event)" 
  (enablePersistence) = 'true'
  > 
      <ng-template #detailTemplate let-data>
          <table class="detailtable"  >
              <colgroup>
                  <col width="100px">
                  <col width="380px">
                  <col width="150px">
                  <col width="150px">
                  <col width="150px">

              </colgroup>
              <tr>              
              </tr>
              <tbody >
                <tr>             
                  <th   >Opcja</th>
             
                  <th  >Lokalizacja</th>                
                  <th [colSpan]="2"  >Okienko odbioru/dostawy</th>
                  <th>zakres</th>
                </tr>
                <tr *ngFor="let hero of data.expanded" >                   
                  <td>    {{hero.typ}}
                    </td>
              
                <td>
                 {{hero.lokalizacja}} 
                </td>
                <td>
                  {{hero.data_}} 
                </td>
                <td>
                 {{hero.data_Godz}}  
                </td> 
                <td>
              {{hero.data_Godz_Od}}  -  {{hero.data_Godz_Do}}  
                 </td>  
                </tr>  
            </tbody>
          </table>
      </ng-template>
      <e-columns>
         <!-- <e-column headerText='Rozwiń' width='99' textAlign='Left' >
            <ng-template #template let-data>
                  <button  ejs-button cssClass="e-info" (click)="clicked($event)">Rozwiń</button>
              </ng-template>
          </e-column> -->

          <e-column *ngIf="accountService.currentAdminSource$ | async" headerText="Negocjuj" textAlign="Center" width="220" >
            <ng-template #template let-data>
               <div  *ngIf="data.isNegotiation == 5">
                  Zaakceptowano
                </div>


               <div  *ngIf="data.isNegotiation == 1"> 
                 <button   content="Odrzucono"   ejs-button  cssClass="e-link"   (click)="negotiate(data)" ></button>
               </div>


               <div  *ngIf="data.isNegotiation == 0"> 
                <button  content="Negocjuj"   ejs-button  cssClass="e-link"   (click)="negotiate(data)" ></button>
              </div> 

              <div  *ngIf="data.isNegotiation == 2"> 
                Oczekiwanie na decyzję
              </div>

             </ng-template>
           </e-column>

        <e-column   [customAttributes]='customAttributesheader' field='zlecenieId' headerText='Zlecenie' textAlign='Left' width=125></e-column>

      
       
        <e-column *ngIf="accountService.currentAdminSource$ | async"
          field="firma"
          headerText="Przewoźnik"
          textAlign="Center"
          width="220"
        >
         <ng-template #template let-data>
            <div>
              <button content="{{data.firma}}"   ejs-button  cssClass="e-link"   (click)="pokazButa(1, $event, data.orderId)" ></button>
            </div>
          </ng-template>
        </e-column>
        <e-column field='offerdate'  [valueAccessor]='valueAccess'  headerText='Data' textAlign='Left'   width=145></e-column>        
        
        <e-column field='data_Godz' headerText='Godz ZA FIX' [disableHtmlEncode]="false" width='150'></e-column> 
        <e-column field='data_Godz_Od' headerText='Zakres ZA'    [disableHtmlEncode]="false" width='150'></e-column>  
        <e-column field='data_WY'  [valueAccessor]='valueAccessDataWy' headerText='Data WY' [disableHtmlEncode]="false" width='140'></e-column> 
        <e-column field='data_Godz_WY' headerText='Godz WY FIX' [disableHtmlEncode]="false" width='150'></e-column> 
        <e-column field='data_Godz_Do' headerText='Zakres WY'    [disableHtmlEncode]="false" width='140'></e-column> 
        
        <e-column field='trasa' headerText='Trasa' [disableHtmlEncode]="false" width='250'></e-column> 
        <e-column field='cena' *ngIf="accountService.currentAdminSource$ | async" headerText='Kwota K' textAlign='Center'  width='140' [valueAccessor]='currencyFormatter2'   > </e-column> 

        <e-column field='oferowanaCena'  headerText='Proponowana kwota' textAlign='Center'   [valueAccessor]='currencyFormatter1'  width=200> </e-column> 
      
        <e-column aria-rowspan="2" field='komentarz' headerText='Komentarz' textAlign='Left'  width='245' >
          <ng-template #template let-data>  
            <div   data-delay='{"show":"800", "hide":"300"}'
            data-toggle="tooltip" data-placement="Left" title="{{data.komentarz}}">                                          
            <p>{{data.komentarz | slice:0:45}}</p> 
            </div>        
        </ng-template>                
      </e-column> 
          <e-column field='skonto' headerText='Skonto' textAlign='Right'  width='130'   editType= 'booleanedit' displayAsCheckBox="true" textAlign='Center'> </e-column> 
          
    
       <e-column   field='spedytor' headerText='Spedytor' width=170></e-column>
   <e-column *ngIf="!(accountService.currentAdminSource$ | async)"  field='spedytorEmail' headerText='Spedytor  e-mail' width=220></e-column>
   <e-column *ngIf="!(accountService.currentAdminSource$ | async)"  field='spedytorTel' headerText='Spedytor Tel' width=170></e-column>

   <e-column    field='userName' headerText='Użytkownik' width=170></e-column>
     <e-column   *ngIf="(accountService.currentAdminUserSource$ | async) || (accountService.currentAdminSource$ | async)" field='userEmail' headerText='E-mail' width=240></e-column>
     <e-column  *ngIf="(accountService.currentAdminUserSource$ | async) || (accountService.currentAdminSource$ | async)"  field='userKom' headerText='Telefon' width=170></e-column>         
     <e-column   field='status' headerText='Status' width=170 *ngIf="accountService.currentAdminSource$ | async"></e-column>          
       </e-columns>
      <ng-template #editSettingsTemplate let-data >     
        <div ngForm #orderForm="ngForm" style="width: 300px;">
          <div class="form-row" style="margin-top:15px;">        
                <div class="e-float-input" >
                    <input     type="text" id="zlecenieId" name="zlecenieId" [(ngModel)]="data.zlecenieId"   readonly >
                    <label class="e-float-text e-label-top" for="zlecenieId">zlecenie Id</label> 
                </div> 
          </div>
          <div class="form-row" style="margin-top:15px;">
            <div class="e-float-input" >
                <input     type="text" id="firmaId" name="firmaId" [(ngModel)]="data.firma"   readonly >
                <label class="e-float-text e-label-top" for="firmaId">Przewoźnik</label> 
            </div> 
        </div>
        <div class="form-row" style="margin-top:15px;">
          <div class="e-float-input" >
              <input     type="text" id="lokalizacjaId" name="lokalizacjaId" [(ngModel)]="data.lokalizacja"   readonly >
              <label class="e-float-text e-label-top" for="lokalizacjaId">lokalizacja</label> 
          </div> 
        </div>
        <div class="form-row" style="margin-top:15px;">
          <div class="e-float-input" >
              <input     type="text" id="offerdateId" name="offerdateId" [(ngModel)]="data.offerdate"   readonly >
              <label class="e-float-text e-label-top" for="offerdateId">Data</label> 
          </div> 
        </div>
        <div class="form-row" style="margin-top:15px;" *ngIf="accountService.currentAdminSource$ | async">
          <div class="e-float-input" >
            <div class="form-row">
              <div class="col">
              <input     type="number" id="oferowanaCenaId" name="oferowanaCenaId" [(ngModel)]="data.oferowanaCena"  [valueAccessor]='currencyFormatter4'    >
              <label class="e-float-text e-label-top" for="oferowanaCenaId">Proponowana kwota</label> 
            </div>
            <div class="col">
              <input  type="text"  id="walutaId" name="walutaId" [(ngModel)]="data.waluta"    readonly >
              <label class="e-float-text e-label-top" for="walutaId">Waluta</label> 
            </div>
            </div> 
          </div> 
        </div>

        <div class="form-row" style="margin-top:15px;" *ngIf="accountService.currentAdminSource$ | async">
          <div class="e-float-input" >
            <div class="form-row">
              <div class="col">
              <input     type="number" id="cenaId" name="cenaId" [(ngModel)]="data.cena"  [valueAccessor]='currencyFormatter5'    >
              <label class="e-float-text e-label-top" for="cenaId">Kwota K</label> 
            </div>
            <div class="col">
              <input  type="text"  id="walutaId" name="walutaId" [(ngModel)]="data.waluta"    readonly >
              <label class="e-float-text e-label-top" for="walutaId">Waluta</label> 
            </div>
            </div> 
          </div> 
        </div>
        <div class="form-row" style="margin-top:15px;" *ngIf="accountService.currentAdminSource$ | async">
          <div class="e-float-input" >
            <div class="form-row">
              <div class="col">
              <input     type="number" id="kupTerazId" name="kupTerazId" [(ngModel)]="data.kupTeraz"  [valueAccessor]='currencyFormatter6'    >
              <label class="e-float-text e-label-top" for="kupTerazId">Kwota P</label> 
            </div>
            <div class="col">
              <input  type="text"  id="walutaId" name="walutaId" [(ngModel)]="data.waluta"    readonly >
              <label class="e-float-text e-label-top" for="walutaId">Waluta</label> 
            </div>
            </div>              
          </div> 
        </div>
        <div class="form-row" style="margin-top:15px;" *ngIf="accountService.currentAdminSource$ | async">
          <div class="e-float-input" >
            <div class="form-row">
              <div class="col">
                <ejs-checkbox #wcheckbox label="Zaakceptowane"  (change)="changeOrderAccept($event)"  [checked]="acceptedDialog"></ejs-checkbox>  
              </div>
            <div class="col">
              <ejs-checkbox     label="Odrzucone"  (change)="changeOrderReject($event)"  [checked]="rejectedDialog"></ejs-checkbox>  

            </div>
            </div>              
          </div> 
        </div>
        <div class="form-row" style="margin-top:15px;">
          <div class="e-float-input" >
              <input     type="text" id="spedytorId" name="spedytorId" [(ngModel)]="data.spedytor"  [valueAccessor]='currencyFormatter6'  readonly >
              <label class="e-float-text e-label-top" for="spedytorId">Spedytor</label> 
          </div> 
        </div>
        </div>
    </ng-template>
          </ejs-grid> 

      </ng-template>
</e-tabitem>
<e-tabitem [header]='headerText[1]'>
  <ng-template #content> 
   
    <ejs-grid #gridAccepted [dataSource]='dataaccepted'  (rowDataBound)="rowDataBoundColor($event)" 
    [allowSorting]='true'   [allowPdfExport]='true' [toolbar]='toolbar'   [filterSettings]='filterOptions'
  [allowFiltering]='true' [pageSettings]='pageSettings' [allowPaging]='true'  
  [allowTextWrap]='true' [textWrapSettings]='wrapSettings'   [showColumnChooser]= 'true'  
  [editSettings]='editSettings' 
[allowGrouping]='false' 
[allowExcelExport]='true'  
  (queryCellInfo)='queryCellInfoEvent($event)' 
  (rowSelected)='rowSelected($event)'
  (actionBegin)='actionBegin($event)' 
  (commandClick)='onOpenDialog($event)' 
  (actionComplete)="actionComplete($event)"
  
    > 
        <ng-template #detailTemplate let-data>
          <table class="detailtable" >
            <colgroup>
              <col width="100px">
                  <col width="120px">
                  <col width="380px">
                  <col width="150px">
                  <col width="150px">
                  <col width="150px">
                </colgroup>
                <tr>              
                </tr>
                <tbody >
                  <tr>             
                    <th   >Opcja</th>
                    <th   >Ilość statusów</th>
                    <th  >Lokalizacja</th>                
                    <th [colSpan]="2"  >Okienko odbioru/dostawy</th>
                    <th>zakres</th>
                  </tr>
                  <tr *ngFor="let hero of data.expanded" >                   
                    <td> 
                          <button  [disabled]="!data.zaakceptowane && !(accountService.currentAdminSource$ | async)" style="font-size: 12px !important; width: 70px;"  (click)="setStatusDateTime(2, $event, hero.iD_ZLECENIA_ZA_WY, hero)" ejs-button cssClass="e-info">{{hero.typ}}</button>
                     </td>
                  <td>
                  {{hero.qtyDate}}  
                </td>
                  <td>
                   {{hero.lokalizacja}} 
                  </td>
                  <td>
                    {{hero.data_}} 
                  </td>
                  <td>
                   {{hero.data_Godz}}  
                  </td> 
                  <td>
                {{hero.data_Godz_Od}}  -  {{hero.data_Godz_Do}}  
                   </td> 
                  </tr>  
              </tbody>
            </table>
        </ng-template>
        <e-columns>
           <!-- <e-column headerText='Rozwiń' width='99' textAlign='Left' >
              <ng-template #template let-data>
                    <button  ejs-button cssClass="e-info" (click)="clicked($event)">Rozwiń</button>
                </ng-template>
            </e-column> -->
          <e-column   [customAttributes]='customAttributesheader' field='zlecenieId' headerText='Zlecenie' textAlign='Left' width=125></e-column>
          

        <e-column
        field="viewdetails2"
        headerText="Dokument"
        textAlign="Center"
        width="140"  >
        <ng-template #template let-data>
          <div  > 
            <button [disabled]="!data.isAttachment" content="Pobierz" cssClass="e-primary" 
            style="height: 26px; width: 80px; padding: 3px; font-size:12px; border-radius: 0.15rem;"  (click)="DownloadPdfFile($event, data.zlecenieId)" ejs-button class="btn btn-danger"></button>
          </div>
        </ng-template>
      </e-column>

          <e-column *ngIf="accountService.currentAdminSource$ | async"
            field="firma"
            headerText="Przewoźnik"
            textAlign="Center"
            width="220"
          >
           <ng-template #template let-data>
              <div>
                <button content="{{data.firma}}"   ejs-button  cssClass="e-link"   (click)="pokazButa(2, $event, data.orderId)" ></button>
              </div>
            </ng-template>
          </e-column>
          <e-column field='trasa' headerText='Trasa' [disableHtmlEncode]="false" width='250'></e-column> 
          <e-column  format= "#%" field='qtyDate' [disableHtmlEncode]="false" headerText='Postęp'  width='130'></e-column> 
          <e-column field='data_'  [valueAccessor]='valueAccessData' headerText='Data ZA' [disableHtmlEncode]="false" width='140'></e-column> 
          <e-column field='data_Godz' headerText='Godz ZA FIX' [disableHtmlEncode]="false" width='150'></e-column> 
          <e-column field='data_Godz_Od' headerText='Zakres ZA'    [disableHtmlEncode]="false" width='150'></e-column>  
          <e-column field='data_WY'  [valueAccessor]='valueAccessDataWy' headerText='Data WY' [disableHtmlEncode]="false" width='140'></e-column> 
          <e-column field='data_Godz_WY' headerText='Godz WY FIX' [disableHtmlEncode]="false" width='150'></e-column> 
          <e-column field='data_Godz_Do' headerText='Zakres WY'    [disableHtmlEncode]="false" width='140'></e-column> 
         
 
          
          
          <e-column field='cena' *ngIf="accountService.currentAdminSource$ | async" headerText='Kwota K' textAlign='Center'  width='140' [valueAccessor]='currencyFormatter2'   > </e-column> 

            <e-column field='oferowanaCena'  headerText='Proponowana kwota' textAlign='Center'   [valueAccessor]='currencyFormatter1'  width=220> </e-column> 
            <e-column field='kupTeraz' *ngIf="accountService.currentAdminSource$ | async"  headerText='Kwota P' textAlign='Center'  width='140' [valueAccessor]='currencyFormatter3'   > </e-column> 
            <e-column aria-rowspan="2" field='komentarz' headerText='Komentarz' textAlign='Left'  width='245' >
              <ng-template #template let-data>  
                <div   data-delay='{"show":"800", "hide":"300"}'
                data-toggle="tooltip" data-placement="Left" title="{{data.komentarz}}">                                          
                <p>{{data.komentarz | slice:0:45}}</p> 
                </div>        
            </ng-template>                
          </e-column> 
           
            <e-column field='skonto' headerText='Skonto' textAlign='Right'  width='130'   editType= 'booleanedit' displayAsCheckBox="true" textAlign='Center'> </e-column> 
              
       <e-column
         field="viewdetails2"
         headerText="Załączniki"
         textAlign="Center"
         width="140"   >
         <ng-template #template let-data>
           <div>
             <button   style="height: 26px; width: 100px; padding: 3px; font-size:12px; border-radius: 0.15rem;"  [disabled]="!data.zaakceptowane && !(accountService.currentAdminSource$ | async)" content="Załączniki"  cssClass="e-warning"   (click)="dodajzalaczniki($event, data.zlecenieId)" ejs-button class="btn btn-danger"></button>
           </div>
         </ng-template>
        </e-column>
         <e-column   field='spedytor' headerText='Spedytor' width=170></e-column>
     <e-column *ngIf="!(accountService.currentAdminSource$ | async)"  field='spedytorEmail' headerText='Spedytor  e-mail' width=220></e-column>
     <e-column *ngIf="!(accountService.currentAdminSource$ | async)"  field='spedytorTel' headerText='Spedytor Tel' width=170></e-column>
  
     <e-column    field='userName' headerText='Użytkownik' width=170></e-column>
     <e-column   *ngIf="(accountService.currentAdminUserSource$ | async) || (accountService.currentAdminSource$ | async)" field='userEmail' headerText='E-mail' width=240></e-column>
     <e-column  *ngIf="(accountService.currentAdminUserSource$ | async) || (accountService.currentAdminSource$ | async)"  field='userKom' headerText='Telefon' width=170></e-column>         
     <e-column   field='status' headerText='Status' width=170 *ngIf="accountService.currentAdminSource$ | async"></e-column>         
    
        </e-columns>


        <ng-template #editSettingsTemplate let-data >     
          <div ngForm #orderForm="ngForm" style="width: 300px;">
            <div class="form-row" style="margin-top:15px;">        
                  <div class="e-float-input" >
                      <input     type="text" id="zlecenieId" name="zlecenieId" [(ngModel)]="data.zlecenieId"   readonly >
                      <label class="e-float-text e-label-top" for="zlecenieId">zlecenie Id</label> 
                  </div> 
            </div>
            <div class="form-row" style="margin-top:15px;">
              <div class="e-float-input" >
                  <input     type="text" id="firmaId" name="firmaId" [(ngModel)]="data.firma"   readonly >
                  <label class="e-float-text e-label-top" for="firmaId">Przewoźnik</label> 
              </div> 
          </div>
          <div class="form-row" style="margin-top:15px;">
            <div class="e-float-input" >
                <input     type="text" id="lokalizacjaId" name="lokalizacjaId" [(ngModel)]="data.lokalizacja"   readonly >
                <label class="e-float-text e-label-top" for="lokalizacjaId">lokalizacja</label> 
            </div> 
          </div>
          <div class="form-row" style="margin-top:15px;">
            <div class="e-float-input" >
                <input     type="text" id="offerdateId" name="offerdateId" [(ngModel)]="data.offerdate"   readonly >
                <label class="e-float-text e-label-top" for="offerdateId">Data</label> 
            </div> 
          </div>
          <div class="form-row" style="margin-top:15px;" *ngIf="accountService.currentAdminSource$ | async">
            <div class="e-float-input" >
              <div class="form-row">
                <div class="col">
                <input     type="number" id="oferowanaCenaId" name="oferowanaCenaId" [(ngModel)]="data.oferowanaCena"  [valueAccessor]='currencyFormatter4'    >
                <label class="e-float-text e-label-top" for="oferowanaCenaId">Proponowana kwota</label> 
              </div>
              <div class="col">
                <input  type="text"  id="walutaId" name="walutaId" [(ngModel)]="data.waluta"    readonly >
                <label class="e-float-text e-label-top" for="walutaId">Waluta</label> 
              </div>
              </div> 
            </div> 
          </div>
  
          <div class="form-row" style="margin-top:15px;" *ngIf="accountService.currentAdminSource$ | async">
            <div class="e-float-input" >
              <div class="form-row">
                <div class="col">
                <input     type="number" id="cenaId" name="cenaId" [(ngModel)]="data.cena"  [valueAccessor]='currencyFormatter5'    >
                <label class="e-float-text e-label-top" for="cenaId">Kwota K</label> 
              </div>
              <div class="col">
                <input  type="text"  id="walutaId" name="walutaId" [(ngModel)]="data.waluta"    readonly >
                <label class="e-float-text e-label-top" for="walutaId">Waluta</label> 
              </div>
              </div> 
            </div> 
          </div>
          <div class="form-row" style="margin-top:15px;" *ngIf="accountService.currentAdminSource$ | async">
            <div class="e-float-input" >
              <div class="form-row">
                <div class="col">
                <input     type="number" id="kupTerazId" name="kupTerazId" [(ngModel)]="data.kupTeraz"  [valueAccessor]='currencyFormatter6'    >
                <label class="e-float-text e-label-top" for="kupTerazId">Kwota P</label> 
              </div>
              <div class="col">
                <input  type="text"  id="walutaId" name="walutaId" [(ngModel)]="data.waluta"    readonly >
                <label class="e-float-text e-label-top" for="walutaId">Waluta</label> 
              </div>
              </div>              
            </div> 
          </div>
          <div class="form-row" style="margin-top:15px;" *ngIf="accountService.currentAdminSource$ | async">
            <div class="e-float-input" >
              <div class="form-row">
                <div class="col">
                  <ejs-checkbox #wcheckbox label="Zaakceptowane"  (change)="changeOrderAccept($event)"  [checked]="acceptedDialog"></ejs-checkbox>  
                </div>
              <div class="col">
                <ejs-checkbox     label="Odrzucone"  (change)="changeOrderReject($event)"  [checked]="rejectedDialog"></ejs-checkbox>  
  
              </div>
              </div>              
            </div> 
          </div>
          <div class="form-row" style="margin-top:15px;">
            <div class="e-float-input" >
                <input     type="text" id="spedytorId" name="spedytorId" [(ngModel)]="data.spedytor"  [valueAccessor]='currencyFormatter6'  readonly >
                <label class="e-float-text e-label-top" for="spedytorId">Spedytor</label> 
            </div> 
          </div>
          </div>
      </ng-template>
        
    </ejs-grid>
    
  </ng-template> 
</e-tabitem>
<e-tabitem [header]='headerText[2]'>
  <ng-template #content> 

    <ejs-grid #gridRejected [dataSource]='datarejected' id='gridRejected' (rowDataBound)="rowDataBound($event)" 
    [allowSorting]="true"   [allowPdfExport]="true" [toolbar]='toolbar'  
    [allowFiltering]='true' [pageSettings]='pageSettings' [allowPaging]="true" [filterSettings]='filterOptions' 
    allowTextWrap='true' [textWrapSettings]='wrapSettings' [sortSettings]='sortOptions' 
    [showColumnChooser]= 'true' (enablePersistence) = 'true'  >   
        <e-columns> 
 

          <e-column   [customAttributes]='customAttributesheader' field='zlecenieId' headerText='Zlecenie' textAlign='Left' width=125></e-column>
           
        <e-column *ngIf="accountService.currentAdminSource$ | async" field="firma" headerText="Przewoźnik" textAlign="Center"  width="220"></e-column> 
        
          <e-column field='offerdate'  [valueAccessor]='valueAccess'  headerText='Data' textAlign='Left'   width=145></e-column>          
          <e-column field='trasa' headerText='Trasa' [disableHtmlEncode]="false" width='250'></e-column> 
          <e-column aria-rowspan="2" field='komentarz' headerText='Komentarz' textAlign='Left'  width='245' >
            <ng-template #template let-data>  
              <div   data-delay='{"show":"800", "hide":"300"}'
              data-toggle="tooltip" data-placement="Left" title="{{data.komentarz}}">                                          
              <p>{{data.komentarz | slice:0:45}}</p> 
              </div>        
          </ng-template>                
        </e-column>  
         <e-column field='oferowanaCena'  headerText='Proponowana kwota' textAlign='Center'   [valueAccessor]='currencyFormatter1'  width=190> </e-column> 
            <e-column field='cena' *ngIf="accountService.currentAdminSource$ | async" headerText='Kwota K' textAlign='Center'  width='140' [valueAccessor]='currencyFormatter2'   > </e-column> 
            <e-column field='kupTeraz' *ngIf="accountService.currentAdminSource$ | async"  headerText='Kwota P' textAlign='Center'  width='140' [valueAccessor]='currencyFormatter3'   > </e-column> 
            <e-column field='skonto' headerText='Skonto' textAlign='Right'  width='140'   editType= 'booleanedit' displayAsCheckBox="true" textAlign='Center'> </e-column> 
                 
         <e-column   field='spedytor' headerText='Spedytor' width=170></e-column>
     <e-column *ngIf="!(accountService.currentAdminSource$ | async)"  field='spedytorEmail' headerText='Spedytor  e-mail' width=220></e-column>
     <e-column *ngIf="!(accountService.currentAdminSource$ | async)"  field='spedytorTel' headerText='Spedytor Tel' width=170></e-column>
  
     <e-column    field='userName' headerText='Użytkownik' width=170></e-column>
     <e-column   *ngIf="(accountService.currentAdminUserSource$ | async) || (accountService.currentAdminSource$ | async)" field='userEmail' headerText='E-mail' width=240></e-column>
     <e-column  *ngIf="(accountService.currentAdminUserSource$ | async) || (accountService.currentAdminSource$ | async)"  field='userKom' headerText='Telefon' width=170></e-column>         
     <e-column   field='status' headerText='Status' width=170 *ngIf="accountService.currentAdminSource$ | async"></e-column>          
     
        </e-columns>
     
    </ejs-grid>
    
  </ng-template> 
</e-tabitem>
<e-tabitem [header]='headerText[3]'>
  <ng-template #content> 

    <ejs-grid #gridArch [dataSource]='dataarchiwum' id='GridArch'  (rowDataBound)="rowDataBound($event)"
    [allowSorting]="true"   [allowPdfExport]="true" [toolbar]='toolbar'  
    [allowFiltering]='true' [pageSettings]='pageSettings' [allowPaging]="true" [filterSettings]='filterOptions' 
    allowTextWrap='true' [textWrapSettings]='wrapSettings' [sortSettings]='sortOptions'
     [showColumnChooser]= 'true' (enablePersistence) = 'true' > 
        <ng-template #detailTemplate let-data>
          <table class="detailtable" >
            <colgroup>
              <col width="100px">
                  <col width="120px">
                  <col width="380px">
                  <col width="150px">
                  <col width="150px">
                </colgroup>
                <tr>              
                </tr>
                <tbody >
                  <tr>             
                    <th   >Opcja</th>
                    <th   >Ilość statusów</th>
                    <th  >Lokalizacja</th>                
                    <th [colSpan]="2"  >Okienko odbioru/dostawy</th>
                  </tr>
                  <tr *ngFor="let hero of data.expanded" >                   
                    <td> 
                           {{hero.typ}}  
                        </td>
                  <td>
                  {{hero.qtyDate}}  
                </td>
                  <td>
                   {{hero.lokalizacja}} 
                  </td>
                  <td>
                    {{hero.data_}} 
                  </td>
                  <td>
                   {{hero.data_Godz}} 
                  </td> 
                  </tr>  
              </tbody>
            </table>
        </ng-template>
        <e-columns>
           <!-- <e-column headerText='Rozwiń' width='99' textAlign='Left' >
              <ng-template #template let-data>
                    <button  ejs-button cssClass="e-info" (click)="clicked($event)">Rozwiń</button>
                </ng-template>
            </e-column> -->
          <e-column   [customAttributes]='customAttributesheader' field='zlecenieId' headerText='Zlecenie' textAlign='Left' width=125></e-column>
      

        <e-column *ngIf="accountService.currentAdminSource$ | async" field="firma" headerText="Przewoźnik" textAlign="Center"  width="220"></e-column> 
          <e-column field='offerdate'  [valueAccessor]='valueAccess'  headerText='Data' textAlign='Left'   width=145></e-column>          
          <e-column field='trasa' headerText='Trasa' [disableHtmlEncode]="false" width='250'></e-column> 
            
          
          
            <e-column aria-rowspan="2" field='komentarz' headerText='Komentarz' textAlign='Left'  width='245' >
              <ng-template #template let-data>  
                <div   data-delay='{"show":"800", "hide":"300"}'
                data-toggle="tooltip" data-placement="Left" title="{{data.komentarz}}">                                          
                <p>{{data.komentarz | slice:0:45}}</p> 
                </div>        
            </ng-template>                
          </e-column> 


          

            <e-column field='oferowanaCena'  headerText='Proponowana kwota' textAlign='Center'   [valueAccessor]='currencyFormatter1'  width=160> </e-column> 
            <e-column field='cena' *ngIf="accountService.currentAdminSource$ | async" headerText='Kwota K' textAlign='Center'  width='120' [valueAccessor]='currencyFormatter2'   > </e-column> 
            <e-column field='kupTeraz' *ngIf="accountService.currentAdminSource$ | async"  headerText='Kwota P' textAlign='Center'  width='120' [valueAccessor]='currencyFormatter3'   > </e-column> 
            <e-column field='skonto' headerText='Skonto' textAlign='Right'  width='130'   editType= 'booleanedit' displayAsCheckBox="true" textAlign='Center'> </e-column> 
                 
         <e-column   field='spedytor' headerText='Spedytor' width=170></e-column>
     <e-column *ngIf="!(accountService.currentAdminSource$ | async)"  field='spedytorEmail' headerText='Spedytor  e-mail' width=220></e-column>
     <e-column *ngIf="!(accountService.currentAdminSource$ | async)"  field='spedytorTel' headerText='Spedytor Tel' width=170></e-column>
  
     <e-column    field='userName' headerText='Użytkownik' width=170></e-column>
     <e-column   *ngIf="(accountService.currentAdminUserSource$ | async) || (accountService.currentAdminSource$ | async)" field='userEmail' headerText='E-mail' width=240></e-column>
     <e-column  *ngIf="(accountService.currentAdminUserSource$ | async) || (accountService.currentAdminSource$ | async)"  field='userKom' headerText='Telefon' width=170></e-column>         
     <e-column   field='status' headerText='Status' width=170 *ngIf="accountService.currentAdminSource$ | async"></e-column>         

        </e-columns>
         
    </ejs-grid>

  </ng-template> 
</e-tabitem>
</e-tabitems>
</ejs-tab>


<div #container class='root-container'>  
                        
     <!--  statusy -->
  <ejs-dialog  draggable="true" showCloseIcon="true" [visible]="false"
  id='innerDialog_statusy'
  #statusyDialog   height='510px' width='310px'  style="z-index: 100 !important;" 
  isModal="true"
  showOnInit='true' showCloseIcon='true'  
   [animationSettings]='dialogAnimation'  > 
   <ng-template #footerTemplate>
     <!-- <input id="inVal" class="e-input" type="text" placeholder="Enter your message here!"/>-->
     
      <button (click)="PostNewDateTime($event)"  class="btn btn-outline-primary my-2 my-sm-0 btn1">Zatwierdź</button>
  </ng-template> 

   <ng-template #content > 
   
       <div [formGroup]="formGroup">


         <mat-form-field style="margin-top: 30px; z-index: 60000 !important;">
           <input matInput [ngxMatDatetimePicker]="picker_RZ_P" placeholder="Przyjazd" formControlName="datA_RZ_P"
           
             [min]="minDate" [max]="maxDate"  [formControl]="datA_RZ_P">
           <mat-datepicker-toggle matSuffix [for]="picker_RZ_P"></mat-datepicker-toggle>
           <ngx-mat-datetime-picker #picker_RZ_P   [showSeconds]="showSeconds" [stepHour]="stepHour"
             [stepMinute]="stepMinute"   
              [disableMinute]="false" [hideTime]="false">
             <!-- Custom icon or text of Apply icon -->
             <ng-template>
               <!-- <mat-icon>star</mat-icon> -->
               <span>OK</span>
             </ng-template>
           </ngx-mat-datetime-picker>
         </mat-form-field>

         
         <mat-form-field style="margin-top: 20px; z-index: 100;" >
           <input matInput [ngxMatDatetimePicker]="picker_RZ"  formControlName="datA_RZ"
                           placeholder="Początek" 
                           [formControl]="datA_RZ"
                           [min]="minDate" [max]="maxDate" 
                           [disabled]="disabled">
           <mat-datepicker-toggle matSuffix [for]="picker_RZ">
           </mat-datepicker-toggle>
           <ngx-mat-datetime-picker #picker_RZ 
            
             [showSeconds]="showSeconds"
             [stepHour]="stepHour" [stepMinute]="stepMinute" 
             [stepSecond]="stepSecond"
              >
             <ng-template>
               <!-- <mat-icon>star</mat-icon> -->
               <span>OK</span>
             </ng-template>
           </ngx-mat-datetime-picker>
         </mat-form-field>


 
 <mat-form-field style="margin-top: 20px; z-index: 100;">
   <input matInput [ngxMatDatetimePicker]="picker_RZ_K" placeholder="Koniec"   formControlName="datA_RZ_K"
     
     [min]="minDate" [max]="maxDate"   [formControl]="datA_RZ_K">
   <mat-datepicker-toggle matSuffix [for]="picker_RZ_K"></mat-datepicker-toggle>
   <ngx-mat-datetime-picker #picker_RZ_K  [showSeconds]="showSeconds" [stepHour]="stepHour"
     [stepMinute]="stepMinute" [stepSecond]="stepSecond"  
      [disableMinute]="false" [hideTime]="false">
     <!-- Custom icon or text of Apply icon -->
     <ng-template>
       <!-- <mat-icon>star</mat-icon> -->
       <span>OK</span>
     </ng-template>
   </ngx-mat-datetime-picker>
 </mat-form-field>



</div>
</ng-template>
   
   <ng-template #header>
     <span    style="display: inline-block;"></span> 
     <img  style="margin-left:5px;"  height="35" src="/assets/images/M_trace_na ciemne tlo.png?"  >
       <div  style="padding-top: 3px;"   class="e-icon-settings dlg-template">Statusy </div>
   </ng-template>

  </ejs-dialog>


  <!--  załączniki -->
  <ejs-dialog  draggable="true" showCloseIcon="true" [visible]="false" id='innerDialogZalaczniki'
 #fileDialog   height='520px' width='370px' style="z-index: 10900;" 

 showOnInit='true' showCloseIcon='true'  
  [animationSettings]='dialogAnimation'  > 
  <ng-template #footerTemplate>
    <!-- <input id="inVal" class="e-input" type="text" placeholder="Enter your message here!"/>-->
    <button  (click)="CloseZalaczniki($event)" type="button" class="btn btn-success" style="  margin-top: 30px;; width: 160px;  font-family:effra,Arial,Helvetica,sans-serif!important;    ">Zatwierdź</button>

    
 </ng-template>

<!--   <div class="col-lg-12 control-section">
   <div class="control_wrapper">
       Initialize Uploader
       <input type="file" name="UploadFiles" id="validation">
   </div>
</div>-->
  
     <ejs-uploader (success)='onUploadSuccess($event)' 

       #defaultupload2 
          [asyncSettings]='path'
           multiple = 'true' 
           [autoUpload]='true'
         
           (removing)='onremoving($event)'
     (uploading)='onFileUpload($event)'
     [buttons]="buttons"
     > </ejs-uploader> 


  
  <ng-template #header>
    <span    style="display: inline-block;"></span> 
    <img  style="margin-left:5px;"   height="35" src="/assets/images/M_trace_na ciemne tlo.png?"  >
      <div style="padding-top: 3px; margin-left: 20px;"   class="e-icon-settings dlg-template">Załączniki</div>
  </ng-template>

</ejs-dialog> 
  

</div>

 

      
<!--  dane kierowcy -->
      <ejs-dialog  draggable="true" showCloseIcon="true" [visible]="false" id='innerDialog3ss3098'
      #butyDialog   height='490px' width='270px' style="z-index: 10900;" isModal="true">  
      <ng-template #content>
        <div class="dialogContent">  
          <div class="form-group template" style="margin-top: 15px;">
            <div   class="e-float-input" >
                <input  style=" font-style: normal;  ;"   type="text" id="first"   [(ngModel)]="find_object.first" #first2="ngModel" required  >

                <label style="text-align: left;" class="e-float-text" [ngClass]="{'e-label-top': find_object.first !== '' , 'e-label-bottom':  find_object.first == ''}" for="name">Imię kierowcy</label>                                                        
            </div>
            <span class="e-error" *ngIf="first2.errors && first2.errors.required && (first2.dirty || first2.touched)" >
                * Podaj imię kierowcy
            </span> 
        </div> 
        <div class="form-group template">
          <div   class="e-float-input"  >
              <input  style=" font-style: normal;"   type="text" id="lastno" name="last" [(ngModel)]="find_object.last" #last="ngModel" required  >

              <label style="text-align: left;" class="e-float-text" [ngClass]="{'e-label-top': find_object.last !== '' , 'e-label-bottom':  find_object.last == ''}" for="name">Nazwisko kierowcy</label>                                                        
          </div>
          <span class="e-error" *ngIf="last.errors && last.errors.required && (last.dirty || last.touched)" >
              * Podaj nazwisko kierowcy
          </span> 
        </div>

        <div class="form-group template">
          <div   class="e-float-input" >
              <input style=" font-style: normal;"   type="text" id="truckno" name="truck" [(ngModel)]="find_object.truck" #truck="ngModel" required  >

              <label style="text-align: left;" class="e-float-text" [ngClass]="{'e-label-top': find_object.truck !== '' , 'e-label-bottom':  find_object.truck == ''}" 
              for="name">Nr rejstracyjny auta</label>                                                        
          </div>
          <span class="e-error" *ngIf="truck.errors && truck.errors.required && (truck.dirty || truck.touched)" >
              * Podaj nr rejstracyjny auta
          </span> 
        </div> 
        <div class="form-group template">
          <div   class="e-float-input"  >
              <input  style=" font-style: normal;"   type="text" id="trailerno" name="trailer" [(ngModel)]="find_object.trailer" #trailer="ngModel" required  >

              <label style="text-align: left;" class="e-float-text" [ngClass]="{'e-label-top': find_object.trailer !== '' , 'e-label-bottom':  find_object.trailer == ''}" 
              for="name">Nr rejstracyjny naczepy</label>                                                        
          </div>
          <span class="e-error" *ngIf="trailer.errors && trailer.errors.required && (trailer.dirty || trailer.touched)" >
              * Podaj nr rejstracyjny naczepy
          </span> 
        </div>

        <div class="form-group template">
          <div class="row">
  <div class="col-3"> 
  <div   class="e-float-input"  >
    <input style=" font-style: normal;"    type="text" id="mobilePrefixno" name="mobilePrefix" [(ngModel)]="find_object.mobilePrefix" #mobilePrefix="ngModel" required  >
    <label style="text-align: left;"        class="e-float-text" [ngClass]="{'e-label-top': find_object.mobilePrefix !== '' , 'e-label-bottom':  find_object.mobilePrefix == ''}" for="name">Prefix</label>                                                        
</div>
<span class="e-error" *ngIf="mobilePrefix.errors && mobilePrefix.errors.required && (mobilePrefix.dirty || mobilePrefix.touched)" >
    * Podaj prefix
</span>  
</div>


<div class="col-9">
  <div   class="e-float-input"  >
    <input style=" font-style: normal;"    type="text" id="shoesno" name="shoes" [(ngModel)]="find_object.shoes" #shoes="ngModel" required  >
    <label style="text-align: left;"        class="e-float-text" [ngClass]="{'e-label-top': find_object.shoes !== '' , 'e-label-bottom':  find_object.shoes == ''}" for="name">Nr telefonu</label>                                                        
</div>
<span class="e-error" *ngIf="shoes.errors && shoes.errors.required && (shoes.dirty || shoes.touched)" >
    * Podaj numer telefonu
</span>  
</div>
            
          </div> 
        </div>  

        </div>
    </ng-template>
        <ng-template #footerTemplate>
          <button  (click)="SaveNrButa($event)" id="sendButton" class="btn btn-success" data-ripple="true">Zatwierdź </button>

       </ng-template>
    
      <ng-template #header>
        <span    style="display: inline-block;"></span> 
        <img  style="margin-left:5px;" height="35" src="/assets/images/M_trace_na ciemne tlo.png?"  >
          <div style="font-size: 13px; padding-top: 3px;"  class="e-icon-settings dlg-template">Dane kierowcy</div>
      </ng-template>
      </ejs-dialog>
  

      <!--  negocjacje -->
      <ejs-dialog  draggable="true" showCloseIcon="true" [visible]="false" id='innerDialognegotiateDialog'
      #negotiateDialog   height='690px' width='800px' style="z-index: 10900;" isModal="true">  
      <ng-template #content>
        <div class="dialogContent">  
          <label style="text-align: left;" class="e-float-text"  >Ostatnia proponowana cena: {{negotiationsProposedPrice}} €</label>  
          


          <div class="row" style="margin-top: 20px;">
            <div class="col-sm">  
              <div class="form-group template" style="margin-top: 15px;">
              <div   class="e-float-input" >
                  <input type="number" autocomplete="off" style=" font-style: normal;  ;"   id="proposedPrice"   [(ngModel)]="negotiation.proposedPrice" #proposedPrice="ngModel" required  >
  
                  <label style="text-align: left;" class="e-float-text" [ngClass]="{'e-label-top': negotiation.first !== '' , 'e-label-bottom':  negotiation.first == ''}" for="name">Nowa propozycja ceny</label>                                                        
              </div>
              <span class="e-error" *ngIf="proposedPrice.errors && proposedPrice.errors.required && (proposedPrice.dirty || proposedPrice.touched)" >
                  * Zaproponuj cene
              </span> 
          </div> </div>
          <div class="col-sm">
            <div class="form-group template" style="margin-top: 15px;">
              <button  (click)="SaveNegotiate($event)" id="sendButton" class="btn btn-success" data-ripple="true">Złoż negocjacje </button>
  
          </div> 
        </div>
          </div>
        
         

       
      
        <div class="form-group template" style="margin-top: 15px;  ">
          <div   class="e-float-input" > 
              <label style="text-align: center;"  >Historia</label>   
              <ejs-grid [dataSource]='negotiations' >
                <e-columns>
                   
                    <e-column field='carrierPrice' headerText='Cena przewoźnika'  width=150></e-column>
                    <e-column field='proposedPrice' headerText='Zaproponowana cena'  width=150></e-column>
                    <e-column [valueAccessor]='valuedecisionNegotiation'  field='decision' headerText='Decyzja' textAlign='Right'   width=150 ></e-column>
                    <e-column [valueAccessor]='valueAccessNegotiation' field='Złożono' width=170 headerText='Data nagocjacji' textAlign='Right' format='yMd'></e-column>
                </e-columns>
                </ejs-grid>
          </div>
          
      </div> 
      
       
        </div>
    </ng-template>
        <ng-template #footerTemplate>

       </ng-template>
    
      <ng-template #header>
        <span    style="display: inline-block;"></span> 
        <img  style="margin-left:5px;" height="35" src="/assets/images/M_trace_na ciemne tlo.png?"  >
          <div style="font-size: 13px; padding-top: 3px;"  class="e-icon-settings dlg-template">Negocjacje</div>
      </ng-template>
      </ejs-dialog>