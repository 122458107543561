 

<nav    #myNavbar class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" 
style="padding-top: 20px; padding-bottom: 20px; 
 background-color: rgb(255, 255, 255) !important;  max-width: 1360px; 
margin-left: auto; z-index: 1;
margin-right: auto; "
  
 
 >
<!-- <div class="container">  -->
<!-- you can turn this container off/on-->
<a (click)="navigateToHome()" class="navbar-brand">
  <a (click)="navigateToHome()" class="layout-header-logo"><img src="/assets/images/paczka/M_trace_.png" width="220" 
    alt="Maszoński Logistic Sp.z.o.o. Sp.k." title="Maszoński Logistic Sp.z.o.o. Sp.k."></a>

  
</a>
<button class="navbar-toggler" type="button" ata-bs-toggle="collapse"data-toggle="collapse"
data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
</button>

  <div class="collapse navbar-collapse" id="navbarNavAltMarkup" >
 
    
    <div class="navbar-nav" >
      <ng-container  *ngIf="accountService.currentUserSource$ | async">
      <a class="nav-item nav-link" routerLink="/orders"  aria-current="page"     (click)="ToggleNavBar()"  >Zlecenia</a>
      <a class="nav-item nav-link" *ngIf="accountService.currentAdminSource$ | async"   routerLink="/carriers"   aria-current="page"     (click)="ToggleNavBar()"   >Przewoźnicy </a>
      <a class="nav-item nav-link" *ngIf="accountService.currentAdminSource$ | async"    routerLink="/owntransport"   aria-current="page"     (click)="ToggleNavBar()"   >Transporty </a>
      <a class="nav-item nav-link" *ngIf="!(accountService.currentAdminSource$ | async) as boolean"   routerLink="/owntransport" (click)="ToggleNavBar()" >Moje transporty</a>
      <a *ngIf="(accountService.currentAdminUserSource$ | async) && !(accountService.currentAdminSource$ | async)"  class="nav-item nav-link" routerLink="/owntruck" (click)="ToggleNavBar()" >Moja flota</a>
      <a *ngIf="(accountService.currentAdminUserSource$ | async) && !(accountService.currentAdminSource$ | async)"  class="nav-item nav-link" routerLink="/company" (click)="ToggleNavBar()" >Dane firmy</a>
      <a *ngIf="(accountService.currentAdminUserSource$ | async) || (accountService.currentAdminSource$ | async)"   class="nav-item nav-link" routerLink="/users" (click)="ToggleNavBar()" >Użytkownicy</a> 
      <a class="nav-item nav-link  " routerLink="/forwarders"   aria-current="page"     (click)="ToggleNavBar()"   >Kontakt </a>
      <!--    <a class="nav-item nav-link active" routerLink="/errors" routerLinkActive="active" aria-current="page"      >errors</a>-->
    </ng-container> 
    </div>

   

    <div class="navbar-nav ml-auto"> 

      <div class="btn-group dropleft"  *ngIf="(accountService.currentUserSource$ | async) as user" dropdown >
        <button   class="caret" type="button"   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        </button>
        <div class="dropdown-menu">
          <!-- Dropdown menu links -->
          <a class="dropdown-item" (click)="logout()">Wyloguj</a>
        </div>
      </div> 
 
  
      <form  *ngIf="(accountService.currentUserSource$ | async) === null" #loginForm="ngForm"  class="form-inline my-2 my-lg-0">
        <li *ngIf="accountService.showRemeberPassword" style="margin-right:15px;"    >
          <a style="text-decoration: none; color:#0091ce;" (click)="ToggleNavBar()" routerLink="/passwordremainder" >Nie pamiętam</a></li>
          <img src="/assets/images/paczka/ludzik_logowanie.png" height="38" style="margin-right:10px;   ">
          <input style=" margin-top: 3px; background-color: #e6e6e6; font-size: 1rem;" name="username" [(ngModel)]="model.username" class="form-control mr-sm-2" type="text" placeholder="login"  >
          <input style=" margin-top: 3px; background-color: #e6e6e6; font-size: 1rem; " name="password" [(ngModel)]="model.password" class="form-control mr-sm-2" type="password" placeholder="hasło"  >
          <button   (click)="login()" class="loginClass"   autocomplete="off"  type="submit"> </button>
          <button   class="registerClass"   (click)="register()"  type="submit"> </button>   
      </form>
    </div>
   

  </div>
</nav>
