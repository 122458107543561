import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import moment from 'moment';
import { DialogService } from '../confirmation-dialog/dialog';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { placeOrder } from 'src/app/_models/placeOrders';
import { OrderService } from '../orders.services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-status-dialog',
  templateUrl: './status-dialog.component.html',
  styleUrls: ['./status-dialog.component.css']
})
export class StatusDialogComponent implements OnInit {


 
  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  public datA_RZ =  new FormControl();
  public datA_RZ_K= new FormControl()
  public datA_RZ_P = new FormControl(); 
  public listColors = ['primary', 'accent', 'warn']; 
  public stepHours = [1, 2, 3, 4, 5];
  public stepMinutes = [1, 5, 10, 15, 20, 25];
  public stepSeconds = [1, 5, 10, 15, 20, 25];
  public dialogAnimation: Object={effect: 'None'};

  public dateControl = null;
  public dateControlMinMax = new FormControl(new Date())

  
  @ViewChild('picker_RZ1') picker_RZ1: any;
 @ViewChild('picker_RZ_K1') picker_RZ_K1: any;
 @ViewChild('picker_RZ_P1') picker_RZ_P1: any;
 @ViewChild('picker_RZ_W1') picker_RZ_W1: any;
  data: placeOrder;
  public datA_RZ1: any;
public datA_RZ_P1: any;
public datA_RZ_K1: any;

public dateControl1 = new FormControl(new Date(2021,9,4,5,6,7));
  formGroup: FormGroup;
 
 constructor(private dialogService: DialogService, @Inject(MAT_DIALOG_DATA) public _data: placeOrder,  private _dialog: MatDialog,
  private dialogRef: MatDialogRef<StatusDialogComponent>,
  private orderService: OrderService,  private toastr: ToastrService) { 
    this.data = _data 

}

  ngOnInit(): void { 

    datA_RZ1:  new FormControl(this.data.datA_RZ);  
    datA_RZ_P1:  new FormControl(this.data.datA_RZ_P);  ;
    datA_RZ_K1:  new FormControl(this.data.datA_RZ_K);  ;


 
    this.formGroup = new FormGroup({
      datA_RZ1:  new Date(this.data.datA_RZ) > new Date('2000-01-01') ? new FormControl(this.data.datA_RZ) : new FormControl() ,
      datA_RZ_K1:  new Date(this.data.datA_RZ_K) > new Date('2000-01-01') ? new FormControl(this.data.datA_RZ_K) : new FormControl() ,
      datA_RZ_P1:  new Date(this.data.datA_RZ_P) > new Date('2000-01-01') ? new FormControl(this.data.datA_RZ_P) : new FormControl() ,
      //datA_RZ_K1: new FormControl(this.data.datA_RZ_K),
      //datA_RZ_P1: new FormControl(this.data.datA_RZ_P) 
     // datA_RZ_W: new FormControl( )
    })
  }

 
  
 

    updatePrzyjazd(dateObject) { 

      if(dateObject.value == null)
      { 
        this.data.datA_RZ_P = new Date('1900-01-01T00:00:00');
      }
      else
      { 
        const stringified = JSON.stringify(dateObject.value);
        const dob = stringified.substring(1, 20);  
        var today = new Date(dob);
        today.setHours(today.getHours() + 4) 
        this.data.datA_RZ_P = today;
      }
 
        var x =  new Date(this.data.datA_RZ) > new Date('2000-01-01') ? 1 : 0;
        var y =  new Date(this.data.datA_RZ_K)> new Date('2000-01-01') ? 1 : 0;
        var z =  new Date(this.data.datA_RZ_P) > new Date('2000-01-01') ? 1 : 0; 
        this.data.qtyDate  = x + y + z;
          this.orderService.SetStatus_RZ_K(this.data).subscribe(async response => {
            console.log(response); 
            this.toastr.success("Zaktualizowano");
          
          }, error=>{
            console.log(error);
             this.toastr.error(error.error)
          });

          if(dateObject.value != null)
          { 
            const stringified = JSON.stringify(dateObject.value);
            const dob = stringified.substring(1, 20);  
            today = new Date(dob);
            today.setHours(today.getHours() + 2) 
            this.data.datA_RZ_P = today;
          }
     }

     updateKoniec(dateObject) {


      if(dateObject.value == null)
      { 
        this.data.datA_RZ_K = new Date('1900-01-01T00:00:00');
      }
      else
      { 
        const stringified = JSON.stringify(dateObject.value);
        const dob = stringified.substring(1, 20);  
        var today = new Date(dob);
        today.setHours(today.getHours() + 4) 
        this.data.datA_RZ_K = today; 
      }
      var x =  new Date(this.data.datA_RZ) > new Date('2000-01-01') ? 1 : 0;
      var y =  new Date(this.data.datA_RZ_K) > new Date('2000-01-01') ? 1 : 0;
      var z =  new Date(this.data.datA_RZ_P) > new Date('2000-01-01') ? 1 : 0; 
      this.data.qtyDate  = x + y + z;
          this.orderService.SetStatus_RZ_K(this.data).subscribe(async response => {
            console.log(response); 
            this.toastr.success("Zaktualizowano");
          
          }, error=>{
            console.log(error);
             this.toastr.error(error.error)
          });



          if(dateObject.value != null)
          { 
            const stringified = JSON.stringify(dateObject.value);
            const dob = stringified.substring(1, 20);  
            today = new Date(dob);
            today.setHours(today.getHours() + 2) 
            this.data.datA_RZ_K = today;
          } 
      }

    

      updatePoczatek(dateObject) {

        if(dateObject.value == null)
        { 
          this.data.datA_RZ = new Date('1900-01-01T00:00:00');
        }
        else
        { 
          const stringified = JSON.stringify(dateObject.value);
          const dob = stringified.substring(1, 20); 
          var today = new Date(dob);
          today.setHours(today.getHours() + 4)
          this.data.datA_RZ =  today; 
        }
        var x =  new Date(this.data.datA_RZ) > new Date('2000-01-01') ? 1 : 0;
        var y =  new Date(this.data.datA_RZ_K) > new Date('2000-01-01') ? 1 : 0;
        var z =  new Date(this.data.datA_RZ_P) > new Date('2000-01-01') ? 1 : 0; 
        this.data.qtyDate  = x + y + z;
            this.orderService.SetStatus_RZ_K(this.data).subscribe(async response => {
              console.log(response); 
              this.toastr.success("Zaktualizowano");
            
            }, error=>{
              console.log(error);
               this.toastr.error(error.error)
            }); 
            if(dateObject.value != null)
            { 
              const stringified = JSON.stringify(dateObject.value);
              const dob = stringified.substring(1, 20);  
              today = new Date(dob);
              today.setHours(today.getHours() + 2) 
              this.data.datA_RZ = today;
            } 
      }

      close($event: any) {
        this.dialogRef.close({ data: this.data }); // <- Closes the dialog
        }
        
} 
