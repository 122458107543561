import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map , filter, switchMap, retry, subscribeOn} from 'rxjs/operators';
import { Order } from '../_models/order';
import { User } from '../_models/user';
import { SearchResults } from '../_models/SearchResults'; 
import { company } from '../_models/company';
import { UserRegister } from '../_models/userRegister'; 
import { ConfigService } from './configService';

 @Injectable({
  providedIn: 'root'
})
export class AccountService {
   
   baseUrl: string;


  constructor(private http: HttpClient, private conf:ConfigService) {  
    this.baseUrl= conf.getApi("baseUrl");
   }
 
  
   showRemeberPassword: boolean;

   SetRememberPassword() {
     this.showRemeberPassword = true;
   }
  

  HideRememberPassword() {
    this.showRemeberPassword = false;
  }
   
   
  
   //return this.http.get<Order[]>(this.baseUrl + 'orders');
 

   async existsinmaszon(model: company){
    const data = await this.http.get<boolean>(this.baseUrl + 'account/' + model.nip).toPromise();
    //console.log("Data: " + JSON.stringify(data)); 
    return data;
  }
 
    
   

 
  private currentUserSource = new ReplaySubject<User>(1);
  private currentComapnySource = new ReplaySubject<company>(1);
  private currentRegisterUserSource = new ReplaySubject<UserRegister>(1);
  private currentAdminSource = new ReplaySubject<boolean>(1);
  private currentAdminUserSource = new ReplaySubject<boolean>(1); //szef
  private currentorders = new ReplaySubject<Order>();

  currentUserSource$ = this.currentUserSource.asObservable();
  currentComapnySource$ = this.currentComapnySource.asObservable();
  currentRegisterUserSource$ = this.currentRegisterUserSource.asObservable();
  currentorders$ = this.currentorders.asObservable();
  currentAdminSource$ = this.currentAdminSource.asObservable();
  currentAdminUserSource$ = this.currentAdminUserSource.asObservable();

  totalAngularPackages: any;
    localVar:any;
    countries:Order[] = []
    companies:company[] = []

    isLogin = false;
    roleAs: string;
    
  

   async postemailconfirm(ec: Boolean) {
    const data = await this.http.get<Boolean>(this.baseUrl + "email/" + ec).subscribe(posts => 
      { 
        return posts;
     }); 
 }


 async sendrequestdatachanging(company: any) {
  return  this.http.post(this.baseUrl + 'email/requestdatachanging', company).pipe(
    map((newpass: Boolean )=> {
      { 
        if(newpass)
        {
          return true;
        }
      }
      return false;
      })
    ) 
}



async sendrequestdatadelete(company: any) {
  return  this.http.post(this.baseUrl + 'email/requestdatadelete', company).pipe(
    map((newpass: Boolean )=> {
      { 
        if(newpass)
        {
          return true;
        }
      }
      return false;
      })
    ) 
}
 

     postnewpassword(ec: any) {
        return  this.http.post(this.baseUrl + 'account/changepasswordbytoken', ec).pipe(
          map((newpass: Boolean )=> {
            { 
              if(newpass)
              {
                return true;
              }
            }
            return false;
            })
          ) 
      }
   
 


   async getCompanies(){
    const data = await this.http.get<company[]>(this.baseUrl + 'users').toPromise();
    //console.log("Data: " + JSON.stringify(data)); 
    return data;
  }
 

    

   getOrders() { 
      this.http.get<Order[]>(this.baseUrl + 'orders').subscribe(posts => 
        {
          posts.forEach( post => { 
            //console.log('bbbbb' + post.idZlecenia + ' NrZlecenia:' + post.nrZlecenia);
            this.countries.push(post);
            this.countries.push(post); 
          }) ; 
          
          return this.countries;
       });
 
}

    

 private handleError<T>(operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    //console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    console.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}

 

   login(model:any){ 
    return this.http.post(this.baseUrl + 'account/login', model).pipe( 
      map((response:User) => {
        const user = response;
        if (user)
        {  

          this.HideRememberPassword();

              if(user.isUserAdmin == true)  
                this.currentAdminUserSource.next(true); 
              else 
                this.currentAdminUserSource.next(false); 

          if(user.isAdmin == true) 
          {
            this.roleAs = "ROLE_ADMIN";
            this.currentAdminSource.next(true);
          }
          else
          {
            this.roleAs = "ROLE_USER"
            this.currentAdminSource.next(false);
          }

 

          localStorage.setItem('ROLE', this.roleAs);

          localStorage.setItem('STATE', 'true');
          localStorage.setItem('user', JSON.stringify(user))
          this.currentUserSource.next(user);
        }
        else
        {
          
        }
      }) 
      );
   }


   registerUser(model:UserRegister){
  return this.http.post(this.baseUrl + 'account/register', model).pipe(
        map((userregister: UserRegister )=> {
          { 
            localStorage.setItem('user', JSON.stringify(userregister))
            this.currentRegisterUserSource.next(userregister);
          }
            return userregister;
          })
        ) 
}


async register(model:company){
  return await this.http.post(this.baseUrl + 'account/registercompany', model).toPromise(); 
}


updateuser(user: UserRegister) {
  return  this.http.post(this.baseUrl + 'account/updateuser', user).pipe(
    map((userregister: UserRegister )=> {
      { 
        if(user != null)
        {
          return true;
        }
      }
        return false;
      })
    ) 
}
 

   async addnewuser(user: UserRegister)  {
  return await this.http.post(this.baseUrl + 'account/adduser', user).toPromise();
     
}

changepassword(user: UserRegister) {
  return  this.http.post(this.baseUrl + 'account/changepassword', user).pipe(
    map((userregister: UserRegister )=> {
      { 
        if(user.userId == userregister.userId)
        {
          localStorage.setItem('user', JSON.stringify(userregister))
          this.currentRegisterUserSource.next(userregister);
        }
      }
        return userregister;
      })
    ) 
}


newPassword(user: any) {
  return  this.http.post(this.baseUrl + 'account/newpassword', user).pipe(
    map((userregister: UserRegister )=> {
      { 
        if(user.userId == userregister.userId)
        {
          localStorage.setItem('user', JSON.stringify(userregister))
          this.currentRegisterUserSource.next(userregister);
        }
      }
        return userregister;
      })
    ) 
}


sendEmailConfirm(mail: { ToEmail: string; subject: string; body: string;  UserName:string}) {
  return this.http.post(this.baseUrl + 'email/send', mail).pipe(
      map((response:any[]) => {
        const user = response; 
  }) )  
}
 
remeberPassword(email:any) {
  return this.http.post(this.baseUrl + 'email/rememberpassword', email).pipe(
      map((response:any[]) => {
      return true;
  }) )  
}




 

   setCurrentUser(user: User ){
    this.currentUserSource.next(user);

      if(user != null)
        {
          if(user.isAdmin == true) 
          {
            this.roleAs = "ROLE_ADMIN";
            this.currentAdminSource.next(true);
          }
          else
          {
            this.roleAs = "ROLE_USER"
            this.currentAdminSource.next(false);
          }



          if(user.isUserAdmin == true)  
          this.currentAdminUserSource.next(true); 
        else 
          this.currentAdminUserSource.next(false); 
          
        }
   }



   logout()
   {
    localStorage.removeItem('user') 
    localStorage.removeItem('ROLE');
    localStorage.removeItem('STATE');
    this.currentUserSource.next(null); 
    this.currentAdminSource.next(null);
    this.currentAdminUserSource.next(null);
   }

  

  isLoggedIn() {
    const loggedIn = localStorage.getItem('STATE');
    if (loggedIn == 'true')
      this.isLogin = true;
    else
      this.isLogin = false;
    return this.isLogin;
  }

  getRole() {
    this.roleAs = localStorage.getItem('ROLE');
    return this.roleAs;
  }



  get isAdmin() {
    return this.getRole()   === 'ROLE_ADMIN';
}


async GetUsers(){
  const data = await this.http.get<UserRegister[]>(this.baseUrl + 'account').toPromise();
  //console.log("Data: " + JSON.stringify(data)); 
  return data;
}

async GetUsersAsObservable(){
  const data = await this.http.get<UserRegister[]>(this.baseUrl + 'account');
  //console.log("Data: " + JSON.stringify(data)); 
  return data;
}

async GetUsersByCompanyId(companyId: Number)  {
  const data = await this.http.get<UserRegister[]>(this.baseUrl + 'users/GetUsersByCompanyId/' + companyId).toPromise();
  //console.log("Data: " + JSON.stringify(data)); 
  return data;
}
 
   
}
 