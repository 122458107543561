import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NegotiationService } from 'src/app/_services/negotiation.service';

@Component({
  selector: 'app-negotiation',
  templateUrl: './negotiation.component.html',
  styleUrls: ['./negotiation.component.css']
})
export class NegotiationComponent implements OnInit {
  ec:string
  accepted: boolean = false;
  constructor(private activatedRoute: ActivatedRoute,  private negService: NegotiationService , private toastr:ToastrService, private router: Router) { 

    this.activatedRoute.queryParams.subscribe(params => {
      this.ec = params['ec'];

      var splitted = this.ec.split("|");

      if(splitted[1] == "5")  
         this.accepted = true;

      this.negService.SendEmail(this.ec);
    
  });
}

  ngOnInit(): void {

  }

}
 
