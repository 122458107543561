import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { company } from '../_models/company';
import { ConfigService } from './configService';

@Injectable({
    providedIn: 'root'
  })

export class GusService {
  baseUrl: any;

  constructor(private http: HttpClient, private conf:ConfigService) {  
    this.baseUrl= conf.getApi("baseUrl");
}


async GetCompanyByNip(model:any){
    const data = await this.http.get<company>(this.baseUrl + 'gus/' + model).toPromise();
    //console.log("Data: " + JSON.stringify(data)); 
    return data;
  }



}