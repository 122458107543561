import { Injectable } from '@angular/core';
import { User } from '../_models/user';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './configService';
import { expandedRow, placedOrder, placeOrder } from "../_models/placeOrders";
import { Dialog } from '@syncfusion/ej2-popups';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class TimerclockService {
  baseUrl: string;
  confirmationDialogComponent: ConfirmationDialogComponent;
  data: any;
  constructor(private http: HttpClient, private conf:ConfigService ,private  confirmationDialogComponent1: ConfirmationDialogComponent
    ,private _dialog: MatDialog
    ) {  
    this.baseUrl= conf.getApi("baseUrl");
    this.confirmationDialogComponent= confirmationDialogComponent1 ;
}


ngAfterViewInit() {
  console.log("afterinit");
  setTimeout(() => {
    
  }, 1000);
}

  async download2222(){



    let user = JSON.parse(localStorage.getItem('user')) as User

    var today = new Date();
    today.setHours(today.getHours() + 0) 
    //user.transportStatus =  today 
    
   // var today2 = new Date();
   // today2.setHours(today.getHours() + 1) 
   // user.transportStatus =  today2 
   // localStorage.setItem('user', JSON.stringify(user));
   var listToShow =  await this.http.get<placeOrder[]>(this.baseUrl + 'Remainder/userremainder/' + user.userId  ).toPromise(); 
   this.data = "jan"

 
   const dialogConfig = new MatDialogConfig();

   dialogConfig.disableClose = true;
   dialogConfig.autoFocus = true;
   dialogConfig.data = listToShow;
   dialogConfig.height = '700px';
   dialogConfig.width = '1240px';
   dialogConfig.role = 'dialog'

    
    if(listToShow.length > 0)   
          var ref1 = this._dialog.open(ConfirmationDialogComponent, dialogConfig

     );
 
  
   console.log(listToShow);

    if (user.transportStatus == undefined) 
    { 
        user.transportStatus =  today    
        localStorage.setItem('user', JSON.stringify(user));
    }


    if((new Date(user.transportStatus) ) < new Date()  )
    {
      console.log("minęłą godzina" + (new Date(user.transportStatus) ).toLocaleString()  +" vs "+ new Date().toLocaleString());
      user.transportStatus = today   
      localStorage.setItem('user', JSON.stringify(user)); 


       
       // var listToShow =  this.http.get<boolean>(this.baseUrl + 'Remainder/userremainder/' + user.userId).toPromise();  


      var today2 = new Date();
      today2.setHours(today2.getHours() + 1) 
      user.transportStatus =  today2 
      localStorage.setItem('user', JSON.stringify(user));
    }
    
    else{
      let user = JSON.parse(localStorage.getItem('user')) as User
      console.log("transportStatus jest mniejszy " +  (new Date(user.transportStatus)).toLocaleString()   + "  "+ today.toLocaleString()     )
    
    }
 
  }

}
