import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { company } from '../_models/company';
import { forwarder } from '../_models/forwarder';
import { ConfigService } from './configService';

@Injectable({
    providedIn: 'root'
  })

export class ForwarderService {
  baseUrl: any;

  constructor(private http: HttpClient, private conf:ConfigService) {  
    this.baseUrl= conf.getApi("baseUrl");
}


async GetForwarders(){
    const data = await this.http.get<forwarder[]>(this.baseUrl + 'Forwarder' ).toPromise();
    //console.log("Data: " + JSON.stringify(data)); 
    return data;
  }



}
