import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { User } from '../_models/user';
import { AccountService } from '../_services/account.service';
import { ToolbarComponent, MenuAnimationSettingsModel } from '@syncfusion/ej2-angular-navigations';

@Component({
  
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  public gridData;
  public pagesettings;
  model: any = {}
  loggedIn: boolean;
  isAdmin:boolean;
  public href: string = "";
  public iSHomePage: boolean = false;


   constructor(public accountService: AccountService, private router: Router, private toastr:ToastrService) {
    this.gridData = (window as any).gridData;
    this.pagesettings = { pageCount: 3, pageSize: 7 };
   }

  ngOnInit(): void {
    this.isAdmin = this.accountService.isAdmin;
     
    if(this.router.url == "/")
    {
      this.iSHomePage=true;
    }
    else
    {
      this.iSHomePage=false;
    }
   
  }


  navigateToHome()
  {
    const user: User = JSON.parse(localStorage.getItem('user'))

    if(user == null)
    {
      this.router.navigateByUrl('');
    }
    else
    {
      this.router.navigateByUrl('/orders');
    }
  }


  login() {
    this.accountService.login(this.model).subscribe(response => {
    this.router.navigateByUrl('/orders');
      console.log(response);
      this.loggedIn = true;
    }, error => {

      this.loggedIn = false;
      this.accountService.SetRememberPassword();
       
    });
  }


  mojaflota()
  {

  }

  mojetransporty()
  {

  }


  private animationSettings: MenuAnimationSettingsModel = { effect: 'None' };

  logout() {
    this.accountService.logout();
    this.router.navigateByUrl('/');
    this.loggedIn = false;
  }

  register() {

    this.ToggleNavBar();
    //this.accountService.logout();
    this.router.navigateByUrl('/second-component');
    this.loggedIn = false;
  }

  isTournamentRoute() {
    return  this.router.url.includes("/second-component");
}

ToggleNavBar () {
  let element: HTMLElement = document.getElementsByClassName( 'navbar-toggler' )[ 0 ] as HTMLElement;
  if ( element.getAttribute( 'aria-expanded' ) == 'true' ) {
      element.click();
  }
}

isIn = false;   // store state

toggleState() { // click handler
  let bool = this.isIn;
  this.isIn = bool === false ? true : false; 
}



}
