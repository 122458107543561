import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { EmitType } from '@syncfusion/ej2-base';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { company } from '../_models/company';
import { User } from '../_models/user';
import { CompanyService } from '../_services/company.service';
import { AccountService } from '../_services/account.service';
import { ToastrService } from 'ngx-toastr';
import { UserRegister } from '../_models/userRegister';

@Component({
  selector: 'app-dane-firmy',
  templateUrl: './dane-firmy.component.html',
  styleUrls: ['./dane-firmy.component.css'],
   
})
export class DaneFirmyComponent implements OnInit {
    
  
  company:any;
  @ViewChild('Dialog')
  public dialogObj: DialogComponent;
  public form: FormGroup;
  public width: string = '335px';
  public visible: boolean = false;
  public multiple: boolean = false;
  public showCloseIcon: Boolean = true;
  public formHeader: string = 'Success';
  public content: string = 'Your details have been updated successfully, Thank you.';
  public target: string = '#control_wrapper';
  public isModal: boolean = true;
  public animationSettings: any = {
        effect: 'Zoom'
    };
 private formSumitAttempt: boolean;
 public dlgBtnClick: EmitType<object> = () => {
  this.dialogObj.hide();
}
public dlgButtons: Object[] = [{ click: this.dlgBtnClick.bind(this), buttonModel: { content: 'Ok', isPrimary: true } }];
public uploadInput: string = '';
public browseClick() {
    document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click(); return false;
  }
public Submit(): void {
  this.onFormSubmit();
}
public onFileSelect: EmitType<Object> = (args: any) => {
  this.uploadInput = args.filesData[0].name;
}

  async deleteAccount() {
  if(confirm("Czy na pewno chcesz wysłać zgłoszenie o usunięciu konta")) {
    console.log("Implement delete functionality here");


    let res=  (await this.accountService.sendrequestdatadelete(this.company)).subscribe(response => {
      this.toastr.success("Wysłano zgłoszenie z prośbą o usunięcie konta") 
    
     }, error => {
       this.toastr.error("Nie udało się wysłać zgłoszenia z prośbą o zmianę")
     }); 
  }
}

  async aktualizujDaneFirmy()
{
    let res=  (await this.accountService.sendrequestdatachanging(this.company)).subscribe(response => {
      this.toastr.success("Wysłano zgłoszenie z prośbą o zmianę") 
    
     }, error => {
       this.toastr.error("Nie udało się wysłać zgłoszenia z prośbą o zmianę")
     }); 
 }
 

public onFormSubmit(): void {
  this.formSumitAttempt = true;
  if (this.form.valid) {
    this.dialogObj.show();
    this.form.reset();
  } else {
    this.validateAllFormFields(this.form);
  }
}

constructor(@Inject(FormBuilder) public formBuilder: FormBuilder, private companyService: CompanyService,private accountService: AccountService, private toastr: ToastrService,) {}

  async ngOnInit() {
  this.form = this.formBuilder.group({
    name: [null, Validators.required],
    upload: [null, Validators.required],
  });

  this.company = {
    companyId:0,
    userId:0,
    kodPocztowy: "",
    nip: "", 
    regon: "",
    statusNip: "",
    nazwa: "",
    wojewodztwo: "",
    powiat: "",
    gmina: "",
    miejscowosc: "", 
    ulica: "",
    nrNieruchomosci: "",
    nrLokalu: "",
    typ: "F",
    silosID: 1,
    dataZakonczeniaDzialalnosci : "2019-01-06T17:16:40",
    miejscowoscPoczty: "", 
    ciagnikSiodlowy:  0,
    ciagnikTrojosiowy:  0,
    ciagnikTypuMega:  0,
    ciagnikTypuMegaReagul:  0,  
    idKontrah:0,
    accepted: false, 
    datarejstracji :   new Date(),
    isAdmin:false,
    users: [
      {userId: 1, 
        userName: "" ,
        PasswordHash:"",
      PasswordSalt:"" , 
       uzytkownikId: 0,
       usernameDto:null,
      mobile: "",
      email: "",
      idKontrah:0,
      accepted:false,
      datarejstracji: new Date() ,
      isAdmin: false,
      userCompanyId: null,
      UserComapnyCompany: {UserId: 2, nip: "",  nazwa: "",   miejscowosc: "",   ulica: "",  nrNieruchomosci: "",},
      orders: [],
      password: "4",
      emailConfirmedToken: "",
      emailConfirmed:false
    },
      
    ], 
    errors: [],
  };


  const user: User = JSON.parse(localStorage.getItem('user'))
  this.company = await this.companyService.GetDataCompany(user);

  const user2: UserRegister =   this.company.users.find(x => x.userId === user.userId);

  user2.password = "";
  user2.UserComapnyCompany = {UserId: user.userId, nip: "",  nazwa: "",   miejscowosc: "",   ulica: "",  nrNieruchomosci: ""};

  this.company.users[0] = user2;
 
 
}

isFieldValid(field: string): boolean {
  return ((!this.form.get(field).valid && this.form.get(field).touched) ||
  (this.form.get(field).untouched && this.formSumitAttempt));
}

validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      this.validateAllFormFields(control);
    }
  });
}



}
 