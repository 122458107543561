import { Component, OnInit, ViewChild, enableProdMode, ElementRef, Injectable, NgZone, Inject } from '@angular/core';
import { BrowserModule, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CheckBoxSelectionService, DropDownListComponent, DropDownListModule, FilteringEventArgs, MultiSelectComponent, RemoveEventArgs, TaggingEventArgs } from '@syncfusion/ej2-angular-dropdowns'; 
import { FilterService, GridModule , FilterSettingsModel, GridComponent,GroupService,IFilter,PageService,PageSettingsModel,SortService,VirtualScrollService, EditSettingsModel, CommandModel, CommandClickEventArgs, EditService, CommandColumnService, QueryCellInfoEventArgs, SelectionSettingsModel, RowSelectEventArgs} from '@syncfusion/ej2-angular-grids';
import {RowDataBoundEventArgs} from '@syncfusion/ej2-grids';
import { ToastrService } from 'ngx-toastr'; 
import { AccountService } from '../_services/account.service';
import { EmitType, loadCldr, setCulture, setCurrencyCode, isNullOrUndefined,  Internationalization} from '@syncfusion/ej2-base';
import { catchError,  map } from "rxjs/operators";
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GooglePlacesService } from '../_services/google.places.service';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { SliderChangeEventArgs, SliderTickEventArgs, SliderTooltipEventArgs } from '@syncfusion/ej2-angular-inputs';
import { Order } from '../_models/order';
import { OrderService } from '../_services/orders.services';
import { DialogComponent, DialogUtility, setSpinner } from '@syncfusion/ej2-angular-popups';   
import { placeOrder } from '../_models/placeOrders';
import { User } from '../_models/user';
import * as templateUser from '../_temlates/order.json';
import { UserRegister } from '../_models/userRegister';
import { Header, SelectEventArgs, SelectingEventArgs, TabComponent } from '@syncfusion/ej2-angular-navigations';
import * as angular from "angular";
import { Query, DataManager, Predicate } from '@syncfusion/ej2-data'; 
import { taborLista } from '../_models/taborLista';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare const myTest: any;
import { L10n } from '@syncfusion/ej2-base';
import { TimerclockService } from '../_services/timerclock.service';


if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}

@Injectable({
  providedIn: 'root'
})

 
@Component({
  selector: 'app-zlecenia',
  templateUrl: './zlecenia.component.html',
  styleUrls: ['./zlecenia.component.css'],
  
  preserveWhitespaces: true,
  providers: [FilterService,VirtualScrollService,PageService,
    SortService,
    FilterService,
    GroupService, EditService, CommandColumnService, GooglePlacesService, OrderService, CheckBoxSelectionService ],
 
}) 


export class ZleceniaComponent implements OnInit {
  


  @ViewChild('elementTab') tabObj: TabComponent;
  public mode: string;
  public Circlevisible:boolean=false;
  public sKontoDialog: boolean = false;

  public rangeValue: number[] =[0, 31000];
  public rangeValueGeo: number[] =[0, 31000];

  public type: string ="Range";
  public username: string = '+48';

  public placeOrder: placeOrder;
 

  public taborDatanaczepa_standard : taborLista = { id: 'naczepa_standard', sports: 'Naczepa standard', checked: true };
  public taborDatanaczepa_mega : taborLista = { id: 'naczepa_mega', sports: 'Naczepa mega', checked: true } ;
  public taborDataZestaw_niesymetryczny : taborLista = { id: 'zestaw_niesymetryczny', sports: 'Zestaw niesymetryczny', checked: true };
  public taborDataZestaw_symetryczny : taborLista = { id: 'zestaw_symetryczny', sports: 'Zestaw symetryczny', checked: true };
  public taborDataMega3m : taborLista = { id: 'mega_3m', sports: 'Mega 3m', checked: true };

  public placeholderza: string = 'Wybierz kraj załadunku';
  public placeholderwy: string = 'Wybierz kraj wyładunku';
  public dataFilterCountriesZa: string[]; 
  public dataFilterCountriesWy: string[]; 
  //dialogbox
  @ViewChild('template') template: DialogComponent;
  
 //dialogbox

 public showCloseIcon: Boolean = true;
 public isCollapsed: Boolean = true;
 public negocjuj: Boolean = false;

 public height: string = '720px';

 public cos :any;


  @ViewChild('ejDialog') ejDialog: DialogComponent;
  @ViewChild('DialogConfirmOrder') DialogConfirmOrder: DialogComponent;
  @ViewChild('DialogConfirmOrderNegocjuj') DialogConfirmOrderNegocjuj: DialogComponent;
  @ViewChild('DialogNotAvailableOrder') DialogNotAvailableOrder: DialogComponent;
    @ViewChild('ejInnerDialog') ejInnerDialog: DialogComponent;
   // Create element reference for dialog target element.
    @ViewChild('container', { read: ElementRef }) container: ElementRef;
    @ViewChild('container2', { read: ElementRef }) container2: ElementRef;
    // The Dialog shows within the target element.
    public targetElement: HTMLElement;
    public targetElement2: HTMLElement;


    


    public emptyDataFormatter = (field: string, data1: object, column: object) => {
      const data = 'data'; 
          
              return  data1[data].replaceAll(".", "-");
            
        }

 
    public emptygodzinaFormatter2 = (field: string, data1: object, column: object) => {
     const data_godz_od = 'data_godz_od';
           if(data1[data_godz_od] == '00:00 00:00')
           {
             return  '';
           }
           else
           {
             return  data1[data_godz_od] + '';
           }
       }

    public emptygodzinaFormatter = (field: string, data1: object, column: object) => {
      const godzina = 'godzina';

            if(data1[godzina] == '00:00')
            {
              return  '';
            }
            else
            {
              return  data1[godzina] + '';
            }
        }

    public currencyFormatter = (field: string, data1: object, column: object) => {
      const Kwota = 'kwota';

if(data1[Kwota] == 0)
{
  return  'Zaproponuj cenę';
}
else
{
  return  data1[Kwota] + ' €';
}

     
  }
   public slidervalue = 300;
    public ticks: Object = {
  placement: 'both',
  largeStep: 50,
  smallStep: 50, 
  showSmallTicks: false,
  format: '##.## Km'
    };


    public tooltip: Object ={ placement: 'After', isVisible: true, showOn: 'Always' };
    public orientation="Vertical"

    public ticksWaga: Object = {
      placement: 'both', 
      showSmallTicks: false,
      largeStep: 7000,
      smallStep: 1000, 
      format: '##### kg'
        };

      

        public filtertaborData: taborLista[];
        public taborData: taborLista[] ;
      // maps the appropriate column to fields property
      public fields: Object = { text: 'sports', value: 'id' };
      // set placeholder to MultiSelect input element
      public placeholder: string = 'Rodzaj taboru';


    public editSettings: EditSettingsModel;
    public commands: CommandModel[];
    @ViewChild('grid') public grid: GridComponent; //jest

    public selectionOptions: SelectionSettingsModel;
    public selIndex: number[] = [];
 

@ViewChild('ddlelement')//jest
public dropDownListObject: DropDownListComponent;

@ViewChild('ddlkrajza')//jest
public dropDownListKrajZaladunku: DropDownListComponent;

@ViewChild('ddlkrajwy')//jest
public dropDownListKrajWyladunku: DropDownListComponent;


@ViewChild('ddlkrajwyGeo')//jest
public dropDownListKrajWyladunkuGeo: DropDownListComponent;

public datadropDownListObject=[]; 
public fields2: Object = { text: 'Game', value: 'Id',   Latitude:51.9189046, Longitude: 19.1343786};
public fieldscos: Object = { text: 'formatted_address', value: 'place_id' };

public tooltipData01: Object = { isVisible: true, format: '##.## Km' };
  public ticksData01: Object = { placement: 'After', format: '##.## Km', largeStep: 50, smallStep: 50, showSmallTicks: false };
  fieldsprofile: any;
  public radius: number = 3; 
  public  zlecenieDoFiltra:Number;
  public text: string = "Podaj lokalizację";

  public customAttributes: Object;
  public customAttributesheader: Object;
  public filterOption: FilterSettingsModel = { type: 'Excel' };
  public pageSettings: PageSettingsModel;
public dReady: boolean = false;
public dtTime: boolean = false;
public isDataBound: boolean = false;
public isDataChanged: boolean = true;
public intervalFun: any;
public clrIntervalFun: any;
public clrIntervalFun1: any;
public clrIntervalFun2: any;    
public dropSlectedIndex: number = null;
public stTime: any;
public data: Order[];
public dataToFilter: Order[];
public dataMasterToFilter: Order[]; 
public dataToDialogBox: Order[];
public dataToDialogBoxPrice: string;
public dataToDialogBoxTabor: string;
public dataToDialogBoxWaga: string;
public dataToDialogBoxSpedytorT: string;
public dataToDialogBoxSpedytorM: string;
public dataToDialogBoxSpedytorN: string;
public dataToDialogBoxTypO: string;
public dataToDialogBoxWaluta: string;
public dataToDialogBoxKwotaK: number;



public selectedKrajZaladunkuFilter: string = null;
public selectedKraWyladunkuFilter: string = null;
public selectedKraWyladunkuFilterGeo: string = null;



public dataq: any;
public filter: Object;
public filterSettings: Object;
public selectionSettings: Object; 
  selectedOrder: Order;
  akceptowalnacena: boolean;
  dataToDialogBoxGodzOdDo: string;
  dataFilterCountriesZaFilter: string[];
  dataFilterCountriesWyFilter: string[];
  public wagaSelectedMin: number = 0;
  public wagaSelectedMax: number = 30000;

  public wagaSelectedMinGeo: number = 0;
  public wagaSelectedMaxGeo: number = 30000;

  
  
  seletetTabIndex: number;
  wagaSelectedTached: boolean = false;
  temporaryData: Order[];
  dataMasterToFilterGeoTmp: Order[];
  memoryTabor: MultiSelectComponent;
  Taborvalue: number[] | string[] | boolean[];
  DialogObj: any;
  DialogObjOrderNotAvailable: any;
 
  skillForm: FormGroup;
  build: FormBuilder;
  disableRadius: boolean = false;
  minimalnakwota: boolean = false;
 

constructor(private accountService: AccountService, private toastr: ToastrService, private googleService : GooglePlacesService, 
  private http: HttpClient, private ngZone: NgZone, private orderService: OrderService,
   @Inject(FormBuilder) private builder: FormBuilder, private router: Router, private timerclockService: TimerclockService) { 

    this.build = builder;
    this.createForm();

 
    
  }


  createForm() {
    this.skillForm = this.build.group({
      first: ['', Validators.required],
      last: ['', Validators.required],
      truck: ['', Validators.required],
      trailer: ['', Validators.required], 
      shoes: ['', Validators.required], 
      mobilePrefix: ['+48', Validators.required], 
    });
}
get first() {
    return this.skillForm.get('first');
}
get last() {
    return this.skillForm.get('last');
}
get truck() {
    return this.skillForm.get('truck');
}

get trailer() {
  return this.skillForm.get('trailer');
}

get shoes() {
  return this.skillForm.get('shoes');
}

get mobilePrefix() {
  return this.skillForm.get('mobilePrefix');
}


onSubmit() {
    alert("Form Submitted!");
}


  btnClearWyGeoClean()
    { 
      this.dropDownListKrajWyladunkuGeo.value = null;
      //this.dataFilterCountriesWy = this.dataFilterCountriesWyFilter;
      this.selectedKraWyladunkuFilterGeo = null;
      this.FilterGeolocation(); 
      this.SetOrderMarkers(); 
    }


    btnClearWy()
    { 
      this.dropDownListKrajWyladunku.value = null;
      this.dataFilterCountriesWy = this.dataFilterCountriesWyFilter;
      this.selectedKraWyladunkuFilter = null;
      this.onFilteringFilterKraj();
    }
    

    btnClearZa()
    { 
      this.dropDownListKrajZaladunku.value = null;
      this.dataFilterCountriesZa = this.dataFilterCountriesZaFilter;
      this.selectedKrajZaladunkuFilter = null;
      this.onFilteringFilterKraj();
    }

  async ngAfterViewInit(): Promise<void> {

    if(await this.orderService.isUserBan())
    {
      this.toastr.error("Użytkownik nie jest zaakceptowany");
      this.accountService.logout();
      this.router.navigateByUrl('/'); 
      return;
    }

    this.seletetTabIndex =0
    this.dataFilterCountriesZaFilter = await  this.orderService.getCountriesZa();
    this.dataFilterCountriesWyFilter = await  this.orderService.getCountriesWy();

    this.dataToFilter = await  this.orderService.getOrders();
    this.dataMasterToFilter = await  this.orderService.getOrders();

     
    

    document.getElementById('btnClearWy').onclick = () => {
      this.dropDownListKrajWyladunku.value = null;
      this.dataFilterCountriesWy = this.dataFilterCountriesWyFilter;
      this.selectedKraWyladunkuFilter = null;
      this.onFilteringFilterKraj();
    }
 


    document.getElementById('btnClearZa').onclick = () => {
      this.dropDownListKrajZaladunku.value = null;
      this.dataFilterCountriesZa = this.dataFilterCountriesZaFilter;
      this.selectedKrajZaladunkuFilter = null;
      this.onFilteringFilterKraj();
    }


    document.getElementById('btnClearGoogle').onclick = () => {
      if(this.dropDownListObject != null) 
          this.dropDownListObject.value=null;
   //this.datadropDownListObject = null;
   
    }

    this.SetMyLocation();
 
 
  }


   // Markers
   logCenter() {
    console.log(JSON.stringify(this.map.getCenter()))
  }


  title = 'angular-google-map-search';
//@ViewChild('search')
  //public searchElementRef!: ElementRef;
  @ViewChild(GoogleMap)
  public map!: GoogleMap;
 

@ViewChild(MapInfoWindow, { static: false })
info!: MapInfoWindow;

 
zoom = 5;

center!: google.maps.LatLngLiteral;
options: google.maps.MapOptions = {
  zoomControl: true,
  scrollwheel: true,
  disableDefaultUI: true,
  fullscreenControl: true,
  disableDoubleClickZoom: true,
  //mapTypeId: 'hybrid',
  mapTypeId: 'roadmap',
  // maxZoom:this.maxZoom,
  // minZoom:this.minZoom,
  backgroundColor: 'white',
  //center: { lat: 51.9189046, lng: 19.1343786 },
  //center: { lat: 51.339620, lng: 12.3712900 },
};
latitude!: any;
longitude!: any;

markers = []  as  any;
infoContent = ''

 

  openInfo(marker: MapMarker, content: string) {
    this.infoContent = content;
    this.info.open(marker)
  }

    ngAfterContentInit() {
   
    
    this.initilaizeTarget(); 

   
}



public locale: string = 'en-US';

    //To get all element of the dialog component after component get initialized.
    async ngOnInit() {

     
      L10n.load({
        'en-US': {
        'multi-select': {
               // 'noRecordsTemplate': "Aucun enregistrement trouvé",
            //  'actionFailureTemplate': "Modèle d'échec d'action",
                'overflowCountTemplate': "+${count} więcej..",
                'totalCountTemplate': "${count} więcej.."
            }
        }
    });


    this.timerclockService.download2222();


      //  this.taborData  = [ { id: 'naczepa_standard', sports: 'Naczepa standard', checked: false },
      //    { id: 'naczepa_mega', sports: 'Naczepa mega', checked: false },
      //    { id: 'zestaw_niesymetryczny', sports: 'Zestaw niesymetryczny' , checked: false},
      //    { id: 'zestaw_symetryczny', sports: 'Zestaw symetryczny' , checked: false} 
      //  ] 

        this.taborData  = [ { id: 'naczepa_standard', sports: 'JUMBO 9,1+6,3', checked: false },
          { id: 'naczepa_mega', sports: 'JUMBO 8+7 (38 EUROPALET)', checked: false },
          { id: 'zestaw_niesymetryczny', sports: 'JUMBO 7,7+7,7' , checked: false},
          { id: 'zestaw_symetryczny', sports: 'STANDARD' , checked: false},
          { id: 'mega_3m', sports: 'MEGA (3m)' , checked: false} 
        ] 



      this.filtertaborData = this.taborData;
      
      this.mode = 'CheckBox';

      this.placeOrder= { 
        przewoznik: "",
        komentarz:  "",
        orderId:0,
        price:0,
            comment: "",
            userId:0,
            username:"" ,
            zlecenieId: 0 ,
            first: "" ,
            last: "" , 
            shoes: "" ,
            mobilePrefix: "" ,
            truck: "" ,
            trailer: "" ,
            datA_RZ_P: new Date() ,
            datA_RZ: new Date() ,
            datA_RZ_K: new Date() ,
            datA_RZ_W: new Date() ,
            lokalizacja: "" ,
            spanRow: 0,
            status: "" ,
            zaakceptowane:false,
            rejected:false,
            cena: 0,
            waluta: "" ,
            offerdate: new Date() ,
           typ:"" ,
           iD_ZLECENIA_ZA_WY:0,
           qtyDate:0,
           spedytor:"" ,
           spedytorEmail:"" ,
           spedytorTel:"" ,
           kupTeraz:0,
           firma:"",
           oferowanaCena:0,
           isAttachment:false,
           skonto:false,expanded:[],  data_: new Date(),
           data_Godz: "",
           data_WY: new Date(),
           data_Godz_WY: "", 
           data_Godz_Od : "",
           data_Godz_Do:  "",
           userName: "", 
           userEmail: "",
           userKom: "",
           trasa:"",
           isNegotiation:0,
            user: {userId: 1, 
                  userName: "" ,
                  PasswordHash:"",
                  PasswordSalt:"" , 
                  uzytkownikId: 0,
                  usernameDto:null,
                  mobile: "",
                  mobilePrefix: "",
                  email: "",
                  idKontrah:0,
                  accepted:false,
                  datarejstracji: new Date() ,
                  isAdmin: false,
                  userCompanyId: null,
                  UserComapnyCompany: {UserId: 2, nip: "",  nazwa: "",   miejscowosc: "",   ulica: "",  nrNieruchomosci: "",},
                  orders: [],
                  password: "",
                  emailConfirmedToken: "",
                  emailConfirmed:false,
                  userComapnyCompanyId:0,
                  companyId:0,
                  isUserAdmin : false 
          } 
       }
    
      this.datadropDownListObject.push( 
        { Id: 'Twoja lokalizacja', 
         Game: 'Twoja lokalizacja', 
         Latitude: 51.9189046, 
         Longitude: 19.1343786
       
       } );

       this.selectionOptions = { type: 'Multiple' };

        
       this.pageSettings = { pageSize: 2000 };
        this.dataFilterCountriesZa = await  this.orderService.getCountriesZa();
         this.dataFilterCountriesWy = await  this.orderService.getCountriesWy();
         await  this.orderService.getOrders().then(ref => {

        this.data =ref;
       // this.grid.hideSpinner = () => false;
         
      }).catch(err => console.error(err));


       //this.dataToFilter = await  this.orderService.getOrders();
         this.filterSettings = { type: "Menu" };      
         this.filter = { type: "CheckBox" };    
        this.stTime = performance.now();
         this.selectionSettings = {persistSelection: true, type: "Multiple", checkboxOnly: true };
         this.customAttributes = {class: 'customcss'};
         this.customAttributesheader = {class: 'customcssheader'};
   
         this.editSettings = { allowEditing: true, allowDeleting: true };
    

       //  this.commands = [{ type: 'Edit', buttonOption: { cssClass: 'customcssheader', iconCss: 'customcssheader' }  }   ];

       this.commands = [{ type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } }   ];
       //   this.commands= [{title:"Search",buttonOption:{cssClass:'e-flat' , iconCss:'e-icons e-search' ,   click:this.onClick.bind(this)}} ];
    }
    onClick(e:any){
      console.log("Click");
    }
 

    async SetMyLocation() {

      this.radius = 300 * 1000;
        await this.getMyLocalization().then(ref =>{
          this.FilterGeolocation() ;
          this.SetOrderMarkers(false);
        }); 
       
         
    }

 
    
    onChange(args): void {
 

      if  (args.value == "Twoja lokalizacja")
      {
         this.SetMyLocation(); 

         this.disableRadius = true;
      
        return;
      } 
     
       if(args.itemData != null)
       {

        this.radius = 300 * 1000;

        this.latitude = args.itemData.Latitude;
        this.longitude = args.itemData.Longitude;
        this.center = {
          lat: this.latitude,
          lng: this.longitude,
        };
  
  
        this.markers=[];
  
        this.markers.push({
          position: {
            lat: args.itemData.Latitude,
            lng: args.itemData.Longitude,
          },
      
          label: {
            color: 'black',
            text: 'TU JESTEŚ ' ,
          },
          title: 'Marker title ' + (this.markers.length + 1),
          info: 'Marker info ' + (this.markers.length + 1),
          options: {
            animation: google.maps.Animation.DROP 
          },
        })

      }

        this.FilterGeolocation() ;
        this.SetOrderMarkers(false);  

      //  this.disableRadius = true;
   
  }
     


  FilterGeolocation() {
    const zlecenia : Number[] = [];
 
    let data = [];    

    let uemail : MultiSelectComponent | undefined;
    let iloscZaznaczonych = 0

    if(this.taborFiltrGeo != uemail)
    { 
      if(this.taborFiltrGeo.value != null)
          iloscZaznaczonych = this.taborFiltrGeo.value.length; 
    } 

    if(this.dataToFilter != null)
    {
    this.dataToFilter.forEach(element => {    

 
 

      let existWy = false;
      let setOthers: Order[] = this.dataToFilter.filter(x => x.idZlecenia === element.idZlecenia); 

  for(let i=0; i< setOthers.length; i++){ 

    let jestwPromieniu:boolean = false;

    let wagaJest:boolean =   this.wagaJestGeo(element);   //  waga
      if(!wagaJest)
            continue;



        if(this.dropDownListObject.value != null)
        {

          if(setOthers[i].typ == 'ZA')
          {
            let distance =  this.googleService.getDistanceBetweenPoints(this.latitude, this.longitude, setOthers[i].lat, setOthers[i].lng)
            if(this.slidervalue >= distance)
            {
              jestwPromieniu = true;   //  promieniu
            }
          }
        }

        if(this.selectedKraWyladunkuFilterGeo  != null) {  


          let finded = setOthers.find(x=>x.typ === 'WY' && x.kraj === this.selectedKraWyladunkuFilterGeo)

          if(finded != null) 
          { 
            existWy=true; //  kraj wyładunku
          }
        }

       let existTabor = this.CheckInTabor(setOthers[i], true);  //  tabor 



       if(jestwPromieniu && this.selectedKraWyladunkuFilterGeo === null &&  iloscZaznaczonych == 0 && wagaJest)
       {
          if (!zlecenia.includes(element.idZlecenia)) 
          {   zlecenia.push(element.idZlecenia); } 
       }

       if(this.selectedKraWyladunkuFilterGeo  != null && existWy && this.dropDownListObject.value == null &&  iloscZaznaczonych == 0 && wagaJest)
       {
            if (!zlecenia.includes(element.idZlecenia)) 
            {   zlecenia.push(element.idZlecenia); } 
       }

       if(this.selectedKraWyladunkuFilterGeo  != null && existWy && this.dropDownListObject.value != null && jestwPromieniu && iloscZaznaczonych == 0 && wagaJest)
       {
            if (!zlecenia.includes(element.idZlecenia)) 
            {   zlecenia.push(element.idZlecenia); } 
       }

       if(this.dropDownListObject.value == null  && this.selectedKraWyladunkuFilterGeo  == null && iloscZaznaczonych  == 0 && wagaJest ) 
       {
        if (!zlecenia.includes(element.idZlecenia)) 
        {   zlecenia.push(element.idZlecenia); } 
       }


       if(existTabor &&  this.dropDownListObject.value == null &&  this.selectedKraWyladunkuFilterGeo  == null    && wagaJest)
          if (!zlecenia.includes(element.idZlecenia)) {   zlecenia.push(element.idZlecenia); } 

          if(existTabor &&  jestwPromieniu &&  this.selectedKraWyladunkuFilterGeo  == null    && wagaJest)
          if (!zlecenia.includes(element.idZlecenia)) {   zlecenia.push(element.idZlecenia); } 


          if(existTabor &&  jestwPromieniu &&  existWy    && wagaJest)
          if (!zlecenia.includes(element.idZlecenia)) {   zlecenia.push(element.idZlecenia); } 
    
      }
 
 
     
     });

 

     this.dataToFilter.forEach(element => {  
        if(zlecenia.includes(element.idZlecenia) )
        {
          data.push(element);
        }
     });
    }

 
 


    if(this.dropDownListObject.value === null && this.selectedKraWyladunkuFilterGeo === null &&  iloscZaznaczonych == 0 && this.wagaSelectedTached == false)
    {
        if(data.length == 0)
          {
            data= this.dataToFilter;
          }
    }


    if( this.seletetTabIndex == 1 )
    {
      this.dataMasterToFilter = data;
      this.dataMasterToFilterGeoTmp  = data;
    } 

    
  }


  

  @ViewChild('taborFiltr')
  public taborFiltrObj: MultiSelectComponent;
  @ViewChild('taborFiltrGeo')
  public taborFiltrGeo: MultiSelectComponent;

  


  onRemovedGeo(args) {

    this.FilterGeolocation();
    this.SetOrderMarkers(); 
  //  let t  = args.itemData;
   // this.SetTaborFilter();
  }


  onRemoved(args) {

    this.MasterGlobalFilter();
  //  let t  = args.itemData;
   // this.SetTaborFilter();
  }



  public onRemove(args) {

    this.MasterGlobalFilter();
  //  let t  = args.itemData;
//
//
  //  this.taborFiltrObj.value.forEach((element,index)=>{
  //    if(element==2) this.taborFiltrObj.value.splice(index,1);
  // });
    //this.SetTaborFilter();
  }

  public   OnValueRemove = (e: RemoveEventArgs) => {
    { 

      let t  = e.itemData;
     
    this.SetTaborFilter();
        //remove the unselected items from multiselect to custom List  
        //_selectedLineIds.Remove(e.ItemData); 
    }
  } 
  

  public onTagging = (e: TaggingEventArgs) => {
  
    this.SetTaborFilter();


    //sthis.taborFiltrObj.value.forEach((element,index)=>{
    //s   if(element==2) this.taborFiltrObj.value.splice(index,1);
    //s });


     
  }


   public onSelection(args, dropObj){
      debugger
      args.cancel = true;
      dropObj.mainList.querySelector('li[data-value="'+dropObj.inputElement.value+'"]').remove()
      dropObj.mainData.pop();
    }


  

  public onTaggingGeo = (e: TaggingEventArgs) => { 
    this.FilterGeolocation();
    this.SetOrderMarkers(); 
    
  }


  SetTaborFilter() { 
    this.MasterGlobalFilter();
    return; 
  } 


 


  public  changeTaborGeo(): void {
  
      this.FilterGeolocation();
      this.SetOrderMarkers(); 
  }


  public changeTabor(): void {
 
      this.SetTaborFilter();

      this.Taborvalue = this.taborFiltrObj.value;
  }

public onFilteringTabor: EmitType<object> =  (e: FilteringEventArgs) => {
  //Query the data source based on country MultiSelect selected value
 let pred:Predicate,temp:any;
 
 
 
 
 //this.cityObj.setProperties({query:new Query().where(pred),enabled:true,values:[]});
}

  public onFiltering: EmitType<object>  =  async (e: FilteringEventArgs) => {

    if(e.text.length > 3)
  {
  
   this.datadropDownListObject = [];
  
   this.datadropDownListObject.push( 
    { Id: 'Twoja lokalizacja', 
     Game: 'Twoja lokalizacja', 
     Latitude: 51.9189046, 
     Longitude: 19.1343786
   
   } );
  
   
   this.cos = await this.googleService.search(e.text);
   
   if(this.cos.length>0)
   {
   this.fieldsprofile = this.cos 

  
    Object.keys(this.fieldsprofile).map(key => this.datadropDownListObject.push( 
      { Id: this.fieldsprofile[key].place_id, 
       Game: this.fieldsprofile[key].formatted_address,
       Latitude: this.fieldsprofile[key].lat,
       Longitude: this.fieldsprofile[key].lng 
  
       
     
     } )) // ["700", ["test1", "test2"], "test", "450"] // Note: Order isn't guaranteed
     e.updateData(this.datadropDownListObject);
    }
   
   
  }
    
    
  
  }


    

  onFilteringFilterKraj()
{

  this.MasterGlobalFilter();

  return;

  const zlecenia : Number[] = []; 
  const dostepneKrajeZa : string[] = []; 
  const dostepneKrajeWy : string[] = []; 
    this.data = [];     
    if(this.dataToFilter != null)
    {


      if(this.dataMasterToFilter.length === 0)
              this.dataMasterToFilter = this.dataToFilter;


      for(let key in this.dataMasterToFilter) {
        let child = this.dataMasterToFilter[key];
        if(this.selectedKrajZaladunkuFilter != null && this.selectedKraWyladunkuFilter == null)
          { 
            if(child.kraj == this.selectedKrajZaladunkuFilter && child.typ == 'ZA')
            {
              if (!zlecenia.includes(child.idZlecenia)) {
                    zlecenia.push(child.idZlecenia);
                  }   
            }
         
          }
        }


        for(let key in this.dataMasterToFilter) {
          let child = this.dataMasterToFilter[key];
          if(this.selectedKraWyladunkuFilter != null && this.selectedKrajZaladunkuFilter == null)
          {
           if(child.kraj == this.selectedKraWyladunkuFilter && child.typ == 'WY')
           {
              if (!zlecenia.includes(child.idZlecenia)) {
                    zlecenia.push(child.idZlecenia);
                  }  
           } 
          }
        }

      

        let zlecenieId = this.dataMasterToFilter[0].idZlecenia;
        for(let i=0; i<this.dataMasterToFilter.length; i++){ 


          if(i == 0 )
            zlecenieId = this.dataMasterToFilter[i].idZlecenia;



            if(zlecenieId == this.dataMasterToFilter[i].idZlecenia)
            {
              if(this.selectedKraWyladunkuFilter ==  this.dataMasterToFilter[i].kraj && this.dataMasterToFilter[i].typ == 'WY')
              resultWy = true;


              if(this.selectedKrajZaladunkuFilter ==  this.dataMasterToFilter[i].kraj && this.dataMasterToFilter[i].typ == 'ZA')
              resultZa = true;

              if(resultWy && resultZa)
                    zlecenia.push(this.dataMasterToFilter[i].idZlecenia);

            }
            else
            {
              resultZa = false;
              resultWy = false;
              zlecenieId = this.dataMasterToFilter[i+1].idZlecenia;
              i = i -1;
            } 
      }

      this.dataMasterToFilter = [];
      for(let key in this.dataToFilter) {
        let child = this.dataToFilter[key];
          if(zlecenia.includes(child.idZlecenia) )
          {
            this.data.push(child);
            this.dataMasterToFilter.push(child);
          }
        }


        if(zlecenia.length === 0)
            this.data = this.dataToFilter;




        for(let key in this.data) {
          let child = this.data[key];

          
          this.dataFilterCountriesWy
          
           
            if(zlecenia.includes(child.idZlecenia) )
            {
              this.data.push(child);
              this.dataMasterToFilter.push(child);
            }
          }

 

      this.SetDropDownKraje();
      this.SetDropDownTabor();


 

        let gr  = this.groupBy(this.dataToFilter, 'idZlecenia') as Order[]  ;


        let total = 0;

        Object.keys(gr).forEach((student) => {
    if (this.selectedKraWyladunkuFilter == student) {
        total++;
    }
});


        var resultWy = false;
        var resultZa = false;

      
        for(let i=0; i<gr.length; i++){  
          if(this.selectedKraWyladunkuFilter == gr[i].kraj)
          resultWy = true;
      }


      for(let i=0; i<gr.length; i++){  
        if(this.selectedKrajZaladunkuFilter == gr[i].kraj)
        resultZa = true;
    }
 

       let fff =   gr.find(x=>x.kraj == this.selectedKrajZaladunkuFilter);

       let ff22f =   gr.find(x=>x.typ == this.selectedKrajZaladunkuFilter);

 
      return; 
    
    }

}


MasterGlobalFilter()
{

  const zlecenia : Number[] = []; 
 //dataSource
let Wy = this.dataFilterCountriesWy;
let Za = this.dataFilterCountriesZa;
let Tabor = this.taborData;

let SelectedTabor = this.taborFiltrObj.value;
let SelectedZa = this.selectedKrajZaladunkuFilter;
let SelectedWy = this.selectedKraWyladunkuFilter;
 

let iloscZaznaczonych = this.taborFiltrObj.value == null ? 0 : this.taborFiltrObj.value.length;

 
for(let key in this.dataToFilter) {
  
  let zlecenieTmp = this.dataToFilter[key];

  if(zlecenieTmp.idZlecenia == 1184210)
  {
    let val = 66;
  }

  if(zlecenieTmp.kraj == "LT")
  {
    let val = 66;
  }
  
 
  let wagaJest:boolean =   this.wagaJest(zlecenieTmp);
  
  
  if(!wagaJest)
      continue;  

  let existZa:boolean = false;
  let existWy:boolean = false;
  let existTabor :boolean = false;

  let setOthers: Order[] = this.dataToFilter.filter(x => x.idZlecenia === zlecenieTmp.idZlecenia); 

  for(let i=0; i< setOthers.length; i++){ 


    if(SelectedZa != null) {
      if(setOthers[i].kraj === SelectedZa && setOthers[i].typ === 'ZA')
        { 
          existZa=true;
        }
    }


    if(SelectedWy != null) {
        if(setOthers[i].kraj === SelectedWy && setOthers[i].typ === 'WY') 
        { 
          existWy=true;
        }
    }

        existTabor = this.CheckInTabor(setOthers[i], false); 
    
  }
  
  let uemail : string | undefined;

 

  if(existZa  && SelectedWy === null &&  iloscZaznaczonych == 0 && wagaJest)
  { 
          if (!zlecenia.includes(zlecenieTmp.idZlecenia)) 
          {   zlecenia.push(zlecenieTmp.idZlecenia); } 
  }


  if(existWy && SelectedZa === null &&  iloscZaznaczonych == 0 && wagaJest) 
  { 
          if (!zlecenia.includes(zlecenieTmp.idZlecenia)) {   zlecenia.push(zlecenieTmp.idZlecenia); } 
        }

  if(existWy && existZa   &&  iloscZaznaczonych == 0 && wagaJest) 
  { 
        {if (!zlecenia.includes(zlecenieTmp.idZlecenia)) {   zlecenia.push(zlecenieTmp.idZlecenia); } 
      } 
  }

  if(existTabor && existWy && existZa && wagaJest )
  if (!zlecenia.includes(zlecenieTmp.idZlecenia)) {   zlecenia.push(zlecenieTmp.idZlecenia); } 


  if(existTabor && existZa &&  SelectedWy === null && wagaJest)
  if (!zlecenia.includes(zlecenieTmp.idZlecenia)) {   zlecenia.push(zlecenieTmp.idZlecenia); } 

  if(existTabor && existWy &&  SelectedZa === null && wagaJest)
  if (!zlecenia.includes(zlecenieTmp.idZlecenia)) {   zlecenia.push(zlecenieTmp.idZlecenia); } 


  if(existTabor &&    SelectedWy === null  &&  SelectedZa === null && wagaJest)
  if (!zlecenia.includes(zlecenieTmp.idZlecenia)) {   zlecenia.push(zlecenieTmp.idZlecenia); } 


  if(iloscZaznaczonych == 0 &&    SelectedWy === null  &&  SelectedZa === null && wagaJest)
  if (!zlecenia.includes(zlecenieTmp.idZlecenia)) {   zlecenia.push(zlecenieTmp.idZlecenia); } 


  }

  this.data=[];
     this.dataToFilter.forEach(element => {  
     if(zlecenia.includes(element.idZlecenia) )
      {
       this.data.push(element);
      }
   });

    if(SelectedWy === null && SelectedZa === null &&  iloscZaznaczonych == 0 )
    {
        if(this.data.length == 0)
          {
            this.data= this.dataToFilter;
          }
    }



    if( this.seletetTabIndex == 0 )
    {
      this.dataMasterToFilter = this.data;
    }
}



wagaJestGeo(zlecenieTmp: Order): boolean {

  let wagaZlecenie = this.ClenWaga(zlecenieTmp);


  if(this.wagaSelectedMinGeo  <= wagaZlecenie && wagaZlecenie <=  this.wagaSelectedMaxGeo)
    return true;
  else
    return false;
     
}

  wagaJest(zlecenieTmp: Order): boolean {

    let wagaZlecenie = this.ClenWaga(zlecenieTmp);


    if(this.wagaSelectedMin  <= wagaZlecenie && wagaZlecenie <=  this.wagaSelectedMax)
      return true;
    else
      return false;
       
  }

  ClenWaga(zlecenieTmp: Order) : Number {
      "1176 KG"
      let clearWaga = zlecenieTmp.waga.replace(' ','');
        clearWaga = clearWaga.replace('KG','');
        clearWaga = clearWaga.replace('kg','');
        clearWaga = clearWaga.replace(' ','');

        return Number(clearWaga); 
       
  }

  CheckInTabor(zlecenieTmp: Order, jestGeo:Boolean) { 
 let isSelectedNaczepaMega:number= 0;
let isSelectedNaczepaStandard:number= 0;
let isSelectedZestawNiesymetryczny:number= 0;
let isSelectedZestawSymetryczny:number= 0;
let isSelectedMega3m:number= 0;

let taborDropDown: MultiSelectComponent;

if  (jestGeo)
{
  taborDropDown = this.taborFiltrGeo;
}
else
{
  taborDropDown = this.taborFiltrObj
}

let uemail : MultiSelectComponent | undefined;
if(taborDropDown === uemail)
    return false; 
 
  if(taborDropDown.value === null)
      return false; 

for(let i=0; i< taborDropDown.value.length; i++){ 
 
  let aaa =  taborDropDown.value[i].toString(); 
      if(aaa == 'naczepa_standard')   
          isSelectedNaczepaStandard = 1 
      if(aaa == 'naczepa_mega')   
            isSelectedNaczepaMega = 1 
      if( aaa == 'zestaw_niesymetryczny')   
            isSelectedZestawNiesymetryczny = 1 
      if( aaa == 'zestaw_symetryczny')   
            isSelectedZestawSymetryczny = 1 
      if( aaa == 'mega_3m')   
            isSelectedMega3m = 1 
  }
    return zlecenieTmp.naczepa_mega == 1 &&  isSelectedNaczepaMega == 1
    || zlecenieTmp.naczepa_standard == 1 &&  isSelectedNaczepaStandard == 1
    || zlecenieTmp.zestaw_niesymetryczny == 1 &&  isSelectedZestawNiesymetryczny == 1
    || zlecenieTmp.zestaw_symetryczny == 1 && isSelectedZestawSymetryczny  == 1
    || zlecenieTmp.mega3m == 1 && isSelectedMega3m  == 1
  }


  

SetDropDownTabor() {


  //return this.dataMasterToFilter.filter(x => x.typ === id);

  this.taborData = [];





  for(let key in this.dataToFilter) {
 

    let child = this.dataToFilter[key];

  
    if( this.taborFiltrObj.value === null){ 

      if(child.naczepa_standard == 1 ) 
            this.AddOrOpdateTaborData(this.taborDatanaczepa_standard,-1 );

      if(child.naczepa_mega == 1 ) 
            this.AddOrOpdateTaborData(this.taborDatanaczepa_mega, -1); 

      if(child.zestaw_niesymetryczny == 1 )
              this.AddOrOpdateTaborData(this.taborDataZestaw_niesymetryczny, -1); 

      if(child.zestaw_symetryczny == 1 )
              this.AddOrOpdateTaborData(this.taborDataZestaw_symetryczny, -1);  

      if(child.mega3m == 1 )
              this.AddOrOpdateTaborData(this.taborDataMega3m, -1);  
    }
else 
    {

    for(let i=0; i<this.taborFiltrObj.value.length; i++){  
   
        let fitrTabor = this.taborFiltrObj.value[i].toString(); 

        if(child.naczepa_standard == 1 && fitrTabor == 'naczepa_standard')   
              this.AddOrOpdateTaborData(this.taborDatanaczepa_standard, child.idZlecenia);  
        if(child.naczepa_mega == 1 && fitrTabor == 'naczepa_mega') 
            this.AddOrOpdateTaborData(this.taborDatanaczepa_mega, child.idZlecenia);  
        if(child.zestaw_niesymetryczny == 1 && fitrTabor == 'zestaw_niesymetryczny') 
             this.AddOrOpdateTaborData(this.taborDataZestaw_niesymetryczny, child.idZlecenia);  
        if(child.zestaw_niesymetryczny == 1 && fitrTabor == 'zestaw_symetryczny')   
             this.AddOrOpdateTaborData(this.taborDataZestaw_symetryczny, child.idZlecenia); 
        if(child.mega3m == 1 && fitrTabor == 'mega_3m')   
             this.AddOrOpdateTaborData(this.taborDataMega3m, child.idZlecenia);


      

        
           
      }
        //  this.taborData = this.filtertaborData.filter(x => x.id === id); 
    } 
  }

  if(this.selectedKraWyladunkuFilter == null && this.selectedKrajZaladunkuFilter == null) 
    if(this.taborData.length === 0)
          this.taborData = this.filtertaborData;
}
  


AddOrOpdateTaborData(tabor : taborLista, idZlecenia : number) { 
    if(!this.taborData.find(x => x.id === tabor.id))
            this.taborData.push(tabor); 


             if(idZlecenia != -1)
             { 
                let setOthers: Order[] = this.dataToFilter.filter(x => x.idZlecenia === idZlecenia); 

                for(let i=0; i< setOthers.length; i++){ 
                
                  if(setOthers[i].naczepa_standard == 1  )   
                    this.AddOrOpdateTaborData(this.taborDatanaczepa_standard, -1);  
                  if(setOthers[i].naczepa_mega == 1 ) 
                    this.AddOrOpdateTaborData(this.taborDatanaczepa_mega, -1);  
                  if(setOthers[i].zestaw_niesymetryczny == 1  ) 
                    this.AddOrOpdateTaborData(this.taborDataZestaw_niesymetryczny, -1);  
                  if(setOthers[i].zestaw_niesymetryczny == 1  )   
                    this.AddOrOpdateTaborData(this.taborDataZestaw_symetryczny, -1);  
                    if(setOthers[i].mega3m == 1  )   
                    this.AddOrOpdateTaborData(this.taborDataMega3m, -1); 
                } 
              }
            
   }

  
 

  SetDropDownKraje() {


    this.MasterGlobalFilter();
    return;
   
   //let sssWY =    this.getDimensionsByFilter("WY");

   //if(sssWY.length > 0)
   //{
   //
   //  this.dataFilterCountriesWy = [];

   //      let grWy  = this.groupBy(sssWY, 'kraj') as Order[]  ; 

   //        for(let key in grWy) { 
   //      this.dataFilterCountriesWy.push(key); 
   //      }  
   //}




   //let sssZA =   this.getDimensionsByFilter("ZA");

   //if(sssZA.length > 0)
   //{
   //
   //    this.dataFilterCountriesZa = [];
   //        let grZa  = this.groupBy(sssZA, 'kraj') as Order[]  ; 
   //        for(let key in grZa) { 
   //          this.dataFilterCountriesZa.push(key); 
   //        }


    //}
  }


GetKrajZaladunku() 
{
  return this.selectedKrajZaladunkuFilter;
}

GetKrajWyladunku()
{
  return this.selectedKraWyladunkuFilter;
}

  groupBy(dataToFilter: Order[], arg1: string) {
    return dataToFilter.reduce((groups, item) => {
      const val = item[arg1];
      groups[val] = groups[val] || [];
      groups[val].push(item);
      return groups;
  }, {});
  }

onFilteringFilterKrajZa(args): void { 

  if(args.value == null)
  return;

  this.selectedKrajZaladunkuFilter = args.value;

  this.onFilteringFilterKraj();
}

onFilteringFilterKrajWyGeo(args): void { 

  if(args.value == null)
  return;

  this.selectedKraWyladunkuFilterGeo = args.value;

  this.FilterGeolocation();
  this.SetOrderMarkers(); 

}


onFilteringFilterKrajWy(args): void { 


  if(args.value == null)
  return;

  this.selectedKraWyladunkuFilter = args.value;

  this.onFilteringFilterKraj();

 //const zlecenia : Number[] = []; 
 //  this.data = [];     
 //  if(this.dataToFilter != null)
 //  {
 //  this.dataToFilter.forEach(element => {   
 //     if(element.kraj == args.value && element.typ == 'WY')
 //     {
 //        if (!zlecenia.includes(element.idZlecenia)) {
 //              zlecenia.push(element.idZlecenia);
 //            }  
 //     }
 //   }); 


 //   this.dataToFilter.forEach(element => {  
 //    if(zlecenia.includes(element.idZlecenia) )
 //    {
 //      this.data.push(element);
 //    }
 // });
 //  }
}

public sliderRadiusChange(args: SliderChangeEventArgs, cyfra:any) {


  if(args.isInteracted == false)
      return;
 
  if(this.dropDownListObject == null) 
  {
      //this.disableRadius = true;
      this.rangeValueGeo = [0, 31000];
      this.slidervalue = 300; 
      return;
  }


  console.log(args.value);
  this.radius = Number(args.value) * 1000;
  this.slidervalue = Number(args.value)

  this.FilterGeolocation();




  if(args.value != 300) 
      this.SetOrderMarkers(); 


      this.markers.push({
        position: {
          lat: this.latitude,
          lng: this.longitude,
        },
    
        label: {
          color: 'black',
          text: 'TU JESTEŚ ' ,
        },
        title: 'Marker title ' + (this.markers.length + 1),
        info: 'Marker info ' + (this.markers.length + 1),
        options: {
          animation: google.maps.Animation.DROP 
        },
      })
}


public sliderChangeKgGeo(args: SliderChangeEventArgs) {

  this.wagaSelectedMinGeo = args.value[0] ;
  this.wagaSelectedMaxGeo = args.value[1] ;
  this.wagaSelectedTached  = true ;

    this.FilterGeolocation();
    this.SetOrderMarkers(); 

  return;
}


public sliderChangeKg(args: SliderChangeEventArgs) {

  this.wagaSelectedMin = args.value[0] ;
  this.wagaSelectedMax = args.value[1] ;

    this.MasterGlobalFilter();

  return;

  console.log(args.value);
  const zlecenia : Number[] = []; 

  
  this.data = [];

  if(this.dataToFilter != null)
    {
    this.dataToFilter.forEach(element => {    
     
      "1176 KG"
      let clearWaga = element.waga.replace(' ','');
        clearWaga = element.waga.replace('KG','');


        let y = Number(clearWaga);
 
       if( args.value[0]  <= y && y <= args.value[1])
       {
          if (!zlecenia.includes(element.idZlecenia)) {
                zlecenia.push(element.idZlecenia);
              }  
       }
     });

     this.dataToFilter.forEach(element => {  
        if(zlecenia.includes(element.idZlecenia) )
        {
          this.data.push(element);
        }
     });
    }
}

public queryCellInfoEvent: EmitType<QueryCellInfoEventArgs> = (args: QueryCellInfoEventArgs) => {
  
  const data: Order = args.data as Order;

  const selectedrowindex: number[] = this.grid.getSelectedRowIndexes();  // Get the selected row indexes.

        if (args.column.field === 'idZlecenia'  || args.column.field === 'status' || args.column.field === 'kwota' || args.column.field === 'waluta' 
        || args.column.field === 'taborProfil'  || args.column.field === 'details' || args.column.field === 'tabor'   ) {
          
          args.rowSpan = data.spanRow; 

          if(args.column.field === 'kwota')
          {
            //(args.cell as any).style.backgroundColor ="blue" ;
            (args.cell as any).style.color = '#00A0E3' ;

          }
          
            if( this.zlecenieDoFiltra == data.idZlecenia )
            {
            // (args.cell as any).style.backgroundColor ="blue" ; 
             // args.cell.classList.add('below-80');  
            } 
            else   
            {
              //args.cell.classList.add('below-30'); 
            //  (args.cell as any).style.backgroundColor ="lightgrey" ; 
            
            //  
            }
          //args.cell.classList.add('above-80'); 
          }   
  }

  SetOrderMarkers(withoutClean = true)
  {

    //var image = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
    var image ="/assets/images/lokalizacja.png?"
    var pictureLabel = document.createElement("img");
  //  pictureLabel.src = YOUR_SOURCE;
   // pictureLabel.width = SOME_WIDTH;
   // pictureLabel.height = SOME_HEIGHT;
    // daneden even got the exact animation your'e looking for :)
    pictureLabel.className = pictureLabel.className + " animated fadeInDown";


    if(withoutClean)
    this.markers=[];

    if(this.dataMasterToFilter == null)
    return; 

    if(this.dataMasterToFilter.length == 0)
    return; 
 

    this.dataMasterToFilter.forEach(element => {   
      if(element.typ == 'ZA')
      {
        this.markers.push({
          position: {
            lat: element.lat,
            lng: element.lng,
          },
    
         //label: {
         //  color: 'white',
         //  text: ''
         //},
          //title: 'Marker title ' + (this.markers.length + 1),
          info: 'Nr zlecenia: ' + element.idZlecenia,
          options: {
            animation: google.maps.Animation.DROP  
            ,  icon: image
          }
        })
      }
    }); 
  }


  

  getCoordinates() {
    return new Promise(function(resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }
  
  
  async getMyLocalization() { 

    await this.getCoordinates().then(  (position: GeolocationPosition) => {  
  
      if(position instanceof GeolocationPosition )
      {  
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;
  
        this.center = {
          lat: lat,
          lng: lng,
        }; 
      
        this.latitude = lat;
        this.longitude = lng; 
 
        this.markers=[];
  
        this.markers.push({
          position: {
            lat:  this.latitude,
            lng: this.longitude,
          },
      
          label: {
            color: 'black',
            text: 'TU JESTEŚ ' ,
          },
          title: 'Marker title ' + (this.markers.length + 1),
          info: 'Marker info ' + (this.markers.length + 1),
          options: {
            animation: google.maps.Animation.DROP 
          },
        }) 
      }   
    }); 
  
  }

  rowBound(args: RowDataBoundEventArgs) {
   //dataMasterToFilter
   // this.SetOrderMarkers(); 
   
}


rowSelected(args: RowSelectEventArgs) {
  const selectedrowindex: number[] = this.grid.getSelectedRowIndexes();  // Get the selected row indexes.
 // alert(selectedrowindex); // To alert the selected row indexes.
  const selectedrecords: object[] = this.grid.getSelectedRecords();  // Get the selected records.



  this.selectedOrder = args.data as Order;
 
  const  indexs: number[] = []; 

  const data: Order = args.data as Order;


  //args.previousRow.classList.add('below-30'); 
  

 // alert (data.idZlecenia);
 //(sdata['idZlecenia'] as any).classList.add('below-30'); 
 

  (this.grid.dataSource as object[]).forEach((sdata, index) => { 
    if (sdata['idZlecenia'] == data.idZlecenia) {
       indexs.push(index);
     // sdata['idZlecenia'].style.backgroundColor ="red" ;
    //  sdata[index].style
   //   (sdata['idZlecenia'] as any).classList.add('below-30'); 
    //  args.cell.classList.add('below-80'); 
      
   }
   else{
    //(sdata['idZlecenia'] as any).style.backgroundColor ="blue" ; 
   }
  });
  //this.grid.selectionModule.selectRows(indexs);

}

public updateTextValue: EmitType<object> = () => {
  let enteredVal: HTMLInputElement = document.getElementById('inVal') as HTMLInputElement;
  let dialogTextElement: HTMLElement = document.getElementsByClassName('dialogText')[0] as HTMLElement;
  let dialogTextWrap : HTMLElement = document.getElementsByClassName('dialogContent')[0] as HTMLElement;
  if (!isNullOrUndefined(document.getElementsByClassName('contentText')[0])) {
      //detach(document.getElementsByClassName('contentText')[0]);
  }
  if (enteredVal.value !== '') {
      dialogTextElement.innerHTML = enteredVal.value;
  }
  enteredVal.value = '';
}

commandClick(args: CommandClickEventArgs): void {
  //alert(JSON.stringify(args.rowData));
  let trasy:any;
  
  this.dataToDialogBox  = this.dataToFilter.filter((obj) => { return obj.idZlecenia === (args.rowData as Order).idZlecenia;   });
 
  this.dataToDialogBoxPrice = this.dataToDialogBox[0].kwota;
  this.dataToDialogBoxTabor = this.dataToDialogBox[0].tabor;


  this.dataToDialogBoxWaga= this.dataToDialogBox[0].waga;
  this.dataToDialogBoxSpedytorT= this.dataToDialogBox[0].spedytorTel;
  this.dataToDialogBoxSpedytorM= this.dataToDialogBox[0].spedytorEmail;
  this.dataToDialogBoxSpedytorN= this.dataToDialogBox[0].spedytor;
  this.dataToDialogBoxTypO= this.dataToDialogBox[0].typOpakowania;
  this.dataToDialogBoxWaluta= this.dataToDialogBox[0].waluta;
  this.dataToDialogBoxKwotaK= this.dataToDialogBox[0].kwotaK;


  this.dataToDialogBoxGodzOdDo= this.dataToDialogBox[0].data_godz_od  + " " +  this.dataToDialogBox[0].data_godz_do;

if(this.dataToDialogBox[0].kwota == "0" )  
{
this.negocjuj = true;
this.akceptowalnacena = true;
}
else
{
  this.negocjuj = false;
  this.akceptowalnacena = false;
} 


this.ejInnerDialog.show();

//  this.template.show();
}


public dialogOpen: EmitType<object> = () => {
  (document.getElementById('sendButton') as any).keypress = (e: any) => {
      if (e.keyCode === 13) { this.updateTextValue(); }
  };
  (document.getElementById('inVal')as HTMLElement).onkeydown = (e: any) => {
      if (e.keyCode === 13) { this.updateTextValue(); }
  };
  document.getElementById('sendButton').onclick = (): void => {
      this.updateTextValue();
  };
}

clenFiltersGoogle() //przycisk
{
  if(this.dropDownListObject != null) 
  this.dropDownListObject.value=null;  
 

  this.disableRadius = false;
  this.radius = 3; 
  
   this.FilterGeolocation();
  this.SetOrderMarkers(); 


}



clenFiltersGeo() //Wyczysc filtry
{
      if(this.dropDownListObject != null) 
            this.dropDownListObject.value=null;

            this.disableRadius = false;

      this.wagaSelectedMin  = 0;
      this.wagaSelectedMax  = 31000;
   
      this.wagaSelectedTached =  false ;

      this.rangeValueGeo = [0, 31000];

 
      this.radius = 1; 


      if(this.taborFiltrGeo != null) 
        this.taborFiltrGeo.value  = null;

      if(this.dropDownListKrajWyladunkuGeo != null) 
              this.dropDownListKrajWyladunkuGeo.value = null;
     
      this.selectedKraWyladunkuFilterGeo = null;

      this.dataMasterToFilter = this.dataToFilter;       
   
      this.SetOrderMarkers(); 

      this.disableRadius = false;
}

clenFilters()
{

   

  this.dropDownListKrajZaladunku.value=null;
  this.dropDownListKrajWyladunku.value=null; 

  this.selectedKrajZaladunkuFilter=null;
  this.selectedKraWyladunkuFilter=null;

  this.dataMasterToFilter = this.dataToFilter;   

  this.rangeValue = [0, 31000];

  if(this.taborFiltrObj != null)  
      this.taborFiltrObj.value = null

  this.wagaSelectedMin  = 0;
  this.wagaSelectedMax  = 31000;
}

szukaj()
{
if(this.dropDownListKrajZaladunku.value==null &&  this.dropDownListKrajWyladunku.value==null)
  {
    this.FilterGeolocation(); 
    this.SetOrderMarkers(); 
  }
  else
  {
    this.onFilteringFilterKraj();
  }
}


clickEvent(){
 
}


//ToggleNavBar () {
//  let element: HTMLElement = document.getElementsByClassName( 'navbar-toggler' )[ 0 ] as HTMLElement;
//  if ( element.getAttribute( 'aria-expanded' ) == 'true' ) {
//      element.click();
//  }
//}


confirmOrderAndSend= async function(event: any): Promise<void> {
  let succes:Boolean = false;
  const user: User = JSON.parse(localStorage.getItem('user'))
  //this.placeOrder.user = this.jsonObject;
  this.placeOrder.userId = user.userId;
  this.placeOrder.zlecenieId = this.dataToDialogBox[0].idZlecenia;
  this.placeOrder.shoes = this.username + this.placeOrder.shoes;

  this.placeOrder.shoes = this.skillForm.value['shoes'];
  this.placeOrder.first = this.skillForm.value['first'];
  this.placeOrder.last = this.skillForm.value['last'];
  this.placeOrder.truck = this.skillForm.value['truck'];
  this.placeOrder.trailer = this.skillForm.value['trailer'];
  this.placeOrder.mobilePrefix = this.skillForm.value['mobilePrefix'];
  this.placeOrder.skonto = this.sKontoDialog;
  
  
  
  const userAnswer = await this.orderService.addOrder(this.placeOrder).then(async (response: Boolean) => {  
    if(response )
    {  
        this.toastr.success("Przyjęto zgłoszenie, dziękujemy");

        await this.orderService.sendmailConfirmNewOrder(this.placeOrder).then((response: Boolean) => {   
          if(response )
          {  
              this.toastr.success("Wysłano e-mail z potwierdzeniem");

              succes = true;

              this.router.navigateByUrl('/owntransport');
              // this.router.navigateByUrl('/owntransport');
          }
        }, succes=>{   },
          error=>{
            succes = false;
          console.log(error);
           this.toastr.error(error.error);
          }
        );
    }
  }, succes=>{  succes = true;  },
    error=>{
    console.log(error);
     this.toastr.error(error.error);
    }
  );

   
 
  
  this.DialogObj.hide();
  this.ejInnerDialog.hide()
  this.ejDialog.hide();
  //this.template.hide();

 // if(succes)
    
}


  async confirmOrder () {
  
  this.ejInnerDialog.show(); 
  
  }
 
  //public onOpenDialogConfirmOrder = function(event: any): void {
  //  this.DialogObj = DialogUtility.confirm({
  //  title: 'Confirmation Dialog',
  //  content: "Zlecenie przyjęte z obowiązkiem realizacji!",
  //  okButton: {  text: 'OK', click: this.okClick.bind(this),      },
  //  cancelButton: {   cssClass: 'e-custom' ,  text: 'Anuluj', click: this.cancelClick.bind(this)},
  //  showCloseIcon: true,
  //  closeOnEscape: true,
  //  animationSettings: { effect: 'Zoom' },
  //   
  //});
  //}


  SaveOrder(){
 

    this.confirmOrderAndSend(null);

    this.ejInnerDialog.hide()
    this.ejDialog.hide();
    this.DialogConfirmOrder.hide();


    this.router.navigateByUrl('/owntransport');

  }


  CancelOrder(){ 
    this.ejInnerDialog.hide()
    this.ejDialog.hide();
    this.DialogConfirmOrder.hide();
  }

public onOpenDialogConfirmOrder = function(event: any): void {
  
   if(this.negocjuj)
   {
    this.DialogConfirmOrderNegocjuj.show();
   }
   else
   {
      this.DialogConfirmOrder.show();
   }
}


public onOpenDialogOrderNotAvailable = function(): void {
//  this.DialogObjOrderNotAvailable = DialogUtility.confirm({
//  title: 'Confirmation Dialog',
//  content: "Zlecenie już jest nieaktywne!",
//  okButton: {  text: 'OK'       },
//   showCloseIcon: false,
//  closeOnEscape: true,
//  animationSettings: { effect: 'Zoom' }
// 
//});

 this.DialogNotAvailableOrder.show();
}


private okClick(): void {
 // alert('you clicked OK button');

  this.confirmOrderAndSend(null);
}

private cancelClick(): void {
  //Hide the dialog
  this.DialogObj.hide();
}

  
    // Initialize the Dialog component target element.
    public initilaizeTarget: EmitType<object> = () => {
     // this.targetElement = this.container.nativeElement.parentElement;
     //this.targetElement2 = this.container2.nativeElement.parentElement;
    }
    // Dialog animation
    public dialogAnimation: Object={effect: 'None'};
     // Disable the Esc key option to hide the Dialog
    public closeOnEscape: boolean =false;
    // Dialog header template content
    public header: string='<div title="Installation Complete" class="e-icon-settings e-icons" style="padding: 3px;">   Installation Complete</div>';
    // Dialog footer template content
    public footerTemplate9: string='<span style="float: left;font-size: 14px;padding-left: 15px;color: rgba(0, 0, 0, 0.54);">Click the close button to Exit</span>';
    // Sample level code to handle the button click action
    
    
    
    onOpenDialog2(args: any, idZlecenia:any)
    { 
      this.onOpenDialog(args, args.rowData['idZlecenia']); 
    }
    
    
    
    public onOpenDialog = async function(args: any, idZlecenia:any): Promise<void> {

      if(await this.orderService.isUserBan())
      {
        this.toastr.error("Użytkownik nie jest zaakceptowany");
        this.accountService.logout();
        this.router.navigateByUrl('/'); 
        return;
      }

 
      await this.orderService.getOrdernotallowed(idZlecenia).then(async(response: Boolean) => {  
        if(response )
        {  
            this.onOpenDialogOrderNotAvailable();
            this.data = await this.orderService.getOrders();
            this.dataMasterToFilter =  this.data;
            return;
             
        }
        else{

          this.placeOrder.price=0;
          this.placeOrder.username="";
          
          if(idZlecenia == false)
          {
            this.dataToDialogBox  = this.dataToFilter.filter((obj) => { return obj.idZlecenia === (args.rowData as Order).idZlecenia;   });
          }
          else
          {
            this.dataToDialogBox  = this.dataToFilter.filter((obj) => { return obj.idZlecenia === idZlecenia;   });
          }
         
    
          for(let key in this.dataToDialogBox)
          {
    
              let aaa : Order = this.dataToDialogBox[key];
    
              aaa.grupa3 =  aaa.kraj + ", " + aaa.kodpocztowy  + " " + aaa.miasto; 
              
          }
     
          this.dataToDialogBoxPrice = this.dataToDialogBox[0].kwota;
          this.dataToDialogBoxTabor = this.dataToDialogBox[0].tabor;
          this.dataToDialogBoxWaga= this.dataToDialogBox[0].waga;
          this.dataToDialogBoxSpedytorT= this.dataToDialogBox[0].spedytorTel;
          this.dataToDialogBoxSpedytorM= this.dataToDialogBox[0].spedytorEmail;
          this.dataToDialogBoxSpedytorN= this.dataToDialogBox[0].spedytor;
          this.dataToDialogBoxTypO= this.dataToDialogBox[0].typOpakowania;
          this.dataToDialogBoxWaluta= this.dataToDialogBox[0].waluta;
          this.dataToDialogBoxKwotaK= this.dataToDialogBox[0].kwotaK;
          
        if(this.dataToDialogBox[0].kwota == "0" )  
        {
        this.negocjuj = true;
        this.akceptowalnacena = true;
        this.minimalnakwota = true;
        }
        else
        {
          this.negocjuj = false;
          this.minimalnakwota = false;
          this.akceptowalnacena =false ;
        } 
    
          this.ejDialog.show();


        }
      }, succes=>{    },
        error=>{
        console.log(error);
         this.toastr.error(error.error);
        }
      ); 
    }
    public onOpenInnerDialog = function(event: any): void {
        this.ejInnerDialog.show();
    }

    public onOpenInnerDialog2()   {
      this.ejInnerDialog.show();
  }


  CheckIsMinimlnaKwota() {

if(this.placeOrder.price > 0)
{
  this.minimalnakwota =  false;
}
else{
  this.minimalnakwota = true;
}
    

 //   this.akceptowalnacena = true;
//
 //  if(this.placeOrder.price  < this.dataToDialogBoxKwotaK * 0.9 )
 //   {
 //     this.akceptowalnacena = false;
 //    
 //     
 //   }
 //   else
 //   {
//
 //     this.akceptowalnacena = true;
 //     
 //   }
    }

    public onFirstGridCreated(): void {
      //this.grid.hideSpinner = () => true;
     // setSpinner({ template: '<div style="width:100%;height:100%" class="custom-rolling"><div></div></div>' });
  }


  public select (e: SelectEventArgs) {
    if (e.isSwiped) {
      e.cancel = true;
    }
  }


  public selecting(args: SelectingEventArgs) {
    //this.getInteractionDetail(args.isInteracted);

   
  }

  public selected(args: SelectEventArgs) {
    if(args.selectedItem.innerText == 'WYSZUKAJ WG KRAJU' )
    {
   
      
      this.rangeValue =  [ this.wagaSelectedMin,  this.wagaSelectedMax];
      this.taborFiltrObj = this.memoryTabor;
      this.taborFiltrObj.value = this.Taborvalue;
      this.dataMasterToFilter = this.data;
      this.seletetTabIndex = 0;
    }
    else if(args.selectedItem.innerText == 'WYSZUKAJ WG LOKALIZACJI' )
    {

      


      this.memoryTabor = this.taborFiltrObj;
      

      let uemail : Order[] | undefined;
 
      if(this.dataMasterToFilterGeoTmp == uemail)
      {
        this.dataMasterToFilterGeoTmp = this.dataToFilter;
      }

      if(this.dataMasterToFilterGeoTmp.length == 0)
      {
        this.dataMasterToFilterGeoTmp = this.dataToFilter;
      }
      
      //this.temporaryData = this.data;
      this.dataMasterToFilter = this.dataMasterToFilterGeoTmp;
      this.seletetTabIndex = 1;
       this.getMyLocalization().then(ref=> {
        this.SetOrderMarkers(false);
       });
      ;
    }
  }


  public getInteractionDetail(interact: boolean): void {
    let eventlog = interact
      ? 'Tab Item selected by user interaction'
      : 'Tab Item selected by programmatically';
    document.getElementById('EventLog').innerHTML = document.getElementById(
      'EventLog'
    ).innerHTML = '<b>' + eventlog + '</b></span>';
  }
 


CloseDialogNotAvailableOrder()
{
  this.DialogNotAvailableOrder.hide();
}


changeOrderSkonto(args) {
  this.sKontoDialog = args.checked; 
} 

}

function geolocFail() {
  throw new Error('Function not implemented.');
}




 
