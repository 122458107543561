 

 
 <div class="container"  > 
      <app-nav style="z-index: 111!important;"></app-nav> 
       <router-outlet></router-outlet>
 </div>   
 <app-loader></app-loader>

 

 

