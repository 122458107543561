import { Component, ElementRef,Inject,Injectable,OnInit,ViewChild, ViewContainerRef } from '@angular/core';
import { EmitType, isNullOrUndefined , Internationalization} from '@syncfusion/ej2-base';
import { DialogService } from '../dialog'; 
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { placeOrder } from 'src/app/_models/placeOrders';
import { StatusDialogComponent } from '../../status-dialog/status-dialog.component';
import { Column, EditSettingsModel, GridComponent, IEditCell, QueryCellInfoEventArgs, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
  

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css'],
  
})

@Injectable({
  providedIn: 'root'
})

export class ConfirmationDialogComponent implements OnInit {
 
  column: any;


   @ViewChild('ejDialog') public ejDialog: DialogComponent | any;
  // The Dialog shows within the target element.
  @ViewChild('container', { read: ElementRef, static: true }) container: ElementRef | any;
  // The Dialog shows within the target element.
  public targetElement?: HTMLElement;

 recordDoubleClick(e:MouseEvent) {
            let cell: HTMLTableCellElement = <HTMLTableCellElement>(e.target); 
            this.column = this.grid.columns[cell.cellIndex]; 

            if(this.column.field == "zlecenieId")
              return;
 
            let row : HTMLTableRowElement = <HTMLTableRowElement>cell.parentElement;
            let rowIndex : number = row.rowIndex;
            let data : {iD_ZLECENIA_ZA_WY?: number}= this.grid.getCurrentViewRecords()[rowIndex];
            this.setStatus(null, data as placeOrder, 0);
    }
 

  @ViewChild("dialogAlert") dialogTarget: ElementRef;
  public valueAccessData = (field: string, data: placeOrder, column: Object) => {
    //debugger
    let intl: Internationalization = new Internationalization();
    return intl.getDateFormat({ type: 'dateTime', format: 'dd-MM-yyyy'})(new Date(data.data_)); 
  }

  public valueAccessDatadatA_RZ_P = (field: string, data: placeOrder, column: Object) => {
    let intl: Internationalization = new Internationalization();  
 
    if(new Date(data.datA_RZ_P)  > new Date('2000-01-01'))           
    return intl.getDateFormat({ type: 'dateTime', format: 'dd-MM-yyyy HH:mm'})(new Date(data.datA_RZ_P)); 
  }

  


  public valueAccessdata_Godz = (field: string, data: placeOrder, column: Object) => {
    if(field=="data_Godz")
    {
    let intl: Internationalization = new Internationalization();  
    //return (  data.data_Godz_Od + " " + data.data_Godz + " " + data.data_Godz_Do).replace("--", " ").replace("-", " ").replace("-", " ");
    return  intl.getDateFormat({ type: 'dateTime', format: 'dd-MM-yyyy'})(new Date(data.data_))  + " " + (data.data_Godz + " " + data.data_Godz_Do).replace("--", " ").replace("-", " ").replace("-", " ");
    }
      
}
 

  public valueAccessqtyDate = (field: string, data: placeOrder, column: Object) => {
      if(field=="qtyDate")
        switch(data.qtyDate){
            case 1:  
              return '33%';  
            case 2:  
              return '66%';
            case 3:  
              return '100%';
      default: { 
            return '0%'; 
    } 
  }

  }

  public valueAccessDatadatA_RZ = (field: string, data: placeOrder, column: Object) => {
    let intl: Internationalization = new Internationalization('pl');        
    if(new Date(data.datA_RZ)  > new Date('2000-01-01'))           
    return intl.getDateFormat({ type: 'dateTime', format: 'dd-MM-yyyy HH:mm'})(new Date(data.datA_RZ)); 
  }


  public valueAccessDatadatA_RZ_K = (field: string, data: placeOrder, column: Object) => {
    let intl: Internationalization = new Internationalization();        
    if(new Date(data.datA_RZ_K)  > new Date('2000-01-01'))           
    return intl.getDateFormat({ type: 'dateTime', format: 'dd-MM-yyyy HH:mm'})(new Date(data.datA_RZ_K)); 
  }

 
  
 
  constructor(private dialogService: DialogService, @Inject(MAT_DIALOG_DATA) public _data: placeOrder[],  private _dialog: MatDialog, private _ViewContainerRef: ViewContainerRef) { 
    this.data = _data;
 
  }
  public editSettings?: EditSettingsModel;
  public toolbar?: ToolbarItems[];
  public data : placeOrder[];
  public dpParams?: IEditCell;
  public formatoptions?: Object;
  public formatOptions?: object[]; 
  @ViewChild('grid') public grid: GridComponent; 
  public customAttributes?: Object;
  ngOnInit(): void {
    this.customAttributes = {class: 'customcss'};
    this.editSettings = { allowEditing: true, mode: 'Batch' };
    this.toolbar = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
    this.dpParams = { params: {value: new Date() } };
    (this as any).formatoptions = { type: 'dateTime', format: 'd-M-y hh:mm a' };


    this.initilaizeTarget();
  }

  public initilaizeTarget: EmitType<object> = () => {
    this.targetElement = this.container.nativeElement.parentElement;
  }


  public onOverlayClick: EmitType<object> = () => {
      this.ejDialog.hide();
  }

  closeDialog()
  {
    this.dialogService.hideDialog();
  }


  async setStatus(e: any, order:placeOrder, innerOrderId: any){

    var ref1 = this._dialog.open(StatusDialogComponent,   {

      data: order,
      height: '470px',
      width: '310px',
    }).afterClosed()
    .subscribe(response => {

      var ob = this.data.find(x => x.iD_ZLECENIA_ZA_WY == response['data'].iD_ZLECENIA_ZA_WY);
     

      (this.data as placeOrder[]).forEach((sdata, index) => { 
        if (sdata['iD_ZLECENIA_ZA_WY'] ==  response['data'].iD_ZLECENIA_ZA_WY) {
       
          sdata['datA_RZ'] = response['data'].datA_RZ;
          sdata['datA_RZ_K'] = response['data'].datA_RZ_K;
          sdata['datA_RZ_P'] = response['data'].datA_RZ_P;

          var x =  response['data'].datA_RZ > new Date('2000-01-01') ? 1 : 0;
          var y =  response['data'].datA_RZ_K > new Date('2000-01-01') ? 1 : 0;
          var z =  response['data'].datA_RZ_P > new Date('2000-01-01') ? 1 : 0; 
         var t2 = response['qtyDate']   ;
         sdata['qtyDate'] = x + y + z;
      
        //  sdata[index].style
         //(sdata['idZlecenia'] as any).classList.add('below-30'); 
        //  args.cell.classList.add('below-80'); 
          
       }
       else{
         //(sdata['idZlecenia'] as any).style.backgroundColor ="blue" ; 
       }
      });
 

       this.grid.dataSource = null;
       this.grid.dataSource = this.data;


       var ob = this.data.find(x => x.iD_ZLECENIA_ZA_WY == response['data'].iD_ZLECENIA_ZA_WY);
     var t = ob.datA_RZ_P;
      console.log(response);
    });;



    //this.fileNameDialogRef = this.dialog.open(DialogDemoComponent);
    console.log(this._ViewContainerRef.element.nativeElement);
    //let matDialogContainerRef = this.ref1.element.nativeElement;
    //ref1._containerInstance.

    //var t = await ref1.afterClosed().toPromise();
    //var t2 = t;
  }

 
  queryCellInfo(args: any): void {

    var today = new Date('2000-01-01');
      

    if (args.column.field == 'datA_RZ_P')
    {
      if (new Date(args.data.datA_RZ_P)  <  today) {

        var y = 4;
      
      } else {
        args.cell.value = new Date(args.data.datA_RZ_P);
        args.cell.style.color = 'black';
        args.cell.style.backgroundColor = 'green';
      }
    }  // || args.column.field == 'datA_RZ' || args.column.field == 'datA_RZ_K') {

      
if(args.column.field == 'datA_RZ')    
{
  if (new Date(args.data.datA_RZ)  <  today) {
    
  } else {
    args.cell.value = new Date(args.data.datA_RZ);
    args.cell.style.color = 'black';
    args.cell.style.backgroundColor = 'green';
  }
}
      
if(args.column.field == 'datA_RZ_K')    
{
  if (new Date(args.data.datA_RZ_K)  <  today) {
    //based on condition we have set the font color to the cell
     
  } else {
    args.cell.value = new Date(args.data.datA_RZ_K);
    args.cell.style.color = 'black';
    args.cell.style.backgroundColor = 'green';
  }
}

      
    } 


    public queryCellInfoEvent: EmitType<QueryCellInfoEventArgs> = (args: QueryCellInfoEventArgs) => {
  
      const data: placeOrder = args.data as placeOrder;
    
      const selectedrowindex: number[] = this.grid.getSelectedRowIndexes();  // Get the selected row indexes.

      var countZlecenia =   this.data.filter(
        it => it['zlecenieId'] === data.zlecenieId
      ).length;
 
    
            if (args.column.field === 'zlecenieId' ) { 
              args.rowSpan =  countZlecenia;  
               
              //args.cell.classList.add('above-80'); 
              }   
      }


      Start() {
        this.ejDialog.show();
      }
 }
   
    
 