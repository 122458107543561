import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs"; 
import { catchError, map } from "rxjs/operators";
import { ConfigService } from "./configService";

@Injectable()
export class GooglePlacesService   {
  //private apiUrl = 'https://maps.googleapis.com/maps/api/place/textsearch/json';
  private apiUrl = 'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/textsearch/json';
  baseUrlGoogleApi: string;

 
  
  constructor(private http: HttpClient, private conf:ConfigService) {  
    this.baseUrlGoogleApi= conf.getApi("baseUrl") + 'GoogleApi/';
   }


    degreesToRadians(degrees){
    return degrees * Math.PI / 180;
}

 
  getDistanceBetweenPoints(lat1: number, lng1: number, lat2: number, lng2: number){
    // The radius of the planet earth in meters
    let R = 6378137;
    let dLat = this.degreesToRadians(lat2 - lat1);
    let dLong = this.degreesToRadians(lng2 - lng1);
    let a = Math.sin(dLat / 2)
            *
            Math.sin(dLat / 2) 
            +
            Math.cos(this.degreesToRadians(lat1)) 
            * 
            Math.cos(this.degreesToRadians(lat1)) 
            *
            Math.sin(dLong / 2) 
            * 
            Math.sin(dLong / 2);

    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let distance = R * c;

    return distance/1000;
}
 
  async search(query: string): Promise<Observable<any>> { 
    const data = await this.http.get<any>(this.baseUrlGoogleApi +   query).toPromise();
  //console.log("Data: " + JSON.stringify(data)); 
  return data;

  }
    handleError(handleError: any): Observable<any> {
        throw new Error('Method not implemented.');
    }
    extractData(extractData: any) {
        throw new Error('Method not implemented.');
    }
}