<!DOCTYPE html>
<html>
<head>
    <style>
        body {
            font-size: 18px;
          text-align: justify;
          line-height: 120%; 
          padding-left: 20px;
          padding-right: 20px;
		
		  
        }
 
         li {
margin-bottom: 9px;
}
    </style>
</head>
<body><br><br>
  <p  style=" margin-top: 100px;" >
 
    MASZOŃSKI LOGISTIC SP. Z O.O. SP. K. <br>
    ul. Lipowa 27 <br>
    69-200 Sulęcin<br>
 
  </p> <br><br><br>
  <div style="text-align: center;">
  <h2> OŚWIADCZENIE ADMINISTRATORA </h2>
  
<h3>  w sprawie zbierania i przetwarzania danych osobowych
 
 
</h3>
</div>
    <p style="text-indent: 5%; ">Działając w imieniu Maszoński Logistic sp. z o. o. sp. k. z siedzibą w Sulęcinie, stosownie do przepisów art. 13 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), niniejszym informuję Panią/Pana jako naszego kontrahenta, że: 
<ol>
  <li >Administratorem Pani/Pana danych osobowych jest Maszoński Logistic sp. z o. o. sp. k. z siedzibą przy ul. Lipowej 27, w Sulęcinie (kod pocztowy: 69-200), e-mail: rodo@maszonski.pl
  </li>
   <li >Wyznaczyliśmy inspektora ochrony danych- Roberta Świergla. Jest to osoba, z którą możesz się kontaktować we wszystkich sprawach dotyczących przetwarzania danych osobowych oraz korzystania z praw związanych z przetwarzaniem danych. Z inspektorem możesz się kontaktować w następujący sposób:
<ul>
  <li style="margin-bottom: 0px; ">listownie na adres: ul. Lipowa 27, 69-200 Sulęcin,</li>
  <li>przez e-mail: rodo@maszonski.pl.</li>
     </ul>
  </li>
  <li>Pani/Pana dane osobowe są zbierane i przetwarzane zgodnie z obowiązującymi przepisami prawa, na potrzeby realizacji umowy zawartej między Panią/Panem a nami, w tym na potrzeby przesyłania Pani/Panu na adres poczty elektronicznej informacji marketingowych (na co wyraża Pan/Pani zgodę).
  </li>
  <li style="margin-bottom: 0px; ">Podstawą prawną przetwarzania Pani/Pana danych osobowych jest:
  </li>
  <ol type="a">
    <li style="margin-bottom: 0px; ">Umowa, której stroną jest Pani/Pan i przetwarzanie potrzebne do prawidłowej realizacji umowy oraz wykonanie obowiązków nałożonych na nas zgodnie obowiązującymi przepisami prawa, w zakresie następujących danych: imię, nazwisko, numer NIP, numer dowodu osobistego, adres korespondencyjny, służbowe dane kontaktowe, imię i nazwisko pracowników wykonujących usługi w Pani/Pana imieniu.
</li>
    <li style="margin-bottom: 0px; ">Pani/Pana zgoda na przetwarzanie danych, jeżeli dobrowolnie przekaże Pani/Pan inne dane niż wskazane w punkcie 4a. Przetwarzanie będzie odbywać się zgodnie z celem określonym w udzielonej przez Panią/Pana zgodzie.</li>
  <li>Nasz uzasadniony interes – w zakresie:
  <ol type="i">
    <li style="margin-bottom: 0px; ">Pani/Pana wizerunku w zakresie dozwolonego przepisami prawa monitoringu w siedzibie Administratora w celu zapewnienia bezpieczeństwa osób i ochrony mienia;</li>
    <li style="margin-bottom: 0px; ">Pani/Pana numeru rachunku bankowego – w celu dokonania zapłaty należności na Pani/Pana rzecz.</li>
  </ol>
</li>
  </ol>
  <li>Przysługuje Pani/Panu prawo dostępu do treści danych oraz ich sprostowania, usunięcia lub ograniczenia przetwarzania, a także prawo sprzeciwu, zażądania zaprzestania przetwarzania i przenoszenia danych, jak również prawo do cofnięcia zgody, w przypadku gdy podstawą przetwarzania danych jest Pani/Pana zgoda, w dowolnym momencie oraz prawo do przenoszenia Pani/Pana danych osobowych, tj. prawo otrzymania od nas Pani/Pana danych osobowych, w ustrukturyzowanym, powszechnie używanym formacie informatycznym nadającym się do odczytu maszynowego. Może Pani/Pana przesłać te dane innemu administratorowi danych lub zażądać, abyśmy przesłali Pani/Pana dane do innego administratora. Jednakże zrobimy to tylko jeśli takie przesłanie jest technicznie możliwe. Prawo do przenoszenia danych osobowych przysługuje Pani/Pana tylko co do tych danych, które przetwarzamy na podstawie umowy z Panią/Panem lub na podstawie Pani/Pana zgody.
</li>
  <li>Przysługuje Pani/Panu prawo do wniesienia skargi do organu nadzorczego Prezesa Urzędu Ochrony Danych Osobowych. 
</li>
  <li>W zakresie, w jakim Pani/Pana dane są przetwarzane na podstawie zgody– ma Pani/Pan prawo wycofania zgody na przetwarzanie danych w dowolnym momencie. Wycofanie zgody nie ma wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie Pani/Pana zgody przed jej wycofaniem. Zgodę może Pani/Pan wycofać poprzez wysłanie oświadczenia o wycofaniu zgody na nasz adres korespondencyjny bądź nasz adres e-mail.
</li>
  <li>Podanie danych, o których mowa w punkcie 4a jest niezbędne do realizacji umowy, której stroną Pani/Pan jest.
</li>
  <li>Dane udostępnione przez Panią/Pana, będą podlegały udostępnieniu podmiotom obsługującym nasze systemy informatyczne; podmiotom świadczącym nam usługi doradcze, pomoc prawną, podatkową, rachunkową oraz usługi ochroniarskie (w przypadku osobistego stawiennictwa w siedzibie Administratora), w związku z potrzebą prowadzenia dokumentacji, rozliczania świadczeń, wykonania umowy. W związku z tym  Pani/Pana dane będą mogły podlegać udostępnieniu  w szczególności następującym podmiotom trzecim: Robertowi Świergiel, Radosławowi Lemieszek, Joannie Kaczorowskiej, Łukaszowi Zgłobickiemu prowadzącemu Kancelarię Radcy Prawnego. Ponadto, Pani/Pana imię i nazwisko lub służbowe dane kontaktowe mogą być udostępniane w służbowej korespondencji, w tym poprzez pocztę e-mail. Pani/Pana dane mogą być także przekazywane klientom i kontrahentom Administratora, w zakresie związanym z normalną komunikacją biznesową. Dane mogą być również przekazane poczcie lub firmom kurierskim, w celu dostarczania przesyłek, a także bankom, w przypadku wypłaty należności na Pani/Pana konto bankowe. Poza ww. podmiotami odbiorcami danych będą tylko instytucje upoważnione z mocy prawa.
</li>
  <li>Administrator danych nie ma zamiaru przekazywać danych osobowych do państwa trzeciego lub organizacji międzynarodowej.
</li>
  <li>Dane udostępnione przez Panią/Pana nie będą podlegały profilowaniu.
</li>
  <li>Dane osobowe będą przechowywane przez czas niezbędny do wykonania umowy, a po tym czasie przez okres odpowiadający okresowi przedawnienia roszczeń- 3 lata od momentu realizacji umowy albo 10 lat od wydania prawomocnego orzeczenia sądu w sprawie tych roszczeń.
</li>
  </ol>
    
</body>
</html>