import { Directive } from '@angular/core';

@Directive({
  selector: '[appPasswordPattern]'
})
export class PasswordPatternDirective {

  constructor() { }

}
