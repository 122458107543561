

<p>Uzupełnij godziny!</p>

 <div>
  <div [formGroup]="formGroup">
<mat-form-field style="margin-top: 20px; z-index: 100;" >
  <input matInput [ngxMatDatetimePicker]="picker_RZ_P1"  formControlName="datA_RZ_P1"
                  placeholder="Przyjazd" 
                
                  [min]="minDate" [max]="maxDate" 
                  [disabled]="disabled" (dateChange)="updatePrzyjazd($event)">
  <mat-datepicker-toggle matSuffix [for]="picker_RZ_P1">
  </mat-datepicker-toggle>
  <ngx-mat-datetime-picker #picker_RZ_P1
   
    [showSeconds]="showSeconds"
    [stepHour]="stepHour" [stepMinute]="stepMinute" 
    [stepSecond]="stepSecond"
     >
    <ng-template>
      <!-- <mat-icon>star</mat-icon> -->
      <span>OK</span>
    </ng-template>
  </ngx-mat-datetime-picker>
</mat-form-field>
 
 <br />
<mat-form-field style="margin-top: 20px; z-index: 100;" >
  <input matInput [ngxMatDatetimePicker]="picker_RZ1"  formControlName="datA_RZ1"
                  placeholder="Początek" 
               
                  [min]="minDate" [max]="maxDate" 
                  [disabled]="disabled" (dateChange)="updatePoczatek($event)">
  <mat-datepicker-toggle matSuffix [for]="picker_RZ1" >
  </mat-datepicker-toggle>
  <ngx-mat-datetime-picker #picker_RZ1 
   
    [showSeconds]="showSeconds"
    [stepHour]="stepHour" [stepMinute]="stepMinute" 
    [stepSecond]="stepSecond"
     >
    <ng-template>
      <!-- <mat-icon>star</mat-icon> -->
      <span>OK</span>
    </ng-template>
  </ngx-mat-datetime-picker>
</mat-form-field>


<br />


<mat-form-field style="margin-top: 20px; z-index: 100;" >
  <input matInput [ngxMatDatetimePicker]="picker_RZ_K1"  formControlName="datA_RZ_K1"
                  placeholder="Koniec" 
                 
                  [min]="minDate" [max]="maxDate" 
                  [disabled]="disabled" (dateChange)="updateKoniec($event)">
  <mat-datepicker-toggle matSuffix [for]="picker_RZ_K1">
  </mat-datepicker-toggle>
  <ngx-mat-datetime-picker #picker_RZ_K1
   
    [showSeconds]="showSeconds"
    [stepHour]="stepHour" [stepMinute]="stepMinute" 
    [stepSecond]="stepSecond"
     >
    <ng-template>
      <!-- <mat-icon>star</mat-icon> -->
      <span>OK</span>
    </ng-template>
  </ngx-mat-datetime-picker>
</mat-form-field>
</div>
 

<div style=" text-align: right;">
  <button style=" text-align: right;" type="submit" (click)="close($event)" mat-dialog-close   mat-button>Zamknij</button>
</div>

 

</div>
       

 
 
 