import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SelectionSettingsModel } from '@syncfusion/ej2-angular-dropdowns';
import {  GridComponent,   FilterService, FilterSettingsModel, PageSettingsModel, ToolbarService, ToolbarItems, EditService, EditSettingsModel, RowDataBoundEventArgs, DetailRowService, SortSettingsModel, SaveEventArgs } from '@syncfusion/ej2-angular-grids';
import { ToastrService } from 'ngx-toastr';
import { company } from '../_models/company';
import { AccountService } from '../_services/account.service';
import { UserRegister } from '../_models/userRegister'
import { CompanyService } from '../_services/company.service';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { from, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { EmitType, isNullOrUndefined , Internationalization} from '@syncfusion/ej2-base';
import { L10n } from '@syncfusion/ej2-base';

 

 

@Component({
  selector: 'app-carriers',
  templateUrl: './carriers.component.html',
  styleUrls: ['./carriers.component.css'],
  providers: [FilterService, EditService, ToolbarService,  DetailRowService]
})
export class CarriersComponent implements OnInit {



  public data: object[];
  @ViewChild('Grid') public Grid: GridComponent;
  public childGrid: object;

  public dataManager: object[];

  public sortSettings: SortSettingsModel;
  public pageSettings: PageSettingsModel;
 
  public users: UserRegister[];
  public companys: company[];
  companys$:Observable<company[]>
  users$:Observable<UserRegister[]>

  public selectionOptions: SelectionSettingsModel;
public editSettings: EditSettingsModel;
public pageOptions: PageSettingsModel;
public toolbar: ToolbarItems[] | object;
public filterOptions: FilterSettingsModel;
public company: company;
public format = {type:'date', format:'dd/MM/yyyy'}



  orderData: company;
  orderForm: any;
  companyMaszon: company;

  constructor(private companyService: CompanyService,  private accountService: AccountService ,private toastr: ToastrService
    ,private elem: ElementRef, private router: Router
    ) { }

  async ngOnInit(): Promise<void> {

    L10n.load({
      'en-US': {
          grid: {
              'Search': 'Szukaj' ,
              'currentPageInfo': '{0} z {1} stron',
              'totalItemsInfo': '({0} stron)'
          }
      }
    });

    


    this.company = {
      companyId:0,
      userId:0,
      kodPocztowy: "",
      nip: "", 
      regon: "",
      statusNip: "",
      nazwa: "",
      wojewodztwo: "",
      powiat: "",
      gmina: "",
      miejscowosc: "", 
      ulica: "",
      nrNieruchomosci: "",
      nrLokalu: "",
      typ: "F",
      silosID: 1,
      dataZakonczeniaDzialalnosci : "2019-01-06T17:16:40",
      miejscowoscPoczty: "", 
      ciagnikSiodlowy:  0,
      ciagnikTrojosiowy:  0,
      ciagnikTypuMega:  0,
      ciagnikTypuMegaReagul:  0,
      mega3m:0,    
      idKontrah:0,
      accepted: false, 
      datarejstracji :   new Date(), 
      users:  [
        {userId: 1, 
          userName: "" ,
          PasswordHash:"",
        PasswordSalt:"" , 
         uzytkownikId: 0,
         usernameDto:null,
        mobile: "",
        mobilePrefix: "",
        email: "",
        idKontrah:0,
        accepted:false,
        datarejstracji: new Date() ,
        isAdmin: false,
        userCompanyId: null,
        UserComapnyCompany: {UserId: 2, nip: "",  nazwa: "",   miejscowosc: "",   ulica: "",  nrNieruchomosci: "",},
        orders: [],
        password: "",
        emailConfirmedToken: "",
        emailConfirmed:false,
        userComapnyCompanyId:0,
        companyId:0,
        isUserAdmin:false 
      },
        
      ], 
      errors: [],
      emailConfirmed:false
    };
    
    this.companys$  =   await this.companyService.getProducts();  

   

    this.users$ = await  this.accountService.GetUsersAsObservable();
    
    
    this.childGrid = {
      dataSource: await  this.accountService.GetUsers(),
       //Field name in parent grid        
      queryString: 'companyId',
        //Field name in child grid
      //foreignKeyField: "userComapnyCompanyId",
      allowPaging: true,
      width: 650,
      columns: [
        { field: 'companyId', headerText: 'Id Przewoźnika', textAlign: 'Left', width: 130 },
           { field: 'userName', headerText: 'Login/użytkownik', textAlign: 'Left', width: 170 }, 
          { field: 'mobile', headerText: 'Telefon', width: 150 },
          { field: 'email', headerText: 'E-mail' }
      ]
  };

   this.editSettings = { allowEditing: true, allowAdding: false, allowDeleting: true, mode: 'Dialog' };
   this.toolbar = [  'Search' ]
   this.selectionOptions = {  showCheckbox: true, showSelectAll: true};
 
   this.pageSettings = {  pageSize: 20 };
   this.filterOptions = { type: 'Excel' };
 
  }

  public valueAccess = (field: string, data: company, column: Object) => {
    //debugger
    let intl: Internationalization = new Internationalization();
    return intl.getDateFormat({ type: 'dateTime', format: 'dd-MM-yyyy'})(new Date(data.datarejstracji)); 
  }


    actionComplete(args) { 

      switch(args.requestType) {
        case 'save': 

        this.company.companyId = this.orderData?.companyId;  
        this.company.userId = this.orderData?.userId;  
        this.company.nip = this.orderData?.nip;  
       this.company.nazwa = this.orderData?.nazwa;  
       this.company.miejscowosc = this.orderData?.miejscowosc;  
       this.company.ulica = this.orderData?.ulica;  
       this.company.nrNieruchomosci = this.orderData?.nrNieruchomosci;  
       this.company.nrLokalu = this.orderData?.nrLokalu;   
       this.company.kodPocztowy = this.orderData?.kodPocztowy;   
        this.company.idKontrah = this.orderData?.idKontrah; 
        this.company.accepted = this.orderData?.accepted;  
        this.company.emailConfirmed = this.orderData?.emailConfirmed; 
        this.company.users[0].email = this.orderData.users[0].email; 
        this.company.users[0].mobilePrefix= this.orderData.users[0].mobilePrefix; 
        this.company.users[0].mobile = this.orderData.users[0].mobile; 
    
        this.companys$.forEach(obj => {
          obj.forEach(childObj=> {
             
            if(childObj.companyId == this.orderData?.companyId)
            {
           
            childObj.companyId   = this.orderData?.companyId;  
            childObj.userId= this.orderData?.userId;  
            childObj.nip = this.orderData?.nip;  
            childObj.nazwa = this.orderData?.nazwa;  
            childObj.miejscowosc = this.orderData?.miejscowosc;  
            childObj.ulica = this.orderData?.ulica;  
            childObj.nrNieruchomosci = this.orderData?.nrNieruchomosci;  
            childObj.nrLokalu = this.orderData?.nrLokalu;   
            childObj.idKontrah = this.orderData?.idKontrah; 
            childObj.kodPocztowy = this.orderData?.kodPocztowy; 
            childObj.accepted  = this.orderData?.accepted;  
            childObj.emailConfirmed = this.orderData?.emailConfirmed; 
            childObj.users[0].email  = this.orderData?.users[0].email 
            childObj.users[0].mobilePrefix  = this.orderData?.users[0].mobilePrefix  
            childObj.users[0].mobile  = this.orderData?.users[0].mobile 
            }
         });
      });

        
       
          
           this.companyService.update(this.company).subscribe(async response => {
             console.log(response); 
             this.toastr.success("Zaktualizowano");
             this.companys$  =   await this.companyService.getProducts();  
           }, error=>{
             console.log(error);
              this.toastr.error(error.error)
           });
        
       
          break;
        case 'y':
          // code block
          break;
        default:
          // code block
      }


      if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
        const dialog = args.dialog;
        const companyId = 'companyId';
        dialog.showCloseIcon = true;
     
        // change the header of the dialog
        dialog.header = args.requestType === 'beginEdit' ? 'Przewoźnik Id ' + args.rowData['companyId'] : 'Nowy przewoźnik';
        
    }


      if ((args.requestType === 'beginEdit' || args.requestType === 'add' || args.requestType === 'refresh')) {
        const dialog = args.dialog;
        const nip = 'nip';
        //dialog.showCloseIcon = false;
       
        // change the header of the dialog
       // dialog.header = args.requestType === 'beginEdit' ? 'Przewoźnik: ' + args.rowData['nip'] : 'Nowy Przewoźnik';

      } 

    }

    public rowDataBound(args: RowDataBoundEventArgs) {

      const Accepted = 'accepted';
      if (args.data[Accepted] == false) {
          args.row.classList.add('below-30'); 
  }

      const userId = 'userId';
      const filter: string = args.data[userId];
      const childrecord: any = new DataManager(this.Grid.childGrid.dataSource as JSON[]).
      executeLocal(new Query().where('userId', 'equal', parseInt(filter, 10), true));
      if (childrecord.length === 0) {
          // here hide which parent row has no child records
          args.row.querySelector('td').innerHTML = ' ';
          args.row.querySelector('td').className = 'e-customizedExpandcell';
      }
      else{

        if(args.row.innerHTML.includes("e-icons e-dtdiagonalright e-icon-grightarrow"))  
        {

          var ret = args.row.innerHTML.replace('href="#"','');
          args.row.innerHTML = ret;
  
        } 

      }

     


      let elements = this.elem.nativeElement.querySelectorAll('e-icons e-dtdiagonalright e-icon-grightarrow');
  }


  ngAfterViewInit(){
 
    let elements = this.elem.nativeElement.querySelectorAll('e-icons e-dtdiagonalright e-icon-grightarrow');
}

register() {
  //this.accountService.logout();
  this.router.navigateByUrl('/second-component');
 
}


  async actionBegin(args: SaveEventArgs): Promise<void> {


    if (args.requestType === 'delete' ) {

      await this.companyService.deleteCompany( {"companyId":args.data[0].companyId}).subscribe(response => {
        console.log(response); 
        this.toastr.success("Skasowano przewoźnika");
      }, error=>{
        console.log(error);
         this.toastr.error(error.error)
      });

    }
  if (args.requestType === 'beginEdit' || args.requestType === 'add') {


      this.orderData=null;
      this.orderData =   args.rowData as company 


      this.companyMaszon =null;


      if(this.orderData.idKontrah != 0 || this.orderData.nip != "")
      {
      this.companyMaszon = await this.companyService.GetMaszonCompany({"CompanyId":  this.orderData.companyId  ,"IdKontrah":this.orderData.idKontrah, "UserId" : this.orderData.userId, "Nip":this.orderData.nip });
      }
      else
      {
        this.companyMaszon = {
          companyId:0,
          userId:0,
          kodPocztowy: "",
          nip: "-", 
          regon: "-",
          statusNip: "",
          nazwa: "-",
          wojewodztwo: "",
          powiat: "",
          gmina: "",
          miejscowosc: "-", 
          ulica: "-",
          nrNieruchomosci: "-",
          nrLokalu: "-",
          typ: "F",
          silosID: 1,
          dataZakonczeniaDzialalnosci : "2019-01-06T17:16:40",
          miejscowoscPoczty: "", 
          ciagnikSiodlowy:  0,
          ciagnikTrojosiowy:  0,
          ciagnikTypuMega:  0,
          ciagnikTypuMegaReagul:  0,  
          mega3m:0,  
          idKontrah:0,
          accepted: false, 
          datarejstracji :   new Date(), 
          users:  [
            {userId: 1, 
              userName: "" ,
              PasswordHash:"",
            PasswordSalt:"" , 
             uzytkownikId: 0,
             usernameDto:null,
            mobile: "-",
            mobilePrefix: "",
            email: "-",
            idKontrah:0,
            accepted:false,
            datarejstracji: new Date() ,
            isAdmin: false,
            userCompanyId: null,
            UserComapnyCompany: {UserId: 2, nip: "",  nazwa: "",   miejscowosc: "",   ulica: "",  nrNieruchomosci: "",},
            orders: [],
            password: "",
            emailConfirmedToken: "",
            emailConfirmed:false,
            userComapnyCompanyId:0,
            companyId:0,
            isUserAdmin:false 
          },
            
          ], 
          errors: [],
          emailConfirmed:false
        };
      } 
 
     // this.company = await this.companyService.GetCompany({"UserId" : this.orderData.userId, "Nip":"2", "Nazwa":"2", "Ulica":"2", "Miejscowosc":"ss", "NrNieruchomosci":"2"});

  }
  if (args.requestType === 'save') {
      if (this.orderForm.valid) {
          args.data = this.orderData;
          await this.companyService.update(args.rowData as company );

          

      } else {
          args.cancel = true;
      }
  }
}

changeAccepted(args) {
  this.orderData.accepted = args.checked;
}

changeEmail(args) {
  this.orderData.emailConfirmed = args.checked;
}



//changeAdmin(args) {
//  this.orderData.isAdmin = args.checked;
//}


}