<h3 style="margin-top:130px; "> </h3>
<hr> 
<div class="control-section">
    <div class="col-lg-12">
       
        <h4 class="text-center text-primary">Dane firmy</h4><h5 style="text-align:center; "></h5>
             <div class="control_wrapper" id="control_wrapper" style="margin: 25px auto;">
          <form id="reactive" >

              <div class="form-group" style="padding-top: 11px;">
                  <div class="e-float-input"> 
                      <input type="text" id="nip" name="nip" [(ngModel)]="company.nip" minlength="6" required >

                      <span class="e-float-line"></span>
                      <label class="e-float-text e-label-top" for="nip">NIP</label>
                  </div> 
              </div>

              <div class="form-group" style="padding-top: 11px;">
                <div class="e-float-input">
                    <textarea  rows="2"    id="nazwa" name="nazwa"  [(ngModel)]="company.nazwa"  class="required" ></textarea> 
                    <span class="e-float-line"></span>
                    <label class="e-float-text e-label-top" for="nazwa">Nazwa</label>
                </div> 
             </div>
             <div class="form-group" style="padding-top: 11px;">
                <div class="e-float-input">
                    <input type="text" id="miejscowosc" name="miejscowosc"  [(ngModel)]="company.miejscowosc"  class="required" >
                    <span class="e-float-line"></span>
                    <label class="e-float-text e-label-top" for="miejscowosc">Miejscowość</label>
                </div> 
            </div>
             <div class="form-group" style="padding-top: 11px;">
                <div class="e-float-input">
                    <input type="text" id="kodPocztowy" name="kodPocztowy"  [(ngModel)]="company.kodPocztowy"  class="required" >
                    <span class="e-float-line"></span>
                    <label class="e-float-text e-label-top" for="name">Kod pocztowy</label>
                </div> 
            </div>
            <div class="form-group" style="padding-top: 11px;">
                <div class="e-float-input">
                    <input type="text" id="ulica" name="ulica"  [(ngModel)]="company.ulica"  class="required" >
                    <span class="e-float-line"></span>
                    <label class="e-float-text e-label-top" for="ulica">Ulica</label>
                </div> 
            </div>
            <div class="form-group" style="padding-top: 11px;">
                <div class="e-float-input">
                    <input type="text" id="nrNieruchomosci" name="nrNieruchomosci"  [(ngModel)]="company.nrNieruchomosci"  class="required" >
                    <span class="e-float-line"></span>
                    <label class="e-float-text e-label-top" for="nrNieruchomosci">Nr nieruchomosci</label>
                </div> 
            </div>
            <div class="form-group" style="padding-top: 11px;">
                <div class="e-float-input">
                    <input type="text" id="nrLokalu" name="nrLokalu"  [(ngModel)]="company.nrLokalu"  class="required" >
                    <span class="e-float-line"></span>
                    <label class="e-float-text e-label-top" for="nrLokalu">Nr lokalu</label>
                </div> 
            </div>

            <div class="form-group" style="padding-top: 11px;"> 
                <div class="row">
                    <div class="col-3">
                        <div class="e-float-input">
                            <input placeholder="+48" type="text" id="mobilePrefix" name="mobilePrefix"  [(ngModel)]="company.users[0].mobilePrefix"  class="required" >
                             <span class="e-float-line"></span>
                            <label class="e-float-text e-label-top" for="mobile">Prefix</label>
                        </div>  
                    </div> 
                    <div class="col-9">
                        <div class="e-float-input">
                            <input type="text" id="mobile" name="mobile"  [(ngModel)]="company.users[0].mobile"  class="required" >
                             <span class="e-float-line"></span>
                            <label class="e-float-text e-label-top" for="mobile">Telefon</label>
                        </div>  
                    </div> 
                </div> 
            </div>


            <div class="form-group" style="padding-top: 11px;">
                <div class="e-float-input">
                    <input type="text" id="email" name="email"  [(ngModel)]="company.users[0].email"  class="required" >
                    <span class="e-float-line"></span>
                    <label class="e-float-text e-label-top" for="email">E-mail</label>
                </div> 
            </div>
          
              <div class="form-group" style="padding-top: 11px;">
                <div class="submitBtn">
                 <!-- <button class="submit-btn e-btn" id="submit-btn"  (click)= "Submit()">Wyślij prośbę zmian</button>-->

                 <button  style="    border-color: #efa300   !important; background-color: #efa300 !important;     box-shadow: none !important;  margin-bottom: 10px;" class="btn btn-success mr-2"   (click)="deleteAccount()" type="submit">Usuń konto</button> 

                  <button  style="   margin-right: 20px; margin-bottom: 10px;" class="btn btn-success mr-2" (click)="aktualizujDaneFirmy()" type="submit">Wyślij zgłoszenie zmian</button> 
                

                  <div style="line-height: normal">
                    <font size="1" >*W celu uaktualnienia/usunięcia danych firmy prosimy o zgłosznie zmiany poprzez powyższy formularz. Dany formularz musi zawierać pole e-mail!</font>  
                </div>
                  
              
                </div>
              </div>


              
          </form>
       
      </div>
    </div>
</div>