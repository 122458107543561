import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PassWordRemaind } from 'src/app/_models/passWordRemaind';
import { AccountService } from 'src/app/_services/account.service';

@Component({
  selector: 'app-password-remainder',
  templateUrl: './password-remainder.component.html',
  styleUrls: ['./password-remainder.component.css']
})
export class PasswordRemainderComponent implements OnInit {


  public passWordRemaind:PassWordRemaind
  constructor(private accountService: AccountService, private toastr:ToastrService, private router: Router) { }

  ngOnInit(): void {

    this.passWordRemaind = {
      toEmail: "",
      body:"0",
      subject:"0",
    };
  }

  passwordRemaind()
  {

     this.accountService.remeberPassword(this.passWordRemaind).subscribe(response => {
      if(response)
      {
        this.toastr.success("Wysłano e-mail z linkiem do utworzenia nowego hasła.");
      }
      setTimeout(() => { this.router.navigate(['/checkemailboxcomponent']);  }, 3000);  //5s
     });;
 
  }


  

}

 
