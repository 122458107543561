import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { company } from '../_models/company';
import { forwarder } from '../_models/forwarder';
import { negotiation } from '../_models/negotiation';
import { ConfigService } from './configService';

@Injectable({
    providedIn: 'root'
  })

export class NegotiationService {
  baseUrl: any;

  constructor(private http: HttpClient, private conf:ConfigService) {  
    this.baseUrl= conf.getApi("baseUrl");
}
 

  async SaveNegotiation(model:negotiation){
    const data = await this.http.post<any>(this.baseUrl + 'negotiation/savenegotiation', model).toPromise();
    //console.log("Data: " + JSON.stringify(data)); 
    return data;
  }

  async GetNegotiations(orderId:Number){ 
    return await this.http.get<negotiation[]>(this.baseUrl + 'negotiation/getnegotiation/' + orderId).toPromise();  
  }
  
  async SendEmail(ec: any) {
    return await this.http.get<any>(this.baseUrl + 'email/acceptnegotatiation/'+ ec).toPromise(); 
  } 

}
