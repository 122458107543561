import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
 
import { Internationalization } from '@syncfusion/ej2-base';
import { forwarder } from 'src/app/_models/forwarder';
import { ForwarderService } from 'src/app/_services/forwarder.service';



let instance: Internationalization = new Internationalization();

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css'],
  encapsulation: ViewEncapsulation.None
})
 


export class ContactsComponent implements OnInit {
  public forwarders: forwarder[];

  public data: Object[];

  @ViewChild('grid')
  public grid: GridComponent;
  url: string;
  image: any;

  constructor(private forwarderService:  ForwarderService, private sanitizer: DomSanitizer) { }

  async ngOnInit(): Promise<void> { 
     

    this.forwarders = await this.forwarderService.GetForwarders(); 

    let objectURL = 'data:image/png;base64,' + this.forwarders[0].picture;
    this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);


    for(let key in this.forwarders) {

      let objectURL = 'data:image/png;base64,' + this.forwarders[key].picture;
       let image = this.sanitizer.bypassSecurityTrustUrl(objectURL);


      this.forwarders[key].pic =image;

    }
  }


  public format(value: Date): string {
    return instance.formatDate(value, { skeleton: 'yMd', type: 'date' });
  } 
}


export interface DateFormat extends Window {
  format?: Function;
}
 

 
