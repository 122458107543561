import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { company } from '../_models/company';
import { User } from '../_models/user';
import { ConfigService } from './configService';

@Injectable({
    providedIn: 'root'
  })

export class CompanyService {
  baseUrl: string;
 

   

    constructor(private http: HttpClient, private conf:ConfigService) {  
      this.baseUrl= conf.getApi("baseUrl");
     }

async GetCompany(model:any){
    const data = await this.http.post<company>(this.baseUrl + 'company/company', model).toPromise();
    //console.log("Data: " + JSON.stringify(data)); 
    return data;
  }


  async GetMaszonCompany(model:any){
    const data = await this.http.post<company>(this.baseUrl + 'company/maszonskicompany', model).toPromise();
    //console.log("Data: " + JSON.stringify(data)); 
    return data;
  }


  async GetDataCompany(model:any){
    const data = await this.http.post<company>(this.baseUrl + 'company/mojedane' , model).toPromise();
    //console.log("Data: " + JSON.stringify(data)); 
    return data;
  } 


  async GetCarriers(){ 
    const model: User = JSON.parse(localStorage.getItem('user'))
    const data = await this.http.post<company[]>(this.baseUrl + 'company/przewoznicy' , model).toPromise();
    //console.log("Data: " + JSON.stringify(data)); 
    return data;
  } 


  public getProducts(): Observable<company[]> {
    return this.http.get<company[]>(this.baseUrl + 'company');
}
 


  updateFlota(model:company)
  {
    return this.http.post(this.baseUrl + 'company/updateflota', model).pipe( )  ;
  }

  update(model:company){
    return this.http.post(this.baseUrl + 'company/updatecompany', model).pipe( )  
  }
  

  deleteCompany(model: any) {
    return this.http.post(this.baseUrl + 'company/deletecompany', model).pipe( ) 
  }
}
