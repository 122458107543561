import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommandColumnService, CommandModel, DetailRowService, EditService, EditSettingsModel, FilterService, FilterSettingsModel, GridComponent, GroupService, PageService, PageSettingsModel, parentsUntil, QueryCellInfoEventArgs, RowSelectEventArgs, SaveEventArgs, SelectionSettingsModel, SortService, TextWrapSettingsModel, ToolbarItems, ToolbarService, VirtualScrollService, ColumnChooserService, ColumnModel } from '@syncfusion/ej2-angular-grids';
import { ToastrService } from 'ngx-toastr'; 
import { placedOrder, placeOrder } from '../_models/placeOrders';
import { OrderService } from '../_services/orders.services'; 
import { EmitType, isNullOrUndefined , Internationalization} from '@syncfusion/ej2-base';
import { DialogComponent, Tooltip, TooltipComponent } from '@syncfusion/ej2-angular-popups'; 
import moment from 'moment';
import { ThemePalette } from '@angular/material/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { FileInfo, FilesPropModel, RemovingEventArgs, SelectedEventArgs, Uploader, UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { TabComponent, SelectEventArgs } from '@syncfusion/ej2-angular-navigations';
import { AccountService } from '../_services/account.service';
import { FilesService } from '../_services/_files.service'; 
import { DownLoadedFile } from '../_models/downLoadedFile';
import { ConfigService } from '../_services/configService';
import { L10n } from '@syncfusion/ej2-base';
import { Order } from '../_models/order';
import { createElement } from '@syncfusion/ej2-base'; 
import { DataManager } from '@syncfusion/ej2-data';
import { NegotiationService } from '../_services/negotiation.service';
import { negotiation } from '../_models/negotiation';
import { User } from '../_models/user';
declare var $: any;
L10n.load({
  'en-US': {
      'grid': {
          'SaveButton': 'Zapisz',
          'CancelButton': 'Anuluj',
          'Columnchooser': 'Kolumny',
          'Search': 'Szukaj',
          'TextFilter': 'Szukaj po tekście',
          'ClearFilter': 'Wyczyść',
          'SortAscending': 'Sortuj od A do Z',
          'SortDescending': 'Sortuj od Z do A',
          'SortAtoZ': 'Sortuj od A do Z', 
          'SortZtoA': 'Sortuj od Z do A', 
          'SortByOldest': 'Sortuj po najstarszych', 
          'SortByNewest': 'Sortuj po najnowszych', 
          'SortSmallestToLargest': 'Sortuj od najmniejszyhch', 
          'SortLargestToSmallest': 'Sortuj od największyh' ,
          'SelectAll': 'Zaznacz wszystkie',
          'Blanks': 'Puste',
       
      } ,
      'pager': {
        'currentPageInfo': '{0} z {1} stron',
        'totalItemsInfo': '({0} zleceń)',
        'pagerDropDown': 'zleceń na stronę',
        'firstPageTooltip': 'Idź do pierwszej strony',
        'lastPageTooltip': 'Idź do ostatniej strony',
        'nextPageTooltip': 'Idź do następnej strony',
        'previousPageTooltip': 'Idź do poprzedniej strony',
        'nextPagerTooltip': 'Pokaż następne elementy',
        'previousPagerTooltip': 'Pokaż poprzednie elementy',
        'NumberFilter': 'NumberFilter' ,
        'Items': 'zleceń'  
        
      }
  }
});

@Component({
  selector: 'app-moje-transporty',
  templateUrl: './moje-transporty.component.html',
  styleUrls: ['./moje-transporty.component.css'],
  providers: [FilterService, EditService, ToolbarService, SortService,
     CommandColumnService, VirtualScrollService, PageService, 
     GroupService  ,DetailRowService, ColumnChooserService
    ]
   
   
})
export class MojeTransportyComponent implements OnInit, AfterViewChecked {
  public tooltip: TooltipComponent;
 
 
  public sortOptions: object;
  public wrapSettings: TextWrapSettingsModel;
  public username: string = '+48';

  public headerText: Object = [{ text: 'Zlecenia otwarte' }, { text: 'Zlecenia zaakceptowane' } , { text: 'Zlecenia odrzucone' } , { text: 'Zlecenia archiwalne' }  ];

  public path: Object = {
    saveUrl: this.conf.getApi("baseUrl") + 'file',
    removeUrl: this.conf.getApi("baseUrl") + 'fileremove' };



    //@ViewChild('defaultupload') defaultupload: Uploader;
    @ViewChild('defaultupload2') defaultupload2: Uploader;
  

 @ViewChild('picker_RZ') picker_RZ: any;
 @ViewChild('picker_RZ_K') picker_RZ_K: any;
 @ViewChild('picker_RZ_P') picker_RZ_P: any;
 @ViewChild('picker_RZ_W') picker_RZ_W: any;


 @ViewChild('containerbuty', { read: ElementRef }) containerbuty: ElementRef;


  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';


  public formGroup = new FormGroup({
    datA_RZ: new FormControl(),
    datA_RZ_K: new FormControl( ),
    datA_RZ_P: new FormControl( ) 
   // datA_RZ_W: new FormControl( )
  })
  public datA_RZ =  new FormControl();
  public datA_RZ_K= new FormControl()
  public datA_RZ_P = new FormControl();
 // public datA_RZ_W = new FormControl()

  public dateControl = null;
  public dateControlMinMax = new FormControl(new Date());

 // public options = [
 //   { value: true, label: 'True' },
 //   { value: false, label: 'False' }
 // ];

  public listColors = ['primary', 'accent', 'warn'];

  public stepHours = [1, 2, 3, 4, 5];
  public stepMinutes = [1, 5, 10, 15, 20, 25];
  public stepSeconds = [1, 5, 10, 15, 20, 25];

  public acceptedDialog: boolean = true;
  public rejectedDialog: boolean = true;

  public toolbar: ToolbarItems[] | object;
  public editSettings: EditSettingsModel;
  public selectionOptions: SelectionSettingsModel; 
  public pageOptions: PageSettingsModel; 
  public filterOptions: FilterSettingsModel;
  public placedOrders: placedOrder[]; 
  public dataplaced: placeOrder[]; 
  public dataarchiwum: placeOrder[]; 
  public dataaccepted: placeOrder[]; 
  public datarejected: placeOrder[]; 

  //@ViewChild('grid') public grid: GridComponent; //jest
  @ViewChild('gridOpen') public gridOpen: GridComponent; //jest
  @ViewChild('gridArch') public gridArch: GridComponent; //jest
  @ViewChild('gridAccepted') public gridAccepted: GridComponent; //jest
  @ViewChild('gridRejected') public gridRejected: GridComponent; //jest

  public commands: CommandModel[];
  public customAttributes: Object;
  public customAttributesheader: Object;
  public filterOption: FilterSettingsModel = { type: 'Excel' };
  public pageSettings: PageSettingsModel;
  @ViewChild('statusyDialog') statusyDialog: DialogComponent;
  @ViewChild('fileDialog') fileDialog: DialogComponent;
  @ViewChild('butyDialog') butyDialog: DialogComponent;
  @ViewChild('negotiateDialog') negotiateDialog: DialogComponent;
  public negotiation:negotiation;
  public negotiations:any;
  public dialogAnimation: Object={effect: 'None'};

  public header: string='<div title="Installation Complete" class="e-icon-settings e-icons" style="padding: 3px;">   Installation Complete</div>';
   
   public footerTemplate: string='<span style="float: left;font-size: 14px;padding-left: 15px;color: rgba(0, 0, 0, 0.54);">Click the close button to Exit</span>';
 
   @ViewChild('container', { read: ElementRef }) container: ElementRef;
   public targetElement: HTMLElement;
   public targetElement2: HTMLElement;

  public currencyFormatter1 = (field: string, data1: object, column: object) => {
    const Kwota = 'oferowanaCena'; 
    return  (data1['waluta'] == 'EUR') ?  data1[Kwota] + ' €' : data1[Kwota] + ' ' +  data1['waluta'];
    
}

public currencyFormatter2 = (field: string, data1: object, column: object) => {
  const Kwota = 'cena';
  return  (data1['waluta'] == 'EUR') ?  data1[Kwota] + ' €' : data1[Kwota] + ' ' +  data1['waluta'];
}

public currencyFormatter3 = (field: string, data1: object, column: object) => {
  const Kwota = 'kupTeraz';
  return  (data1['waluta'] == 'EUR') ?  data1[Kwota] + ' €' : data1[Kwota] + ' ' +  data1['waluta'];
}

public currencyFormatter4 = (field: string, data1: object, column: object) => {
  const Kwota = 'oferowanaCena';
  return  data1[Kwota] + ' €';
}
public currencyFormatter5 = (field: string, data1: object, column: object) => {
  const Kwota = 'cena';
  return  data1[Kwota] + ' €';
}
public currencyFormatter6 = (field: string, data1: object, column: object) => {
  const Kwota = 'kupTeraz';
  return  data1[Kwota] + ' €';
}

  find_object: placeOrder;
  file: any;
  skasuj: any;
  filesData: FileInfo[];
  aktywnyNumerZleceniaZalacznik: any;
 
  public valueAccessNegotiation = (field: string, data: any, column: Object) => {
    //debugger
    let intl: Internationalization = new Internationalization();
    return intl.getDateFormat({ type: 'dateTime', format: 'dd-MM-yyyy HH:mm'})(new Date(data.dateAdd)); 
  }
  public valuedecisionNegotiation = (field: string, data: any, column: Object) => {

    if (data.decision == 0)
    return "Brak decyzji";

   if (data.decision == 5)
     return "Zaakceptowano";

     if (data.decision == 1)
     return "Odrzucone";
 
     if (data.decision == 2)
     return "Oczekiwanie na decyzję";
  }

  public valueAccess = (field: string, data: placeOrder, column: Object) => {
    //debugger
    let intl: Internationalization = new Internationalization();
    return intl.getDateFormat({ type: 'dateTime', format: 'dd-MM-yyyy HH:mm'})(new Date(data.offerdate)); 
  }
 

  public valueAccessData = (field: string, data: placeOrder, column: Object) => {
    //debugger
    let intl: Internationalization = new Internationalization();
    return intl.getDateFormat({ type: 'dateTime', format: 'dd-MM-yyyy'})(new Date(data.data_)); 
  }
  public shipColumns: ColumnModel[];

  public valueAccessDataWy = (field: string, data: placeOrder, column: Object) => {
    //debugger
    let intl: Internationalization = new Internationalization();
    return intl.getDateFormat({ type: 'dateTime', format: 'dd-MM-yyyy'})(new Date(data.data_WY)); 
  }
  negotiationsProposedPrice: any;

  constructor(private orderService: OrderService,  private toastr: ToastrService,
    public accountService: AccountService, 
    private  filesService: FilesService,  private conf:ConfigService,  private negService: NegotiationService ) { }

  async ngOnInit(): Promise<void> { 


    this.negotiation =  {negotiationId: 0, orderId: 0,carrierPrice: 0, proposedPrice: null, carrierUserId: 0, 
      negotiatorUserId: 0, dateAdd: new Date, decision: 0, emailSended: false, route:""  }   ;


    this.shipColumns = [
      {
        field: 'data_WY',
        headerText: 'Shipped Date',
        format: 'yMd',
        textAlign: 'Right',
        width: 150,
        minWidth: 10,
      },
      {
        field: 'data_Godz_WY',
        headerText: 'Ship Country',
        width: 150,
        minWidth: 10,
      },
      
      {
        field: 'data_Godz_Do',
        headerText: 'data_Godz_Do Country',
        width: 150,
        minWidth: 10,
      },
    ];

    this.sortOptions = { columns: [{ field: 'offerdate', direction: 'Descending' } ]};
    this.wrapSettings = { wrapMode: 'Content' };
    this.find_object= {
      przewoznik: "",
      komentarz:  "",
       orderId:0,
      price:0,
          comment: "",
          userId:0,
          username:"" ,
          zlecenieId: 0 ,
          first: "" ,
          last: "" , 
          shoes: "" ,
          mobilePrefix: "" ,
          truck: "" ,
          trailer: "" ,
          datA_RZ_P: new Date() ,
          datA_RZ: new Date() ,
          datA_RZ_K: new Date() ,
          datA_RZ_W: new Date() ,
          lokalizacja: "" ,
          spanRow: 0,
          status: "" ,
          zaakceptowane:false,
          rejected:false,
          cena: 0,
          waluta: "" ,
          offerdate: new Date() ,
         typ:"" ,
         iD_ZLECENIA_ZA_WY:0,
         qtyDate:0,
         spedytor:"" ,
         spedytorEmail:"" ,
         spedytorTel:"" ,
         kupTeraz:0,
         firma:"",
         oferowanaCena:0,
         isAttachment:false,
         skonto:false, expanded:[],  
          data_: new Date(),
         data_Godz: "",
         data_WY: new Date(),
         data_Godz_WY: "", 
         data_Godz_Od : "",
         data_Godz_Do:  "",
         userName: "", 
         userEmail: "",
         userKom: "",
         trasa:"", 
         isNegotiation :0,
          user: {userId: 1, 
                userName: "" ,
                PasswordHash:"",
                PasswordSalt:"" , 
                uzytkownikId: 0,
                usernameDto:null,
                mobile: "",
                mobilePrefix: "",
                email: "",
                idKontrah:0,
                accepted:false,
                datarejstracji: new Date() ,
                isAdmin: false,
                userCompanyId: null,
                UserComapnyCompany: {UserId: 2, nip: "",  nazwa: "",   miejscowosc: "",   ulica: "",  nrNieruchomosci: "",},
                orders: [],
                password: "",
                emailConfirmedToken: "",
                emailConfirmed:false,
                userComapnyCompanyId:0,
                companyId:0,
                isUserAdmin:false 
        } 
     }

   
  // var ttt = this.defaultupload;

    const now = new Date();
    this.date =   null;
    this.minDate = moment(now, "DD-MM-YYYY").add(-30, 'days');
  

    this.customAttributes = {class: 'customcss'};
    this.customAttributesheader = {class: 'customcssheader'};
    this.commands = [{ type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } }   ];
    this.pageSettings = { pageSizes: ['8', '16', '50', 'All'], pageSize:8};
    this.toolbar = [  'Search', 'ColumnChooser' ]
    //this.dataplaced = await  this.orderService.getplacedorders();


    this.dataplaced = await  this.orderService.getPlaced();
    this.dataaccepted = await  this.orderService.getPlacAcce();
    this.datarejected= await  this.orderService.getPlacedRej();
    this.dataarchiwum = await  this.orderService.getPlacedArch();    

     
    //this.placedOrders = await  this.orderService.getplacedorders();

    this.editSettings = { allowEditing: this.accountService.isAdmin, allowAdding: false, allowDeleting: false, mode: 'Dialog' };
    
   // this.selectionOptions = {  showCheckbox: true,      showSelectAll: true};
    this.pageOptions = { pageSize: 5 };
    this.filterOptions = { type: 'Excel' };
   // for (let i = 0; i < this.placedOrders.length; i++) {
   //   this.placedOrders[i]['List'] = i + 1;
   // }



 
   // (this.grid.toolbarModule as any).element.style.display = 'none';
    
   
  }

  async dodajzalaczniki(e: any, innerOrderId: any){

    this.aktywnyNumerZleceniaZalacznik = innerOrderId;

    this.defaultupload2.clearAll();
    let isPromisePending = false;
    this.filesData == null;// = this.defaultupload.getFilesData();

    let listaZalacznikowId: DownLoadedFile[];

    //this.defaultupload2.filesData = null;
    
    let filesData3 : FileInfo;
    listaZalacznikowId =  await this.filesService.GetFileList(innerOrderId);
    let filesData : FileInfo[]

    for(let i=0; i<listaZalacznikowId.length; i++){
       await this.GetListaZalacznikow(listaZalacznikowId[i].idZalaczniki).then((data => 
         {   
          this.defaultupload2.filesData.push(
            {
                "name": listaZalacznikowId[i].nazwaPliku ,
                "rawFile": data.rawFile,
                "size": 11091,
                "status": "File uploaded successfully",
                "type": listaZalacznikowId[i].typPliku,
                "validationMessages": {
                    "minSize": "",
                    "maxSize": ""
                },
                "statusCode": "2",
                "id": listaZalacznikowId[i].nazwaPliku
            });

         })).catch(() => {
        isPromisePending = false;
       //Error handler below
       });
      
      ;
     
    }

  this.defaultupload2.createFileList(this.defaultupload2.filesData);


  for(let y=0; y<listaZalacznikowId.length; y++){

        let liElements: any = document.body.querySelectorAll('.e-upload-file-list');
       for (let i = 0; i < liElements.length; i++) {
           if (liElements[i].getAttribute('data-file-name') == this.defaultupload2.filesData[y].name) {
               liElements[i].addEventListener('click', () => { this.openFile2(this.defaultupload2.filesData[y], e); });
               // File path have to update from server end in response status description.
               liElements[i].setAttribute('file-path', this.defaultupload2.filesData[y].id);
           }
      }
    }

    this.fileDialog.show(); 
  }

  
  async GetListaZalacznikow(arg0: Number): Promise<FileInfo> {
    
   

       return await this.filesService.downloadAll(arg0).then((blob: Blob) => {
        let f: FileInfo;
        if (blob instanceof Blob) {
          f = {
            "name": "jak to widzisz to jestes niezły.pdf",
            "rawFile": blob,
            "size": 11091,
            "status": "File uploaded successfully",
            "type": "pdf",
            "validationMessages": {
              "minSize": "",
              "maxSize": ""
            },
            "statusCode": "2",
            "id": "zestaw_sym_0.png",
            "fileSource": ""
          };

          return f;

        }
      }) ; 
     
  }

  

  async negotiate(order : any){ 

    const user: User = JSON.parse(localStorage.getItem('user'))


    
    this.negotiation.route = order.trasa;
    this.negotiation.orderId = order.orderId;
    this.negotiation.negotiatorUserId = user.userId;
    this.negotiation.carrierPrice = order.oferowanaCena;

   this.negotiations = await this.negService.GetNegotiations(order.orderId); 
   this.negotiationsProposedPrice = order.oferowanaCena; 
   this.negotiateDialog.show() 
  }
 
  SaveNegotiate = async function(event: any): Promise<void> { 
 
    await this.negService.SaveNegotiation(this.negotiation).then(async response => {
      console.log(response); 
      this.toastr.success("Wysłano e-mail do Przewoźnika");
     }, error=>{
      console.log(error);
       this.toastr.error(error.error)
    });

    this.negotiateDialog.hide();
    
    }


  pokazButa(tab:any, e: any, innerOrderId: any){
    if(tab == 1)
        this.find_object = this.dataplaced.find(x => x.orderId == innerOrderId);
    if(tab == 2)
      this.find_object = this.dataaccepted.find(x => x.orderId == innerOrderId);

    this.butyDialog.show() 
  }



  setStatusDateTime(tab:any,e: any, innerOrderId: any,  Order:  any){
 

  this.find_object.iD_ZLECENIA_ZA_WY = innerOrderId; 

 this.find_object.datA_RZ_P = new Date(Order.datA_RZ_P); 
 this.find_object.datA_RZ = new Date(Order.datA_RZ);
 this.find_object.datA_RZ_K = new Date(Order.datA_RZ_K);



  var d = new Date('1910-01-01T00:00:00') ;
     

this.datA_RZ_P =   (new Date(Order.datA_RZ_P) >  d) ? new FormControl(Order.datA_RZ_P)  :  new FormControl(); //Przyjazd
this.datA_RZ = (new Date(Order.datA_RZ) >  d) ? new FormControl(Order.datA_RZ)  :  new FormControl(); 
this.datA_RZ_K = (new Date(Order.datA_RZ_K) >  d) ? new FormControl(Order.datA_RZ_K)  :  new FormControl(); 
 
    this.formGroup.setValue({
      datA_RZ: this.datA_RZ,
      datA_RZ_K: this.datA_RZ_K,
      datA_RZ_P: this.datA_RZ_P 
     // datA_RZ_W: this.datA_RZ_W
    }) 

    


    this.statusyDialog.show();
    
}


  actionComplete(args) { 

    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      const dialog = args.dialog;
      const CustomerID = 'zlecenieId';
      dialog.showCloseIcon = true;
      dialog.height = 700;  
      // change the header of the dialog


      this.acceptedDialog = args.rowData['zaakceptowane'] ;
      this.rejectedDialog = args.rowData['rejected'] ;
      dialog.header = args.requestType === 'beginEdit' ? 'Zlecenie ' + args.rowData['zlecenieId'] : 'New Customer';
      
  }



    switch(args.requestType) {
      case 'save':
     
 
      
        break;
      case 'y':
        // code block
        break;
      default:
        // code block
    }


    if ((args.requestType === 'beginEdit' || args.requestType === 'add' || args.requestType === 'refresh' )) {
      const dialog = args.dialog;
      const nip = 'nip';
      //dialog.showCloseIcon = false;
     
      // change the header of the dialog
     // dialog.header = args.requestType === 'beginEdit' ? 'Przewoźnik: ' + args.rowData['nip'] : 'Nowy Przewoźnik';

    } 

  }


  public queryCellInfoEvent: EmitType<QueryCellInfoEventArgs> = (args: QueryCellInfoEventArgs) => {
  
    const data: placeOrder = args.data as placeOrder;
  
    const selectedrowindex: number[] = this.gridOpen.getSelectedRowIndexes();  // Get the selected row indexes.
  
          
    }

    rowSelected(args: RowSelectEventArgs) {
      const selectedrowindex: number[] = this.gridOpen.getSelectedRowIndexes();  // Get the selected row indexes.
     // alert(selectedrowindex); // To alert the selected row indexes.
      const selectedrecords: object[] = this.gridOpen.getSelectedRecords();  // Get the selected records.
     
       
    
    }

    public onOpenDialog = function(args: any): void {
    }






    toggleMinDate(evt: any) {
      if (evt.checked) {
        this._setMinDate();
      } else {
        this.minDate = null;
      }
    }
  
    toggleMaxDate(evt: any) {
      if (evt.checked) {
        this._setMaxDate();
      } else {
        this.maxDate = null;
      }
    }
  
    closePicker() {
      this.picker_RZ_W.cancel();
    }
  
    private _setMinDate() {
      
      const now = new Date();
      var new_date = moment(now, "DD-MM-YYYY").add(-1, 'days');

      
      this.minDate = moment(now, "DD-MM-YYYY").add(-1, 'days');
 
    }
  
  
    private _setMaxDate() {
      const now = new Date();
      this.maxDate =   moment(now, "DD-MM-YYYY").add(1, 'days');
      
    }
    async ngAfterViewInit(): Promise<void> {

      this.initilaizeTarget();
    } 
    initilaizeTarget: EmitType<object> = () => {
      //this.targetElement = this.container.nativeElement.parentElement;
      //this.targetElement2 = this.containerbuty.nativeElement.parentElement;
    }


    async DownloadPdfFile(event, innerOrderId: number)
    {

      this.skasuj =   this.filesService.downloadZlecenie(innerOrderId)
      .then(  (blob: Blob) => {  

        if(blob instanceof Blob )
        { 
          var file = new Blob([blob], {type: 'application/pdf'});
          var fileURL = URL.createObjectURL(file); 
          window.open(fileURL); 
       // a.download = a.download = 'Zlecenie_' + innerOrderId+'.pdf';
        }
        else{
          this.toastr.error("Nie znaleziono dokumentu zlecenia") 
        }
      } , error=>{
        console.log(error);
         this.toastr.error("Nie znaleziono dokumentu zlecenia")
      });
 
    }


    CloseZalaczniki(event)
    {

      this.fileDialog.hide();
    }

    async PostNewDateTime(event)
    {
 
      
      var d = new Date('1910-01-01T00:00:00') ;

var tt = this.picker_RZ_K.value;
      //const data: Date = this.picker_RZ_K._validSelected.d.i  as Date;

  //Przyjazd

  var date = new Date();
 
 
  if(this.formGroup.value.datA_RZ_P.value  != null) ////Przyjazd
  {
    var local_RZ_P = new Date(this.formGroup.value.datA_RZ_P.value);
    local_RZ_P.setMinutes(local_RZ_P.getMinutes() - local_RZ_P.getTimezoneOffset());
    this.find_object.datA_RZ_P =  local_RZ_P ;   
  }
  else
  this.find_object.datA_RZ_P = d;

  if(this.formGroup.value.datA_RZ.value!= null) //Początek
  { 
    var local_RZ = new Date(this.formGroup.value.datA_RZ.value);
    local_RZ.setMinutes(local_RZ.getMinutes() - local_RZ.getTimezoneOffset());
    this.find_object.datA_RZ =  local_RZ ; 
   } else
   this.find_object.datA_RZ = d;
  

  if(this.formGroup.value.datA_RZ_K.value != null) //Koniec
  {
    var local_RZ_K = new Date(this.formGroup.value.datA_RZ_K.value);
    local_RZ_K.setMinutes(local_RZ_K.getMinutes() - local_RZ_K.getTimezoneOffset());
    this.find_object.datA_RZ_K =  local_RZ_K ; 
  } else
  this.find_object.datA_RZ_K = d;
         
     
   

     // if(this.datA_RZ_W.value != null)
      //this.find_object.datA_RZ_W =  this.datA_RZ_W.value.format("YYYY-MM-DD HH:mm:ss");;
      //const data: Date = this.picker_RZ_K._validSelected.d.i  as Date;

   

      this.orderService.SetStatus_RZ_K(this.find_object).subscribe(async response => {
        console.log(response); 
        this.toastr.success("Zaktualizowano");
         this.dataplaced = await  this.orderService.getPlaced();
         this.dataaccepted = await  this.orderService.getPlacAcce();
      }, error=>{
        console.log(error);
         this.toastr.error(error.error)
      });


      
      this.statusyDialog.hide();
     
      
    }


    SaveNrButa= async function(event: any): Promise<void> {
  
    await this.orderService.updateshoes(this.find_object).subscribe(response => {
      console.log(response); 
      this.toastr.success("Zaktualizowano");
     }, error=>{
      console.log(error);
       this.toastr.error(error.error)
    });

    this.butyDialog.hide();
    
    }

     
      onFileSelected(args : SelectedEventArgs) : void {
      //debugger;
      // Filter the 3 files only to showcase
      args.filesData.splice(3);
      let filesData : FileInfo[] = this.defaultupload2.getFilesData();
      let allFiles : FileInfo[] = filesData.concat(args.filesData);
      if (allFiles.length > 5) {
          for (let i : number = 0; i < allFiles.length; i++) {
              if (allFiles.length > 3) {
                  allFiles.shift();
              }
          }
          args.filesData = allFiles;
          // set the modified custom data
          args.modifiedFilesData = args.filesData;
      }
      args.isModified = true;
    }

    public uploadEle: HTMLElement = createElement('span', { className: 'upload e-icons', innerHTML : 'Upload All' });
    public clearEle = createElement('span', { className: 'remove e-icons', innerHTML : 'Clear All' });
    public buttons: Object = {
     browse: 'Dodaj pliki',
     clear: this.clearEle,
     upload: this.uploadEle
    };

      public onUploadSuccess: EmitType<Object> = (args: any) => {
        let liElements: any = document.body.querySelectorAll('.e-upload-file-list');
        for (let i = 0; i < liElements.length; i++) {
            if (liElements[i].getAttribute('data-file-name') == args.file.name) {
                liElements[i].addEventListener('click', () => { this.openFile(args, event); });
                // File path have to update from server end in response status description.
                liElements[i].setAttribute('file-path', args.e.target.statusText);
            }
        }
   };
   openFile(args: any, e: any) {
    if (!e.target.classList.contains('e-file-delete-btn') && !e.target.classList.contains('e-file-remove-btn'))
    {
      const url = window.URL.createObjectURL(args.file.rawFile);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = args.file.name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      //alert('your file has downloaded!'); // or you know, something with better UX.
    } 
}
    

openFile2(args: any, e: any) {
  if (!e.target.classList.contains('e-file-delete-btn') && !e.target.classList.contains('e-file-remove-btn'))
  {
    const url = window.URL.createObjectURL(args.rawFile);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = args.name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    //alert('your file has downloaded!'); // or you know, something with better UX.
  } 
}


public onFileUpload: EmitType<SelectedEventArgs> = (args: any) =>  {
  // add addition data as key-value pair.
  args.customFormData = [{'name':  this.aktywnyNumerZleceniaZalacznik}];
};


public onFileSelect: EmitType<Object> = (args: any) => {
  args.customFormData = [{'name':  this.aktywnyNumerZleceniaZalacznik}];
 }
 
 public onFileRemove(args: RemovingEventArgs): void {
  args.customFormData = [{'name':  this.aktywnyNumerZleceniaZalacznik}];
}

public onremoving: EmitType<SelectedEventArgs> = (args: any) =>  {
  args.customFormData = [{'name':  this.aktywnyNumerZleceniaZalacznik}];
   };


   public select (e: SelectEventArgs) {
    if (e.isSwiped) {
      e.cancel = true;
    }
  }


async actionBegin(args: SaveEventArgs): Promise<void> {


  if (args.requestType === 'save') {
    
         
   let zlecenie : placedOrder = args.data as placedOrder;
   zlecenie.zaakceptowane =  this.acceptedDialog;
   zlecenie.rejected =  this.rejectedDialog;
 
 
        await this.orderService.updateorder(zlecenie).then(async response => {
        
          this.toastr.success("Zaktualizowano");
            this.dataplaced = await  this.orderService.getPlaced();
            this.dataaccepted = await  this.orderService.getPlacAcce();
         }, error=>{
          console.log(error);
           this.toastr.error(error.error)
        });
    
        this.butyDialog.hide();

       
}

}

changeOrderAccept(args) {
  this.acceptedDialog = args.checked; 
 
}


changeOrderReject(args) {
  this.rejectedDialog = args.checked; 
} 
 
clicked(e) {
  let tr = parentsUntil(e.target, 'e-row');
  let trIdx = parseInt(tr.getAttribute('aria-rowindex'));
  if (parentsUntil(e.target, 'e-row').children[0].classList.contains('e-detailrowexpand')) {
    this.gridOpen.detailRowModule.collapse(trIdx)
  } else {
    this.gridOpen.detailRowModule.expand(trIdx)
  }
}


ngAfterViewChecked() {
  $('[data-toggle="tooltip"]').tooltip();
}

 

  public rowDataBound(args:any){ 

     
    
    if(args.row.innerHTML.includes("e-icons e-dtdiagonalright e-icon-grightarrow"))  
    { 
      var ret = args.row.cells[0].innerHTML.replace('href="#"','');
      args.row.cells[0].innerHTML = ret; 
    }  
  }


  public rowDataBoundColor(args:any){ 
    const qtyDate = 'qtyDate';
    if (args.data[qtyDate] == 0.0) {
        args.row.style.background = '#ff000021';
    } 
    if(args.row.innerHTML.includes("e-icons e-dtdiagonalright e-icon-grightarrow"))  
    { 
      var ret = args.row.cells[0].innerHTML.replace('href="#"','');
      args.row.cells[0].innerHTML = ret; 
    }  
  }
 
 

}





 