import { formatDate } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Order } from "../_models/order";
import { expandedRow, placedOrder, placeOrder } from "../_models/placeOrders";
import { User } from "../_models/user";
import { ConfigService } from "./configService";

@Injectable({
    providedIn: 'root'
  })
  
export class OrderService { 
  baseUrl: string;
 
  constructor(private http: HttpClient, private conf:ConfigService) {  
    this.baseUrl= conf.getApi("baseUrl");
}
 
  

    async download3(){
      return await this.http.get<Order[]>(this.baseUrl + 'file/1054073').toPromise(); 
    }

    downloadFile(id: number, contentType: string)
  {
    return this.http.get(this.baseUrl + 'file/1054073', {responseType: 'blob'})
    .subscribe((result: Blob) => {
      const blob = new Blob([result], { type: contentType }); // you can change the type
      const url= window.URL.createObjectURL(blob);
    
      window.open(url);
      console.log("Success");
  });
  }

 
    async getOrders(){
        return await this.http.get<Order[]>(this.baseUrl + 'orders').toPromise(); 
      }


    

    
      

      async getCountriesZa(){
        return await this.http.get<string[]>(this.baseUrl + 'orders/countriesza').toPromise(); 
      }
      async getCountriesWy(){
        return await this.http.get<string[]>(this.baseUrl + 'orders/countrieswy').toPromise(); 
      }

     

      async getOrdernotallowed(zlecenieId:number){
        return await this.http.get<boolean>(this.baseUrl + 'orders/ordernotallowed/ ' + zlecenieId).toPromise(); 
      }

      async addOrder(model:placeOrder){
        return await this.http.post(this.baseUrl + 'orders/addorder', model).toPromise( )  
      }


      updateshoes(model:placeOrder){
        return this.http.post(this.baseUrl + 'orders/updateshoes', model).pipe( )  
      }


      async updateorder(model: Object) {
        return this.http.post(this.baseUrl + 'orders/updateorder', model).toPromise(); 
      }

      async getplacedorders(){ 
        let user = JSON.parse(localStorage.getItem('user')) as User
        return await this.http.get<placeOrder[]>(this.baseUrl + 'orders/placedorders/' + user.userId).toPromise(); 
      }

      async getArchives(){
        let user = JSON.parse(localStorage.getItem('user')) as User
        return await this.http.get<placeOrder[]>(this.baseUrl + 'orders/archivesorders/' + user.userId).toPromise(); 
         
      }

      async getRejected(){
        let user = JSON.parse(localStorage.getItem('user')) as User
        return await this.http.get<placeOrder[]>(this.baseUrl + 'orders/rejectedorders/' + user.userId).toPromise(); 
       
      }

      async getAccepted(){
        let user = JSON.parse(localStorage.getItem('user')) as User
        return await this.http.get<placeOrder[]>(this.baseUrl + 'orders/acceptedorders/' + user.userId).toPromise(); 
         
      }

      SetStatus_RZ_K(find_object: placeOrder) {
        return  this.http.post(this.baseUrl + 'orders/updatestatus', find_object).pipe(
          map((placedOrder: Boolean )=> {
            { 
              if(placedOrder)
              {
                return true;
              }
            }
            return false;
            })
          ) 
      }
    


      async sendmailConfirmNewOrder(placedOrder: placedOrder) {
        return  this.http.post(this.baseUrl + 'email/neworder', placedOrder).toPromise(); 
      }

     async getPlaced(){ 
      var t;
        await this.getplacedorders().then(async (ref: placeOrder[]) => {
          t =  await this.getplacedordersExpand(ref);   
        }).catch(err => console.error(err)); 
      return t;
      } 
   
      async getPlacedArch(){ 
        var t;
          await this.getArchives().then(async (ref: placeOrder[]) => {
            t =  await this.getplacedordersExpand(ref);   
          }).catch(err => console.error(err)); 
        return t;
        } 

        async getPlacedRej(){ 
          var t;
            await this.getRejected().then(async (ref: placeOrder[]) => {
              t =  await this.getplacedordersExpand(ref);   
            }).catch(err => console.error(err)); 
          return t;
          } 

          async getPlacAcce(){ 
            var t;
              await this.getAccepted().then(async (ref: placeOrder[]) => {
                t =  await this.getplacedordersExpand(ref);   
              }).catch(err => console.error(err)); 
            return t;
            } 


      public expandedRow :expandedRow[];

      async getplacedordersExpand(data:placeOrder[]){ 
  
          var dataGropuped = new Array<placeOrder>();
          var zlecenie = 0;
        const format = 'dd-MM-yyyy';
        const locale = 'en-US';

        
       data.forEach(element => {

        if(element.iD_ZLECENIA_ZA_WY == 5119840 ||  element.iD_ZLECENIA_ZA_WY  == 5119839)  
        {
          var t = 4;
        }  


        if(zlecenie != element.orderId) 
        { 
          let setOthers: placeOrder[] =  data.filter(x => x.zlecenieId === element.zlecenieId && x.orderId == element.orderId); 
          var i = 0
              setOthers.forEach(rowOrder => { 
                var row = new expandedRow();
                 row.datA_RZ = rowOrder.datA_RZ;
                 row.datA_RZ_K = rowOrder.datA_RZ_K;
                 row.datA_RZ_P = rowOrder.datA_RZ_P;
                 row.datA_RZ_W = rowOrder.datA_RZ_W;
                 row.lokalizacja = rowOrder.lokalizacja;
                 row.status = rowOrder.status; 
                 row.typ = rowOrder.typ; 
                 row.qtyDate = rowOrder.qtyDate; 
                 row.iD_ZLECENIA_ZA_WY = rowOrder.iD_ZLECENIA_ZA_WY; 
                 row.data_ =  formatDate(rowOrder.data_, format, locale) ; 
                 row.data_Godz = rowOrder.data_Godz; 
                 row.data_Godz_Od = rowOrder.data_Godz_Od; 
                 row.data_Godz_Do = rowOrder.data_Godz_Do; 
                    if(element.expanded == undefined)  
                    { 
                       element.expanded = new Array<expandedRow>();
                    }
                 element.expanded.push(row); 
               }); 
          
               const sum = setOthers
               .reduce((sum, current) => sum + current.qtyDate, 0);


               if(sum > 0)
               {
                var TT = sum;
               }
               
                element.qtyDate =   sum   / (setOthers[0].spanRow * 3);
               element.trasa = setOthers[0].lokalizacja + "<br />" + setOthers[setOthers.length-1].lokalizacja;
               
               
               let setOthersZA: placeOrder[] =  setOthers.filter(x => x.typ === "ZA"); 
               let setOthersWY: placeOrder[] =  setOthers.filter(x => x.typ === "WY"); 

               element.data_ = setOthersZA[0].data_
               element.data_WY = setOthersWY[setOthersWY.length-1].data_
               element.data_Godz = setOthersZA[0].data_Godz
               element.data_Godz_WY = setOthersWY[setOthersWY.length-1].data_Godz

               element.data_Godz_Od = setOthersZA[0].data_Godz_Od + "<br />" + setOthersZA[setOthersZA.length-1].data_Godz_Do;
               element.data_Godz_Do = setOthersWY[0].data_Godz_Od + "<br />" + setOthersWY[setOthersWY.length-1].data_Godz_Do;
               

           dataGropuped.push(element); 

          }
          zlecenie = element.orderId;
        }); 

        return dataGropuped;
      }
      async isUserBan(){
        let user = JSON.parse(localStorage.getItem('user')) as User
        return await this.http.get<boolean>(this.baseUrl + 'orders/userban/' + user.userId).toPromise();  
      }
    
       
}

 