import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/_services/configService';

@Component({
  selector: 'app-test-errors',
  templateUrl: './test-errors.component.html',
  styleUrls: ['./test-errors.component.css']
})
export class TestErrorsComponent implements OnInit {
 
  validationErrors: string[] = [];

  baseUrl: string;

  constructor(private http: HttpClient, private conf:ConfigService) {  
    this.baseUrl= conf.getApi("baseUrl") ;
   }

  ngOnInit(): void {


  }

  get404Error(){
    this.http.get(this.baseUrl + 'buggy/not-found').subscribe(response => { 
      console.log(response); 
    }, error => { 
    console.log(error);
    })
  }

  get400Error(){
    this.http.get(this.baseUrl + 'buggy/bad-request').subscribe(response => { 
      console.log(response); 
    }, error => { 
    console.log(error);
    this.validationErrors = error;
    })
  }

  get500Error(){
    this.http.get(this.baseUrl + 'buggy/server-error').subscribe(response => { 
      console.log(response); 
    }, error => { 
    console.log(error);
    })
  }

  
  get401Error(){
    this.http.get(this.baseUrl + 'bugg/auth').subscribe(response => { 
      console.log(response); 
    }, error => { 
    console.log(error);
    })
  }

  get400ValidationError(){
    this.http.post(this.baseUrl + 'account/register', {}).subscribe(response => { 
      console.log(response); 
    }, error => { 
    console.log(error);
    this.validationErrors = error;
    })
  }

}
