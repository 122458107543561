 
<div class="container" style="  border: 1px solid #e0e0e0;
padding: 10px;   margin-top:126px;" >

 
   <ejs-tab id="element" (selecting)="select($event)" (selecting)="selecting($event)" (selected)="selected($event)" > 
       <e-tabitems>
         <e-tabitem>
           <ng-template #headerText>
             <div> Wyszukaj wg kraju </div>
          </ng-template>
          <ng-template #content>



            
    
      <div class="row" style="margin-top:  20px;">
        <div class="col-3" style="width: 320px; " >
          <div class="input-group mb-4"  >
            <ejs-dropdownlist class="fontf"   style="width: 220px; " id='ddlkrajza' #ddlkrajza [dataSource]='dataFilterCountriesZa' 
            [placeholder]='placeholderza' (change)='onFilteringFilterKrajZa($event)' floatLabelType='Auto'>
           </ejs-dropdownlist>
            <div class="input-group-append"> 
           
              <button (click)="btnClearZa()" id='btnClearZa' #btnClearZa  class="btn"><i class="fa fa-close"></i></button>

            </div>
          </div>
          </div>
        <div class="col-3" style="width: 320px; ">
          <div class="input-group mb-4"   >
            <ejs-dropdownlist  class="fontf"  style="width: 220px; " id='ddlkrajwy' #ddlkrajwy [dataSource]='dataFilterCountriesWy' [placeholder]='placeholderwy' (change)='onFilteringFilterKrajWy($event)' floatLabelType='Auto'></ejs-dropdownlist>
            <div class="input-group-append"> 
               <button (click)="btnClearWy()" id='btnClearWy' #btnClearWy  class="btn"><i class="fa fa-close"></i></button>
            </div>
           </div>    
          </div>  
      </div>
    



       <!--  WAGA TABOR    -->

 

       </ng-template>
         </e-tabitem>
         <e-tabitem>
           <ng-template #headerText>
             <div> Wyszukaj wg lokalizacji </div>
          </ng-template>
          <ng-template #content>


            


          <div class="row"> 

              <div class="col-md-3" style="width: 315px; ">
                  <div class="row" style="float: none !important; text-align: center;">
                      <div class="col-md-12">
                        
                        <div class="input-group mb-3" style="width: 315px; ">
                          <ejs-dropdownlist class="fontf" style="width: 240px;  margin-top: 23px;" id='ddlelement' #ddlelement 
                          [dataSource]='datadropDownListObject' [fields]='fields2' [placeholder]='text'  
                          (change)='onChange($event)'[allowFiltering]='true' (filtering)='onFiltering($event)'></ejs-dropdownlist>
                                    <div class="input-group-append">
                                      <button (click)="clenFiltersGoogle()" style="  margin-top: 23px;" id='btnClearGoogle' #btnClearGoogle  class="btn"><i class="fa fa-close"></i></button>
                                    </div>
                        </div> 

                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-12"><div class="input-group mb-4"   style="width: 315px; ">
                        <ejs-dropdownlist class="fontf"  style="width: 240px;  " id='ddlkrajwyGeo' #ddlkrajwyGeo [dataSource]='dataFilterCountriesWy'
                         [placeholder]='placeholderwy' (change)='onFilteringFilterKrajWyGeo($event)' floatLabelType='Auto'></ejs-dropdownlist>
                        <div class="input-group-append"> 
                           <button (click)="btnClearWyGeoClean()" id='btnClearWyGeo' #btnClearWyGeo  class="btn"><i class="fa fa-close"></i></button>
                        </div>
                       </div> 
                      </div>
                  </div>
                  <div class="row" *ngIf="seletetTabIndex == 1">
                    <div class="col-md-12" style="width: 315px; ">
                    

                      
                        
                           <ejs-multiselect id='multiselectelement'  
                           #taborFiltrGeo  
                           
                           (removed)="onRemovedGeo($event)"  (removing)="onRemoveGeo($event)" 
                            [dataSource]='taborData'
                            (tagging)='onTaggingGeo($event)'
                            [mode]='box' 
                            [placeholder]='placeholder'
                            [fields]='fields'
                            style="width: 245px; margin-bottom: 32px; "
                            ></ejs-multiselect>

                    </div>
                </div>

                <div class="row" style="float: none !important; text-align: center;" *ngIf="seletetTabIndex == 1">
                  <div class="col-md-12" style="width: 315px; "> 
                    <ejs-slider  class="fontf" (change)="sliderChangeKgGeo($event)"  style="width: 240px;    " id='slider2Geo'  
                    [type]= 'type' [showButtons]=false [value] = 'rangeValueGeo' [ticks]="ticksWaga"  min='0' max='31000' ></ejs-slider> 
                  </div>
              </div>
              </div>
 

              <div class="col-md-3" *ngIf="disableRadius"> 
              
                <div class="row"  style="float: none !important; " >
                    <div  class="col-md-12" style="width: 315px; "> 
                        <h4  class="fontf" style="margin-top: 23px;  margin-bottom: 14px;">Promień wyszukiwania:</h4>
                    </div>
                </div>
                <div class="row" style="float: none !important; text-align: center;"  > 
                    <div class="col-md-12" style="width: 315px; "> 
                      <ejs-slider disa class="fontf"  style="width: 240px; margin-top: 23px; " value="300"  max="300" (change)="sliderRadiusChange($event)"
                       class="form-control" id='slider' [ticks]="ticks" 
                      [(ngModel)]="slidervalue" name="slider" required #slider="ngModel"></ejs-slider>
                    </div>
                </div>
                <div class="row"  >
                  <div class="col-md-12">
                    <div class="input-group mb-4"   > 
                       </div>
                </div>  
                <div class="row" style="float: none !important; text-align: center;  margin-top: 30px;">
                  <div class="col-md-12" style="width: 280px;">
                                    <button  (click)="clenFiltersGeo()" type="button" class="btn btn-success" style=" width: 160px;  font-family:effra,Arial,Helvetica,sans-serif!important;    ">Wyczyść filtry</button>
    
                  </div>
              </div> 
            </div>  


            </div> 
              <div [ngClass]="{'col-md-6' : disableRadius,'col-md-9' : !disableRadius }"  >   
                 <div id="wrapper" style="  text-align: center;  "> 
                <div id='content321' >  
                  <google-map [hidden]="hiddenRadius"
                    #myGoogleMap
                    height="300px"
                    width="100%"
                    [zoom]="zoom"
                    [center]="center"
                    [options]="options"  
                    >
                    <map-marker 
                      #markerElem="mapMarker"
                      *ngFor="let marker of markers"
                      [position]="marker.position"
                      [label]="marker.label"
                      [title]="marker.title"
                      [options]="marker.options" 
                      (mapClick)="openInfo(markerElem, marker.info)"
                       
                    >
                    </map-marker>
                    <map-circle    [center]="center" [radius]="radius"  
                  ></map-circle>
        
                  
                   </google-map> 
                </div> 
              </div>
            </div> 
          </div>
        



           


 


 

          </ng-template>
         </e-tabitem>
       </e-tabitems>
   </ejs-tab>


         <!--    TABOR    --> 
     <div class="container" *ngIf="seletetTabIndex == 0">
     <div class="row" style=" text-align: center;">
        <div class="col-3" style="width: 315px; ">
         

          <ejs-multiselect id='multiselectelement2'  
          #taborFiltr  
          (removed)="onRemoved($event)"  
           [dataSource]='taborData'
           (tagging)='onTagging($event)'
           [mode]='box' 
           [placeholder]='placeholder'
           [fields]='fields'
           style="width: 240px; margin-bottom: 32px; " 
           ></ejs-multiselect>



 

        </div>
        <div class="col-3" style="width: 315px;" >
          <ejs-slider (change)="sliderChangeKg($event)"  style="width: 230px; "  id='slider2'  
             [type]= 'type' [showButtons]=false [value] = 'rangeValue' [ticks]="ticksWaga"  min='0' max='31000' ></ejs-slider>
        </div>

        <div class="col-3"  >
         
               
          <button  (click)="clenFilters()" type="button" class="btn btn-success" style=" width: 160px;  font-family:effra,Arial,Helvetica,sans-serif!important;    ">Wyczyść filtry</button>
      
         
      </div>
      </div>
    </div>
       <!--  WAGA TABOR    -->
 
</div>  
<div style="margin-bottom:50px ;">
<ejs-grid [dataSource]='dataMasterToFilter'   
[allowPaging]="false"
[pageSettings]='pageSettings'
[allowGrouping]='false'
  [allowFiltering]='false' 
   [allowGrouping]='false'
   [allowSorting]='false'  [allowExcelExport]='true'  
   [filterSettings]='filterOption'  #grid (queryCellInfo)='queryCellInfoEvent($event)'
 
   (rowSelected)='rowSelected($event)'
   (rowDataBound)='rowBound($event)'
   allowTextWrap='true' 
   (commandClick)='onOpenDialog2($event, "3")' 
   (created)='onFirstGridCreated()'
   >
             <e-columns> 
                 <e-column  headerTextAlign='Center' [customAttributes]='customAttributesheader' field='idZlecenia' headerText='ZLECENIE' textAlign='Left' width=95></e-column>
              
               
                  <e-column  
                 field="kwota"
                 headerText="KWOTA"
                 textAlign='Center'
                 headerTextAlign='Center'
                 width='130'
                 [valueAccessor]='currencyFormatter'
               >
                 <ng-template #template let-data>
                   <div>
                    <button  style="white-space: normal;"  cssClass="e-link"   (click)="onOpenDialog($event, data.idZlecenia)" ejs-button  > 
                  <!--  <button  style="white-space: normal;"  cssClass="e-link"   (click)="onOpenDialogConfirmOrder()" ejs-button  >  -->

                      
                        <span  *ngIf="data.kwota != 0">
                       {{data.kwota + '€'}}    
                    </span>
                    <span  style="white-space: normal;"  *ngIf="data.kwota == 0">
                     Zaproponuj cenę
                  </span>
                   
                   </button>
                   </div>
                 </ng-template>
               </e-column>

                


                 <e-column field='typ' headerText='TYP' width=70></e-column>
                 <e-column field='kraj' headerText='KRAJ' textAlign='Left'   width=100></e-column>
                
                 <e-column field='kodpocztowy' headerText='KOD POCZTOWY' textAlign='Left' width=120></e-column>
                 <e-column field='miasto' headerText='MIASTO' headerTextAlign="Center" textAlign='Left' width=190></e-column>
                 <e-column [valueAccessor]='emptyDataFormatter' field='data' headerText='DATA' width='110'   textAlign='Center'></e-column>
                 <e-column  [valueAccessor]='emptygodzinaFormatter' field='godzina' headerText='GODZINA' textAlign='Center'   width=99></e-column> 
                 <e-column field='waga' headerText='WAGA' textAlign='Center'   width=110></e-column> 
                 <e-column field='tabor' headerText='TABOR' textAlign='Center'  width='220' ></e-column>
          <!--        <e-column field='details'  headerText='SZCZEGÓŁY'    width='100' [commands]='commands'  ></e-column> -->


 
             

          </e-columns>
 </ejs-grid>
</div>
<!-- 
 <div #container class='root-container'></div>

   <ejs-dialog isModal="true" style="position: absolute;" id='dialog' #template [visible]='false' showCloseIcon='true' (open)='dialogOpen()' [height]='height' [target]='targetElement' width='435px'
   allowDragging='true' enableResize='true' 
   >
   
   </ejs-dialog> -->


   

   <ejs-dialog isModal="true" showCloseIcon="true" [visible]="false" id='innerDialog33dd'
    #ejInnerDialog header='Dodaj niezbędne dane' height='520px' width='300px' showOnInit='true' showCloseIcon='true' content='This is a Nested Dialog' target='#dialog' [animationSettings]='dialogAnimation'  >
   

    <form [formGroup]="skillForm" novalidate id="formId" (ngSubmit)="onSubmit()">

     <ng-template #footerTemplate>
   
       <button [disabled]="!skillForm.valid" (click)="onOpenDialogConfirmOrder($event)" id="sendButton" class="btn btn-success" data-ripple="true" style="font-size: 14px;">Zatwierdź </button>
   </ng-template>
   <ng-template #content>
       <div class="dialogContent">

        <div class="form-group" style="padding-top:20px; padding-left: 20px;
        padding-right: 20px;">
          <div class="e-float-input">
            <input type="text" formControlName="first" name="first" minlength="2" style=" color: rgb(46, 44, 44); text-align: left; font-style: normal !important; font-weight: 400 !important; font-size: 14px !important; " />
            <span class="e-float-line e-label-top"></span>
 
            <label style="font-weight: 400 ; text-align: left; font-size: 15px;" class="e-float-text" 
            [ngClass]="{'e-label-top': first.touched , 'e-label-bottom':   !first.touched}" for="name">Imię kierowcy</label>                                                        

                        <div *ngIf="first.invalid && (first.dirty || first.touched)"  >
              <div class="e-error" *ngIf="first.errors.required">
                * Podaj imię kierowcy
              </div> 
            </div>
          </div>
        </div>


        <div class="form-group" style="padding-top:10px; padding-left: 20px;
        padding-right: 20px;">
          <div class="e-float-input">
            <input type="text" formControlName="last" name="last" minlength="2" style="  color: rgb(46, 44, 44); text-align: left; font-style: normal !important; font-weight: 400 !important; font-size: 14px !important; " />
            <span class="e-float-line e-label-top"></span>
 
            <label style="font-weight: 400 ; text-align: left; font-size: 15px;"  class="e-float-text" 
            [ngClass]="{'e-label-top': last.touched  , 'e-label-bottom':  !last.touched }" for="name">Nazwisko kierowcy</label>                                                        

                        <div *ngIf="last.invalid && (last.dirty || last.touched)"  >
              <div class="e-error" *ngIf="last.errors.required">
                * Podaj nazwisko kierowcy
              </div> 
            </div>
          </div>
        </div>


        
        <div class="form-group" style="padding-top:10px; padding-left: 20px;
        padding-right: 20px;">
          <div class="e-float-input">
            <input type="text" formControlName="truck" name="truck" minlength="2"  style=" color: rgb(46, 44, 44);  text-align: left; font-style: normal !important; font-weight: 400 !important; font-size: 14px !important; " />
            <span class="e-float-line e-label-top"></span>
 
            <label style="font-weight: 400 ; text-align: left; font-size: 15px;" class="e-float-text" 
            [ngClass]="{'e-label-top': truck.touched , 'e-label-bottom':  !truck.touched}" for="name">Nr rejestracyjny auta</label>                                                      
             <div *ngIf="truck.invalid && (truck.dirty || truck.touched)"  >
              <div class="e-error" *ngIf="truck.errors.required">
                * Podaj nr rejestracyjny auta
              </div> 
            </div>
          </div>
        </div>


   
        <div class="form-group" style="padding-top:10px; padding-left: 20px;
        padding-right: 20px;">
          <div class="e-float-input">
            
            <input type="text" formControlName="trailer" name="trailer" minlength="2" style=" color: rgb(46, 44, 44);  text-align: left; font-style: normal !important; font-weight: 400 !important; font-size: 14px !important; " />
            <span class="e-float-line e-label-top"></span>
 
            <label style="font-weight: 400 ; text-align: left; font-size: 15px;" class="e-float-text" 
            [ngClass]="{'e-label-top': trailer.touched  , 'e-label-bottom':  !trailer.touched }" for="name">Nr rejestracyjny naczepy</label>                                                      
             <div *ngIf="trailer.invalid && (trailer.dirty || trailer.touched)"  >
              <div class="e-error" *ngIf="trailer.errors.required">
                * Podaj nr rejestracyjny naczepy
              </div> 
            </div>
          </div>
        </div>



     
     
 
        <div class="form-group template" style="padding-left: 20px; padding-right: 20px;">
          <div   class="e-float-input"  >  
            <div class="row"> 
              <div class="col-4">
                 <input style="font-weight: 400 ; font-style: normal; width: 40px; font-size: 15px !important;"  type="text"   
                 formControlName="mobilePrefix" name="mobilePrefix" minlength="2">
                 </div>
                  <div class="col-8">
                   <input type="text" formControlName="shoes" name="shoes" minlength="7" required pattern="[- +()0-9]+" style=" color: rgb(46, 44, 44);  text-align: left; font-style: normal !important; font-weight: 400 !important; font-size: 14px !important; " />
 
                   <label style="font-weight: 400 !important ; padding-left: 15px;; text-align: left;
                   
                   font-size: 16px !important;" class="e-float-text" 
                   [ngClass]="{'e-label-top': shoes.touched  , 'e-label-bottom':  !shoes.touched }" for="name">Nr telefonu</label>                                                      
               


                   <div *ngIf="shoes.invalid && (shoes.dirty || shoes.touched)" class="e-error">
                    <div *ngIf="shoes.errors.required">
                      * Podaj numer telefonu
                    </div>
                    <div *ngIf="shoes.errors.minlength">
                      *Podaj porawny numer telefonu
                    </div>
                  </div> 
                    
                   </div>
                 </div> 
            </div> 
           </div> 

       </div>
   </ng-template>
   <ng-template #header>
     <span    style="display: inline-block;"></span> 
     <img  style="margin-left:5px;"  height="35" src="/assets/images/M_trace_na ciemne tlo.png?"  >
       <div style="font-size: 13px; padding-top: 3px;"   class="e-icon-settings dlg-template">Dane kierowcy</div>
   </ng-template>


  </form>
   </ejs-dialog>

 
     
   
   <ejs-dialog [visible]="false"   isModal="true" id='dialog' #ejDialog header='Outer Dialog' height='760px' width='400px' showOnInit='true' showCloseIcon='true'   [target]='targetElement' [animationSettings]='dialogAnimation' [closeOnEscape]='closeOnEscape'>
     
     <ng-template #contenst>
       <span class="e-icons e-settings"  style="display: inline-block;"></span> 
         <div  > Szczegóły zlecenia </div>
     </ng-template>
   

     <ng-template #footerTemplate>
       <!-- <input id="inVal" class="e-input" type="text" placeholder="Enter your message here!"/>-->
        <button  [disabled]="minimalnakwota"  (click)="confirmOrder()" id="sendButton" class="btn btn-success" data-ripple="true">Złóż zamówienie</button>
    </ng-template>

    <ng-template #content >
       

        <ejs-grid [dataSource]='dataToDialogBox' allowTextWrap='true' style="margin-top:20px;">
          <e-columns>
            <e-column  field='typ' width='55' headerText='TYP' textAlign='Center'  ></e-column> 

              <e-column  field='grupa3' width='160' headerText='LOKALIZACJA' textAlign='Left' headerTextAlign="Center" ></e-column> 
               <e-column  [valueAccessor]='emptygodzinaFormatter2' field='data_godz_od' headerText='GODZ       OD DO' width='75' textAlign='Center'></e-column>
              
          
          </e-columns>
          </ejs-grid>


          <div style="background-color: #ffffff; padding: 10px;">
            <div class="row" style="padding-top: 10px;">  
                <div class="col-4">
                  <div style="font-weight:bold; "  >Waga:</div>
                </div> 
                <div class="col-8">
                 {{dataToDialogBoxWaga}} 
                </div>  
           </div> 
           <div class="row" style="padding-top: 10px;"> 
                 <div class="col-4">
                   <div style="font-weight:bold; "   >Typ:</div>
                 </div> 
                 <div class="col-8">
                  {{dataToDialogBoxTypO}} 
                 </div>  
           </div> 
           <div class="row" style="padding-top: 10px; padding-bottom: 11px;"> 
                 <div class="col-4">
                   <div style="font-weight:bold; "  >Tabor:</div>
                 </div> 
                 <div class="col-8">
                  {{dataToDialogBoxTabor}} 
                 </div> 
           </div> 
       </div>



       

             <div id="headernegocjacje" style="margin-top: 25px; margin-left: 10px; "> 
                  
        
               
              
                  <div *ngIf="negocjuj" class="row" style="padding-top: 10px; padding-bottom: 11px;"> 
                    <div class="col-6">
                      <label for="colFormLabel" style="font-weight:bold;  color: rgb(0, 160, 227);" class="col-sm-16 col-form-label">Zaproponuj cenę:</label>
                    </div> 
                    <div class="col-4">
                      <input  (input)="CheckIsMinimlnaKwota()" class='input' min="1" type='number' id='this-input-name' placeholder='' 
                           
                           class="form-control" [(ngModel)]="placeOrder.price" #slider="ngModel"> 
                    </div> 
                    <div class="col-2" style="padding-top: 10px;">
                      {{dataToDialogBoxWaluta}} 
                     </div>
                  </div> 
                  
 
                         

                 
                 
                 <ul class="header-list" *ngIf="!negocjuj" > 
                   <label for="colFormLabel" style="font-weight:bold ;color: rgb(0, 160, 227);"  >Kwota:</label>
                   <li  style="font-weight:bold; margin-top: 9px; color: rgb(0, 160, 227);" class="right-header list tour">   {{dataToDialogBoxPrice}}  {{dataToDialogBoxWaluta}}   </li>

                 </ul> 
              </div>


              <div class="form-row" style="  padding-bottom: 16px; padding-left: 15px; width: 350px ;"   >
                <div class="e-float-input" >
                  <div class="form-row">
                    <div class="col">
                      <ejs-checkbox #wcheckbox label=" Wyrażam zgodę na udzielenie skonto 3,5% w przypadku zapłaty za fakturę w terminie 3 dni roboczych od czasu dostarczenia wszystkich wymaganych dokumentów." 
                       (change)="changeOrderSkonto($event)"  [checked]="acceptedDialog"></ejs-checkbox>  
                    </div> 
                  </div> 
                </div> 
              </div>


              <div class="header-list" style="padding-left: 10px; padding-right: 10px;">
               <label   style="font-weight:bold; margin-left: 10px;"  >Zostaw komentarz:</label>
               <textarea style='resize: none;' class='form-control' rows='2'   [(ngModel)]="placeOrder.comment"></textarea>
           </div>

              <div style="background-color: #ffffff; padding: 7px; margin-top: 15px;;">
               <div class="row" style="padding-top: 7px;">  
                   <div class="col-4">
                     <div style="font-weight:bold; "  >Opiekun:</div>
                   </div> 
                   <div class="col-8">
                    {{dataToDialogBoxSpedytorN}} 
                   </div>  
              </div> 
              <div class="row" style="padding-top: 7px;"> 
                    <div class="col-4">
                      <div style="font-weight:bold; "   >Tel:</div>
                    </div> 
                    <div class="col-8">
                     {{dataToDialogBoxSpedytorT}} 
                    </div>  
              </div> 
              <div class="row" style="padding-top: 7px; padding-bottom: 7px;"> 
                    <div class="col-4">
                      <div style="font-weight:bold; "  >Mail:</div>
                    </div> 
                    <div class="col-8">
                     {{dataToDialogBoxSpedytorM}} 
                    </div> 
              </div> 
          </div>
         
               
 
 
      
          

    </ng-template>
    <ng-template #header>
      <span    style="display: inline-block;"></span> 
      <img  style="margin-left:5px;"  height="35" src="/assets/images/M_trace_na ciemne tlo.png?"  >
        <div   class="e-icon-settings dlg-template">Szczegóły zlecenia</div>
    </ng-template>
  </ejs-dialog>


  <ejs-dialog  [visible]="false"   isModal="true" id='dialog2' #DialogConfirmOrder allowDragging='true' header='Transport jest Twój'  
            [target]='targetElement' width='370px'  height="250px;"> 
          <br>
          <div style="margin-top: 12px; margin-left: 12px;" >Zlecenie przyjęte z obowiązkiem realizacji!</div> 
      <div style="margin-top: 12px;" >  
            <button  (click)="CancelOrder()" id="cancelButton2" class="e-custom" data-ripple="true">Rezygnuj</button>
            <button style="float: right;" (click)="SaveOrder()" id="sendButton" class="btn btn-success" data-ripple="true">Ok</button> 
      </div>

  </ejs-dialog>


  <ejs-dialog  [visible]="false"   isModal="true" id='dialog2' #DialogConfirmOrderNegocjuj allowDragging='true' header='Przyjęto propozycję cenową'  
  [target]='targetElement' width='370px'  height="250px;"> 
<br>
<div style="margin-top: 12px; margin-left: 12px;" >Dziękujemy za złożenie propozycji cenowej! Informację na temat akceptacji Twojej ceny prześlemy e-mailem.</div> 
<div style="margin-top: 12px;" >  
   
  <button style="float: right;" (click)="SaveOrder()" id="sendButton" class="btn btn-success" data-ripple="true">Ok</button> 
</div>

</ejs-dialog>


<ejs-dialog  [visible]="false"   isModal="true" id='dialog2' #DialogNotAvailableOrder allowDragging='true' header='Ktoś był szybszy!'  
[target]='targetElement' width='370px'  height="250px;"> 
<br>
<div style="margin-top: 12px; margin-left: 12px;" >Zlecenie nieaktywne.</div> 
<div style="margin-top: 12px;" >  
 
<button style="float: right;" (click)="CloseDialogNotAvailableOrder()" id="sendButton" class="btn btn-success" data-ripple="true">Ok</button> 
</div>

</ejs-dialog>