import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { User } from './_models/user';
import { AccountService } from './_services/account.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'PickAndGo';
  users:any;

  constructor(private accountService: AccountService){}
  
  ngOnInit() { 

    let i: number = 4;
do {
    console.log( "Block statement execution no." + i )
    i++;
} while ( i < 4)


   this.setCurrentUser();
    
  }

setCurrentUser(){
  const user: User = JSON.parse(localStorage.getItem('user'))
  this.accountService.setCurrentUser(user);
}


  handlePermission() {
    navigator.geolocation.getCurrentPosition(function(){
      alert('Location accessed')
},function(){
     alert('User not allowed')
},{timeout:10000})
}
 
 


 
}
