import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FilterService, GridModule , FilterSettingsModel, GridComponent,GroupService,IFilter,PageService,PageSettingsModel,
  SortService,VirtualScrollService, EditSettingsModel, CommandModel, CommandClickEventArgs, EditService, CommandColumnService,
   QueryCellInfoEventArgs,  RowSelectEventArgs, ToolbarItems, RowDataBoundEventArgs, ToolbarService, SaveEventArgs, ToolbarItem
   , ExcelExportService, PdfExportService,
   ExcelExportCompleteArgs, PdfExportCompleteArgs, TextWrapSettingsModel
  } from '@syncfusion/ej2-angular-grids';

import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EmitType, isNullOrUndefined } from '@syncfusion/ej2-base';
import { DataManager, Query, DataResult } from '@syncfusion/ej2-data';
import { company } from 'src/app/_models/company';
import { User } from 'src/app/_models/user';
import { UserRegister } from 'src/app/_models/userRegister';
import { AccountService } from 'src/app/_services/account.service';
import { CompanyService } from 'src/app/_services/company.service';
import { SelectionSettingsModel } from '@syncfusion/ej2-angular-dropdowns';
import { ToastrService } from 'ngx-toastr'; 
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { L10n } from '@syncfusion/ej2-base';
import { FormValidators } from '@syncfusion/ej2-angular-inputs';

L10n.load({
  'en-US': {
      'grid': {
          'Edit': 'Edycja',
          'Add': 'Nowy użytkownik',
       
      } 
  },
  
});

@Component({
  selector: 'app-moje-konta',
  templateUrl: './moje-konta.component.html',
  styleUrls: ['./moje-konta.component.css'],
  providers: [FilterService,VirtualScrollService,PageService,
    SortService,
    FilterService,
    GroupService, EditService, CommandColumnService, ToolbarService,  ExcelExportService, PdfExportService],
})
export class MojeKontaComponent implements OnInit {

  public wrapSettings: TextWrapSettingsModel;
  @ViewChild('Grid')
  public Grid: GridComponent;
  public submitClicked: boolean = false;
  @ViewChild('orderForm') public orderForm: FormGroup;
  public UserId:Number;
  public ComapnyId:Number;
  public UserComapny: any;
public password :String
public confirm :String
passwordContains: boolean = false ;
passwordConfirm: boolean = false ;

  users:UserRegister[];
  user:UserRegister;
  @ViewChild('Dialog')
  public dialogObj: DialogComponent;
  @ViewChild('container', { read: ElementRef, static: true }) container: ElementRef;
  
 
  public width: string = '335px';
  public visible: boolean = false;
  public multiple: boolean = false;
  public showCloseIcon: Boolean = true;
  public editSettings: EditSettingsModel;
  public commands: CommandModel[];
  public selectionOptions: SelectionSettingsModel; 
  public pageOptions: PageSettingsModel;
  public toolbar: ToolbarItems[] | object;
  public filterOptions: FilterSettingsModel;
  public company: company;
  public format = {type:'date', format:'dd/MM/yyyy'}
  @ViewChild('template') template: DialogComponent; 
  @ViewChild('ejDialog') ejDialog: DialogComponent;
  @ViewChild('ejDialogAdd') ejDialogAdd: DialogComponent;
  // The Dialog shows within the target element.
  public targetElement: HTMLElement;
  public proxy: any = this;
 //dialogbox
 public orderData: UserRegister;
  passwordVisible: boolean;
  pass: string;
  constuser:  User;
  enableButtonPassord: boolean;
  isUserAdmin: boolean;
  loginForm: boolean;

  constructor(@Inject(FormBuilder) public formBuilder: FormBuilder,
   private companyService: CompanyService,public accountService: AccountService
   ,private toastr: ToastrService
   ) { 
   }


  

  async ngOnInit() { 




    this.wrapSettings = { wrapMode: 'Header' };
  this.UserId=0;
  this.commands = [{ type: 'Edit', buttonOption: { content: 'Zmień hasło', cssClass: 'e-flat'  } }   ];
  this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: false, mode: 'Dialog' }; 

  

  this.isUserAdmin = false;
  this.accountService.currentAdminUserSource$.subscribe(x => {
    console.log('From sdmin User subscription 1:', x);
    this.isUserAdmin = x;
  });

  if(this.isUserAdmin) 
  {

 
    //this.toolbar = [{text: 'Dodaj', tooltipText: 'Add', id: 'Add', prefixIcon:'e-add' }, { text: 'Edytuj',    id: 'Edit', prefixIcon:'e-btn-icon e-edit e-icons' },  { text: 'Search', align: 'Right'} ];
     this.toolbar = ['Add',  'Edit'];
   // this.toolbar = [{text: 'Add', tooltipText: 'Add', id: 'add', prefixIcon:'e-add' }];
  //  this.toolbar = [{text: 'Dodaj', tooltipText: 'Add', id: 'Add', prefixIcon:'e-add' }, {text: 'Edytuj', tooltipText: 'Edit', id: 'edit', prefixIcon:'e-edit' }   ];

  }
  else  
  {
    this.toolbar = null;
  }


   this.selectionOptions = {  showCheckbox: true,
     showSelectAll: true};
   this.pageOptions = { pageSizes: ['10', '20', '50', 'All'], pageSize:10 };
   this.filterOptions = { type: 'Excel' };

this.GetUsers(); 

  this.user=   {
    userId: 0, 
    userName: "" ,
    PasswordHash:"",
  PasswordSalt:"" , 
   uzytkownikId: 0,
   usernameDto:null,
  mobile: "",
  mobilePrefix: "",
  email: "",
  idKontrah:0,
  accepted:false,
  datarejstracji: new Date() ,
  isAdmin: false,
  userCompanyId: null,
  UserComapnyCompany: {UserId: 2, nip: "",  nazwa: "",   miejscowosc: "",   ulica: "",  nrNieruchomosci: "",},
  orders: [],
  password: "",
  emailConfirmedToken: "",
  emailConfirmed:false,
  userComapnyCompanyId:0,
  companyId:0,
  isUserAdmin: false 
}  ;


this.confirm="";
this.pass="";
}


get mobile(): AbstractControl  { return this.orderForm.get('mobile'); } 
get email(): AbstractControl  { return this.orderForm.get('email'); } 
get userName(): AbstractControl  { return this.orderForm.get('userName'); } 
get userComapnyCompanyId(): AbstractControl  { return this.orderForm.get('userComapnyCompanyId'); } 
get isAdmin(): AbstractControl  { return this.orderForm.get('isAdmin'); } 
get IsUserAdmin(): AbstractControl  { return this.orderForm.get('IsUserAdmin'); } 
get mobilePrefix(): AbstractControl  { return this.orderForm.get('mobilePrefix'); } 


// getting the form control elements
get Password(): AbstractControl {
  return this.orderForm.get('Password');
}

get confirm_password(): AbstractControl {
  return this.orderForm.get('confirm_password');
}

  async GetUsers() {
    this.constuser = JSON.parse(localStorage.getItem('user'))
    if(this.accountService.isAdmin == true)
    {
     this.users = await this.accountService.GetUsers();
   }
   else
   {
  
     this.users = await this.accountService.GetUsersByCompanyId(this.constuser.companyId);
   }
  }
  onPasswordChange() {

    
    if (this.Password.value == this.confirm_password.value) {
      this.confirm_password.setErrors(null);
    } else {
      this.confirm_password.setErrors({ mismatch: true });
    }
  }

  createFormGroup(users: UserRegister): FormGroup {
    return new FormGroup({
      userComapnyCompanyId: new FormControl(this.constuser.companyId),
        userName: new FormControl(users.userName, Validators.required),
        mobilePrefix: new FormControl(users.mobilePrefix, [Validators.required, Validators.minLength(2), Validators.pattern('[- +()0-9]+')]),
        mobile: new FormControl(users.mobile, [Validators.required, Validators.minLength(6), Validators.pattern('[- +()0-9]+')]),
        email: new FormControl(users.email, [Validators.required,  Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]), 
        isAdmin: new FormControl(users.isAdmin), 
        IsUserAdmin: new FormControl(users.isUserAdmin), 
       // Password: new FormControl(users.password, Validators.minLength(6)),   
        Password: new FormControl(null, [
          Validators.required,
          Validators.pattern(
          //  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
          /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
          ),
          Validators.minLength(6),
        ]),
        confirm_password: new FormControl(users.password, Validators.required ) ,
    }
    
    
    );
}
 

validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      this.validateAllFormFields(control);
    }
  });
}
 
commandClick(args: CommandClickEventArgs): void {
  //alert(JSON.stringify(args.rowData));
  let trasy:any;
 

  //this.dataToDialogBox  = this.dataToFilter.filter((obj) => {
  //return obj.idZlecenia === (args.rowData as Order).idZlecenia;
}  

ngAfterViewInit() {
  setTimeout(() => {
    this.loginForm = true;
  }, 0);
 }



changePassword()
{
  this.user.password = this.pass;
  this.user.userId = this.UserId; 
  this.accountService.newPassword(this.user).subscribe(response => {
     this.toastr.success("Zmieniono hasło")
    }, error => {
      this.toastr.error("Nie udało się zmienić hasła")
    });

    
    this.pass="";
    this.confirm="";
    this.passwordConfirm = false;
    this.passwordContains = false; 
     this.enableButtonPassord = false;
     
    this.ejDialog.hide();
}
 


 


  async actionComplete(args) { 

    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      // Set initail Focus
      if (args.requestType === 'beginEdit') {
          (args.form.elements.namedItem('userName') as HTMLInputElement).focus();
      } else if (args.requestType === 'add') {
          (args.form.elements.namedItem('userName') as HTMLInputElement).focus();
      }
  }


    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      let btnObj = (args.dialog as any).btnObj[0];
      btnObj.content = 'Zapisz';
      btnObj.disabled = !this.orderForm.valid;
      this.orderForm.statusChanges.subscribe((e)=> {
       e === 'VALID' ? btnObj.disabled = false : btnObj.disabled = true; 
      });

     
    }

  switch(args.requestType) {
    case 'save': 

      if(args.data.userId != null)
      {
       
        this.user.email = this.orderForm.value['email'] 
        this.user.isAdmin = this.orderForm.value['isAdmin']
        this.user.mobile = this.orderForm.value['mobile']  
        this.user.userName = this.orderForm.value['userName'] 
        this.user.isUserAdmin = this.orderForm.value['IsUserAdmin'] 
        this.user.mobilePrefix = this.orderForm.value['mobilePrefix'] 
        this.user.userId = this.orderData.userId; 


        if( this.user.isUserAdmin) 
        {

          this.toolbar = [  'Add',  'Edit'  ];
          //this.toolbar = [{ text: 'Excel', tooltipText: 'ExcelExport', id: 'excel', prefixIcon:'e-excelexport'   }  ];
        }
        else  
        {

          (this.Grid.toolbarModule as any).element.style.display = 'none';
        
        }


          this.accountService.updateuser(this.user).subscribe(response => {
            console.log(response); 
            this.toastr.success("Zaktualizowano");
            this.GetUsers();
          }, error=>{
            console.log(error);
            this.GetUsers();
             //this.toastr.error(error.error)
          });
      }
       else
       {


        this.orderData =   this.orderForm.value, 
        this.orderData.UserComapnyCompany =   {CompanyId: this.constuser.companyId}, 
        //this.orderData.UserComapnyCompany =   {CompanyId: this.orderData.userComapnyCompanyId}, 
       await this.accountService.addnewuser(this.orderData).then(response => {
          console.log(response); 
          this.toastr.success("Dodano nowego użytkownika");
          this.GetUsers();
        }, error=>{
          console.log(error); 
           const index: number = this.users.indexOf(args.data);
           if (index !== -1) {
               this.users.splice(index);
           }  
        });

       
       
       }
     
    
      break;
    case 'y':
      // code block
      break;
    default:
      // code block
  }


 


  if ((args.requestType === 'beginEdit' || args.requestType === 'add' || args.requestType === 'refresh')) {
    const dialog = args.dialog; 
     dialog.showCloseIcon = true; 
     dialog.header = args.requestType === 'beginEdit' ? 'Użytkownik nr ' + args.rowData['userId'] : 'Nowy użytkownik';

  }  
}


 
 // Initialize the Dialog component target element.
 public initilaizeTarget: EmitType<object> = () => {
  this.targetElement = this.container.nativeElement.parentElement;
    }
    // Sample level code to handle the button click action
    public onOpenDialog = function(event: any): void {
      this.pass="";
      this.confirm="";
    this.UserId = event;
    this.ejDialog.show();
    };
    // Sample level code to hide the Dialog when click the Dialog overlay
    public onOverlayClick: EmitType<object> = () => {
      this.pass="";
      this.confirm="";
    this.ejDialog.hide();
    }

 

    actionBegin(args: SaveEventArgs): void {


      if (args.requestType === 'beginEdit' || args.requestType === 'add') {
        this.submitClicked = false;
        this.orderForm = this.createFormGroup(args.rowData as UserRegister);
    }

      let uemail : string | undefined;
      if(args.requestType === uemail)
      {
        this.ejDialogAdd.show();

      }
      if (args.requestType === 'beginEdit' ) {
          this.orderData = args.rowData as UserRegister; 
          this.passwordVisible = false;

          this.orderForm.controls['Password'].clearValidators();
          this.orderForm.controls['confirm_password'].clearValidators();
 
          this.orderForm.controls['Password'].updateValueAndValidity();
          this.orderForm.controls['confirm_password'].updateValueAndValidity();
      }


      if ( args.requestType === 'add') { 
        this.orderData = args.rowData as UserRegister; 
 
        this.orderData.userComapnyCompanyId = this.constuser.companyId;
        this.orderData.isUserAdmin = false;
        this.passwordVisible = true;
    }




      if (args.requestType === 'save') {
        this.submitClicked = true;
          if (this.orderForm.valid) {
              args.data = this.orderForm.value;
              //args.data = this.orderData;
          } else {
              args.cancel = true;
          }
      }
  }


  saverange(newValue, area) {    
   var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;    
   if(area == "pass1")
   {  

    this.pass = newValue;
    
          if(newValue.length > 0 && newValue != "password")
          { 

      

            if(format.test(newValue)){
              this.passwordContains = false;
            } else {
            this.passwordContains = true;
            }
          } 
   }
  
   if(area == "pass2")
   {  
          if(this.pass != newValue)
          { 
              this.passwordConfirm=true;
          } 
          else 
          {
            this.passwordConfirm=false; 
          }
   }

   if(this.passwordConfirm && !this.passwordContains)
   {
    this.enableButtonPassord = true;
   }
 
  }

 changeAdmin(args) {
   this.orderData.isAdmin = args.checked;
 }


 changeUserAdmin(args) {
  this.orderData.isUserAdmin = args.checked;
}


clickHandler(args: ClickEventArgs): void {
  if (args.item.id === 'Add') { // Grid_Collapse is control id + '_' + toolbar value.
   this.ejDialogAdd.show();
  }
}


 
    public exportBlob = (blob: Blob) => {
        const a: HTMLAnchorElement = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        const url: string = (window.URL as any).createobjectURL(blob);
        a.href = url;
        a.download = 'Export';
        a.click();
        (window.URL as any).revokeobjectURL(url);
        document.body.removeChild(a);
    }

 

    toolbarClick(args: ClickEventArgs) {
     
        if( args.item.id.indexOf('pdfexport') >= 0){
        {
            this.Grid.pdfExport(null, null, null, true);
        }
      }
    }

    excelExpComplete(args: ExcelExportCompleteArgs) {
        // This event will be triggered when excel exporting.
        args.promise.then((e: { blobData: Blob }) => {
            // In this `then` function, we can get blob data through the arguments after promise resolved.
            this.exportBlob(e.blobData);
        });
    }

    pdfExpComplete(args: PdfExportCompleteArgs) {
        // This event will be triggered when pdf exporting.
        args.promise.then((e: { blobData: Blob }) => {
            // In this `then` function, we can get blob data through the arguments after promise resolved.
            this.exportBlob(e.blobData);
        });
    }

    

}




