



<div    style="position: absolute;
margin-left: auto;
margin-right: auto;
left: 0;  color:grey;
right: 0; top: 250px;
text-align: center;">
    <h1><p>E-mail został wysłany! Sprawdź swoją skrzynkę pocztową.</p></h1>
        <p class="lead"> </p>
       <!-- <div class="text-center">
                <button (click)="registerToogle()" class="btn btn-primary btn-lg mr-2">Rejestracja</button>
                <button class="btn btn-info btn-lg">Zaloguj</button>
        </div>-->
</div>