 
<div style="margin-top: 130px;"></div>  <hr> 
<h4 class="text-center text-primary">Konta Użytkowników</h4><h5 style="margin-top: 20px; text-align:center; "></h5>
        

<ejs-grid  allowTextWrap="true" textWrapSettings="wrapSettings" style="margin-top: 30px; margin-bottom: 30px;" #Grid [dataSource]='users'  (commandClick)='commandClick($event)' [editSettings]='editSettings'  [toolbar]='toolbar'
(actionComplete)="actionComplete($event)"    (actionBegin)='actionBegin($event)'   
 [allowFiltering]='true' [pageSettings]='pageOptions' [allowPaging]="true" [filterSettings]='filterOptions' allowSorting="true"  
  [allowExcelExport]='true'
    (excelExportComplete)='excelExpComplete($event)' (pdfExportComplete)='pdfExpComplete($event)'
      [allowPdfExport]='true' (toolbarClick)='toolbarClick($event)'  >

     <e-columns>
        <e-column *ngIf="accountService.currentAdminSource$ | async" [allowEditing]=false  field='userComapnyCompany.companyId' 
        headerText='Id Przewoźnika' width='130'   textAlign='Center' headerTextAlign="Center"></e-column>
        
        <e-column *ngIf="accountService.currentAdminSource$ | async"[allowEditing]=false field='userId' headerText='Id Użytkownika'
         headerTextAlign="Right" [isPrimaryKey]='true' width='120'  textAlign='Center' headerTextAlign="Center" ></e-column> 
        <e-column *ngIf="accountService.currentAdminSource$ | async" [allowEditing]=false field='userComapnyCompany.nip' headerTextAlign="Center" headerText='Nip' textAlign='Center' width='110' ></e-column>
        <e-column *ngIf="accountService.currentAdminSource$ | async" [allowEditing]=false field='userComapnyCompany.nazwa'  headerText='Nazwa Przewoźnika' width='250'  headerTextAlign="Center" textAlign='Left'></e-column>

        <e-column [allowEditing]=false field='userName' headerText='Login/użytkownik' width='140' headerTextAlign="Center"  textAlign='Left'></e-column>
        <e-column field='email' headerText='E-mail' width='160' textAlign='Left' headerTextAlign="Center"  ></e-column>
           
        <e-column headerText="Telefon" width="120" textAlign='Center' [allowFiltering]='true'>
          
          <ng-template #template let-data>
            <div>
              <span>{{data.mobilePrefix}}   {{data.mobile}}</span>  
            </div>
          </ng-template>
             
         
      </e-column>

             <e-column *ngIf="accountService.currentAdminSource$ | async" field='isAdmin' headerText='Admin' width='80' textAlign='Right' editType= 'booleanedit'  displayAsCheckBox="true" ></e-column> 
         <e-column [allowEditing]=false   field='isUserAdmin' headerText='Szef' width='70' textAlign='Right' editType= 'booleanedit'  displayAsCheckBox="true" ></e-column> 
      
      
         <!-- <e-column [allowEditing]=false headerText='Zmiana hasła'    width='90' [commands]='commands'  ></e-column>-->
        <e-column headerText='Zmiana hasła' width='150' [allowEditing]=false textAlign='center'> 
            <ng-template #template let-data> 
              <div> 
                <button  style="color:white;" ejs-button class="DefaultButton" (click)="onOpenDialog(data.userId)"> 
                  Zmień hasło 
                </button> 
              </div> 
            </ng-template>       
          </e-column> 
   
    </e-columns>
    <ng-template   #editSettingsTemplate let-data >
   
  
        <div [formGroup]="orderForm"  #formDir="ngForm"  style="width: 460px;">

          <div class="form-row" > 

            <div class="col-6"  [style.display]="(accountService.currentAdminSource$ | async) ? 'block' : 'none'"  > 
               <div class="e-float-input e-control-wrapper"  style="margin-bottom:30px; margin-top: 28px !important;" >
                 <ejs-checkbox label="Admin" (change)="changeAdmin($event)" formControlName="isAdmin"></ejs-checkbox>  
               </div> 
            </div>

            <div class="col-6"  [style.display]="((accountService.currentAdminUserSource$ | async) || (accountService.currentAdminSource$ | async)) ? 'block' : 'none'"> 
               <div class="e-float-input e-control-wrapper"  style="margin-bottom:30px; margin-top:28px !important;" >
                 <ejs-checkbox label="Szef" (change)="changeUserAdmin($event)"  formControlName="IsUserAdmin"></ejs-checkbox>  
               </div>
            </div> 
       </div>
          <div class="form-row">
            <div class="col-6">
              <div class="e-float-input e-control-wrapper" [ngClass]="{'e-error': userComapnyCompanyId.invalid && (userComapnyCompanyId.dirty || userComapnyCompanyId.touched)}">
                  <input formControlName="userComapnyCompanyId" readonly data-msg-containerid='userComapnyCompanyIdError' id="userComapnyCompanyId" name="userComapnyCompanyId" type="text">
                  <span class="e-float-line"></span>
                  <label class="e-float-text e-label-top" for="userComapnyCompanyId">Company ID</label>
              </div> 
          </div>


              <div class="col-6">
                <div class="e-float-input e-control-wrapper" [ngClass]="{'e-error': userName.invalid && (userName.dirty || userName.touched)}">
                    <input formControlName="userName" data-msg-containerid='userNameError' id="userName" name="userName" type="text" >
                    <span class="e-float-line"></span>
                    <label class="e-float-text e-label-top" for="userName">Nazwa użytkownika</label>
                </div>
                <div id="userNameError" [style.display]='((userName.invalid))? "block": "none"'>
                    <label class="e-error" for="userName" id="userName-info" style="display: block;">*Nazwa użytkownika jest wymagana</label>
                </div>
            </div> 
          </div>


          <div class="form-row"> 



            <div class="col-2">
              <div class="e-float-input e-control-wrapper" [ngClass]="{'e-error': mobilePrefix.invalid && (mobilePrefix.dirty || mobilePrefix.touched)}">
                  <input formControlName="mobilePrefix" data-msg-containerid='mobileprefixError' id="mobilePrefix" name="mobilePrefix" type="text">
                  <span class="e-float-line"></span>
                  <label class="e-float-text e-label-top" for="mobilePrefix">Prefix</label>
              </div> 
              <div id="mobileprefixError" [style.display]='((mobilePrefix.invalid && (mobilePrefix.dirty || mobilePrefix.touched)) || (mobilePrefix.invalid && submitClicked))? "block": "none"'>
                <label class="e-error" for="mobile" id="mobile-info" style="display: block;">*Prefix jest wymagany</label>
            </div>
          </div>
          
            <div class="col-4">
              <div class="e-float-input e-control-wrapper" [ngClass]="{'e-error': mobile.invalid && (mobile.dirty || mobile.touched)}">
                  <input formControlName="mobile" data-msg-containerid='mobileError' id="mobile" name="mobile" type="text">
                  <span class="e-float-line"></span>
                  <label class="e-float-text e-label-top" for="email">Telefon</label>
              </div>
              <div id="mobileError" [style.display]='((mobile.invalid && (mobile.dirty || mobile.touched)) || (mobile.invalid && submitClicked))? "block": "none"'>
                  <label class="e-error" for="mobile" id="mobile-info" style="display: block;">*Telefon jest wymagany</label>
              </div>
          </div>

          
            <div class="col-6">
              <div class="e-float-input e-control-wrapper" [ngClass]="{'e-error': email.invalid && (email.dirty || email.touched)}">
                  <input formControlName="email" data-msg-containerid='emailError' id="email" name="email" type="email">
                  <span class="e-float-line"></span>
                  <label class="e-float-text e-label-top" for="email">E-mail</label>
              </div>

              <div id="PasswordError" [style.display]='((email.invalid && (email.dirty || email.touched)) || (email.invalid && submitClicked))? "block": "none"'>
                <label class="e-error" for="Password" id="Password-info" style="display: block;">*Poprawny adres e-mail jest wymagany</label>
            </div> 
              
          </div>
        </div>

         
          <div class="form-row"  [style.display]="passwordVisible ? 'block' : 'none'"  > 
            <div class="col-5">
              <div class="e-float-input e-control-wrapper" [ngClass]="{'e-error': Password.invalid && (Password.dirty || Password.touched)}">
                  <input (change)="onPasswordChange()" formControlName="Password" data-msg-containerid='Password' id="Password" name="Password" type="password">
                  <span class="e-float-line"></span>
                  <label class="e-float-text e-label-top" for="Password">Hasło</label>
              </div>
              <div id="PasswordError" [style.display]='(Password.errors?.["required"] &&   
              (Password.dirty || Password.touched)|| (email.invalid && submitClicked))? "block": "none"'>
                  <label class="e-error" for="Password" id="Password-info" style="display: block;">*Hasło jest wymagane</label>
              </div>


              <div  [style.display]='Password.invalid &&  (Password.dirty || Password.touched) ? "block": "none"'>
               
               
              <div [style.display]='Password.errors?.["pattern"] ? "inline-block": "none"'>

               <!--<label class="e-error" for="Password" id="Password-info" style="display: block;">*Hasło musi zawierać przynajmniej jedną cyfrę, małą i dużą literę, znak specjalny i przynajmniej 8 znaków. Hasło nie może zawierać spacji</label>-->
                <label class="e-error" for="Password" id="Password-info" style="display: block;">* Hasło musi mieć conajmniej 6 znaków  i zawierać jeden znak specjalny</label>
                
              </div>
            </div>


               
          </div>
            <div class="col-5">
              <div class="e-float-input e-control-wrapper" [ngClass]="{'e-error': confirm_password.invalid && (confirm_password.dirty || confirm_password.touched)}">
                  <input (change)="onPasswordChange()" formControlName="confirm_password" data-msg-containerid='confirm_passwordError' id="confirm_password" name="confirm_password" type="password">
                  <span class="e-float-line"></span>
                  <label class="e-float-text e-label-top" for="confirm_password">Powtórz hasło</label>
              </div>
              <div   [style.display]="(confirm_password.hasError('mismatch') && (confirm_password.dirty || confirm_password.touched)) ? 'block': 'none'">
                  <label class="e-error" for="confirm_password" id="confirm_password-info" style="display: block;">*Hasła nie pasują do siebie.</label>
              </div>
          </div>
          </div>
      </div> 
  </ng-template>
</ejs-grid>




<ejs-dialog isModal="true" [showCloseIcon]="true" [visible]="false" id='dialogAdd' #ejDialogAdd content='This is a Dialog with content' height="300" [target]='targetElement' width='250px'>
  <ng-template #footerTemplate >
       <button [disabled]="!enableButtonPassord"  (click)="changePassword()"  id="sendButton" class="btn btn-success"  data-ripple="true">Zmień</button>

 
      </ng-template>
  <ng-template #content>
<ng-template   #editSettingsTemplate let-data >


  <div ngForm #orderForm="ngForm" style="width: 360px;">
 

      <div class="row" > 

           <div class="col-6" *ngIf="accountService.currentAdminSource$ | async"> 
            <div class="e-float-input e-control-wrapper"  style="margin-bottom:30px; margin: top 12px !important;" >
              <ejs-checkbox label="Admin" (change)="changeAdmin($event)" [checked]="user.isAdmin"></ejs-checkbox>  
            </div> 
           </div>



           <div class="col-6" *ngIf="(accountService.currentAdminUserSource$ | async) || (accountService.currentAdminSource$ | async)"> 
            <div class="e-float-input e-control-wrapper"  style="margin-bottom:30px; margin: top 12px !important;" >
              <ejs-checkbox label="Szef" (change)="changeUserAdmin($event)" [checked]="user.isUserAdmin"></ejs-checkbox>  
            </div>
           </div> 
      </div>
      <div class="row">
          <div class="col-6">
              <div class="e-float-input e-control-wrapper"  >
                  <input [(ngModel)]="user.userComapnyCompanyId" readonly   id="companyId" name="companyId" type="text"  
                  [attr.disabled]="!accountService.currentAdminSource$ | async" >
                  <span class="e-float-line"></span>
                  <label class="e-float-text e-label-top" for="companyId">Company Id</label>
              </div> 
          </div>
          <div class="col-6">
              <div class="e-float-input e-control-wrapper" [ngClass]="{'e-error': CustomerID.invalid && (CustomerID.dirty || CustomerID.touched)}">
                  <input [(ngModel)]="user.userName" required id="CustomerID" name="CustomerID" type="text" #CustomerID="ngModel">
                  <span class="e-float-line"></span>
                  <label class="e-float-text e-label-top" for="CustomerID">Nazwa użytkownika</label>
              </div>
              <div id="CustomerIDError" *ngIf='CustomerID.invalid && (CustomerID.dirty || CustomerID.touched)'>
                  <label class="e-error" id="CustomerID-info" style="display: block;">*Nazwa użytkownika jest wymagana</label>
              </div>
          </div>
      </div>


      <div class="row">
        <div class="col-6">
            <div class="e-float-input e-control-wrapper" [ngClass]="{'e-error': mobile.invalid && (mobile.dirty || mobile.touched)}">
                <input [(ngModel)]="user.mobile" required id="mobile" name="mobile" type="text"   #mobile="ngModel">
                <span class="e-float-line"></span>
                <label class="e-float-text e-label-top" for="mobile">Telefon</label>
            </div>
            <div id="mobileDError" *ngIf='mobile.invalid && (mobile.dirty || mobile.touched)'>
                <label class="e-error" id="mobile-info" style="display: block;">*Order ID is required</label>
            </div>
        </div>
        <div class="col-6">
          <div class="e-float-input e-control-wrapper" >
            <input [(ngModel)]="user.email" required id="emailId" [email]="true"  name="email" type="text" #email="ngModel">
            <span class="e-float-line"></span>
            <label class="e-float-text e-label-top" for="emailId">Email</label>
        </div>
        <div [hidden]="email.valid || email.pristine" class="e-error">
        <label class="e-error" id="CustomerID-info" style="display: block;">*Podaj poprawny adres email</label>
          </div> 
        </div>
        
    </div>

     
      <div class="row" *ngIf="passwordVisible">
        <div class="col-6" style="max-width:158px;">
          <div class="e-float-input e-control-wrapper" [ngClass]="{'e-success': password.valid}">
              <input  (ngModelChange)="saverange($event, 'pass1')"    type="password" id="password" name="password" [(ngModel)]="user.password" #password="ngModel" minlength="6" required >
             
              <label class="e-float-text" [ngClass]="{'e-label-top': user.password !== '' , 'e-label-bottom':  user.password == ''}" for="password">Hasło</label>                                        
          </div>

          <span style="white-space:pre-wrap;" class="e-error" *ngIf="passwordContains" >
              * Hasło musi mieć conajmniej 6 znaków  i zawierać jeden znak specjalny 
          </span>
      </div>


   

      <div class="col-6" style="max-width:158px;">
        <div class="e-float-input e-control-wrapper" >
            <input  (ngModelChange)="saverange($event, 'pass2')"    type="password" id="password" name="miejscowoscPoczty" [(ngModel)]="confirm" #password="ngModel" minlength="6" required >
           
            <label class="e-float-text" [ngClass]="{'e-label-top': confirm !== '' , 'e-label-bottom':  confirm== ''}" for="miejscowoscPoczty">Powtórz hasło</label>                                        
        </div>

        <span style="white-space:pre-wrap;" class="e-error" *ngIf="passwordConfirm" >
            * Hasła muszą być takie same!
        </span>
    </div>
      </div>
  </div> 
</ng-template>
</ng-template>
 


<ng-template #header>
  <span    style="display: inline-block;"></span> 
  <img  style="margin-left:5px;"  height="35" src="/assets/images/M_trace_na ciemne tlo.png?"  >
    <div  style="padding-top: 3px;"   class="e-icon-settings dlg-template"> Zmiana hasła </div>
</ng-template>

</ejs-dialog>



<div #container class='root-container'></div>
  <ejs-dialog isModal="true" [showCloseIcon]="true" [visible]="false" id='dialog' #ejDialog content='This is a Dialog with content' height="320" [target]='targetElement' width='250px'>
    <ng-template #footerTemplate >
         <button [disabled]="!enableButtonPassord"  (click)="changePassword()"  id="sendButton" class="btn btn-success" data-ripple="true">Zmień</button>
 
   
        </ng-template>
    <ng-template #content>
  
            <div class="form-group template" style="margin-top:20px;">
                <div class="e-float-input">
                    <input  (ngModelChange)="saverange($event, 'pass1')"    type="password"    [(ngModel)]="pass" #password="ngModel" minlength="6" required >
                   
                    <label class="e-float-text"  for="password">Hasło</label>                                        
                </div>

                <span  class="e-error" *ngIf="passwordContains" >
                    * Hasło musi mieć conajmniej 6 znaków  i zawierać jeden znak specjalny 
                </span>
            </div>


         

            <div class="form-group template">
              <div class="e-float-input" >
                  <input  (ngModelChange)="saverange($event, 'pass2')"    type="password"  name="miejscowoscPoczty" [(ngModel)]="confirm" #password="ngModel" minlength="6" required >
                 
                  <label class="e-float-text" [ngClass]="{'e-label-top': confirm !== '' , 'e-label-bottom':  confirm == ''}" for="miejscowoscPoczty">Powtórz hasło</label>                                        
              </div>

              <span  class="e-error" *ngIf="passwordConfirm" >
                  * Hasła muszą być takie same!
              </span>
          </div>
        <style>
              .e-input-eye:before {
                content:'\e345';
                font-family: e-icons;
                font-size: 13px;
            }
          </style>
    </ng-template>
    <ng-template #header>
      <span    style="display: inline-block;"></span> 
      <img  style="margin-left:5px;"  height="35" src="/assets/images/M_trace_na ciemne tlo.png?"  >
        <div  style="padding-top: 3px;"   class="e-icon-settings dlg-template"> Zmiana hasła </div>
    </ng-template>

</ejs-dialog>

 