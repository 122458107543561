
 <div style="margin-top:150px;"></div>
<h2 style="text-align: center;">&nbsp;</h2>
<h2 style="text-align: center;"><span style="color: rgb(46, 44, 44);"><strong>Twój e-mail został zweryfikowany, konto będzie aktywne po akceptacji Administratora.</strong></span></h2>
<h2 style="text-align: center;"><span style="color: rgb(46, 44, 44);"><strong>Proszę o cierpliwość!</strong></span></h2>
 

<!--
<div *ngIf="!accountConfimed">
    <h2 style="text-align: center;">&nbsp;</h2>
    <h2 style="text-align: center;"><span style="color: #008000;"><strong>Twoje konto nie zotało aktywowane</strong></span></h2>
 </div>-->