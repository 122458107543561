<div class="container2" style="margin-top: 130px;">
    

    <div class="row h-100">
        <div class="col-5"  > <!--LEWA KOLUMNA-->
            <div class="row" > 
                <div class="col-12 h-150">  </div> 
                <div class="col-12" style="margin-top: 50px;"> 
                   <h1  >Witaj</h1> 
                    <h2> na nowej giełdzie</h2> 
                    <h2> transportowej!</h2> 
                 </div> 
                <div class="col-12" style="margin-top: 3.2vw;"> 
                    <p> Szukasz ładunku? </p>
                    <p> Już teraz możesz za darmo zarejestrować się w najnowszej giełdzie transportowej <br /> 
                       i skutecznie optymalizować wykorzystanie swoich pojazdów! </p>
                    <p> Jesteśmy dostępni przez całą dobę! Sprawdź nas! </p>

                    <img style="margin-top:3.2vw; margin-bottom:3.2vw; margin-left: 35%; margin-right: 65%; width: 1.7em; ;" src="https://maszonski.pl/uploads/images/Mtrace/strza%C5%82ka.png" alt=""  />

                </div>  
               
               
            </div>
        </div>
    
       

        <div class="col-7" > <!--pinezka-->
            <div class="row ">
                <div class="col-6"> </div>
                <div class="col-6 h-190" style="height:90px;"  id="prawaKolumna"> </div> 
            </div>


            <div class="row h-100">
                <div class="col-12" style="height: 100%; background: linear-gradient(to right, white 50%, #00a0e3 50%)  ">                
                    <img style="margin-top:0; display: block; width:75% ; position: relative;" src="https://maszonski.pl/uploads/images/Mtrace/pinezka.png" alt="pinezka" height=auto />
                </div> 
            </div>
            
        </div>

 
       


            <div class="col-5"> <!-- Bezpłatny dostęp--> 
                <ul>
                    <li> Bezpłatny dostęp do aplikacji mobilnej </li>
                    <li> Łatwe wyszukiwanie ładunków, m.in. po geolokalizacji </li>
                    <li> Rozbudowana baza ładunków  </li>
                    <li> Automatyczny dostęp do ładunków w czasie rzeczywistym </li>
                    <li>  Ekspresowe płatności - E-skonto </li>
                 </ul> 
            </div> 
            <div class="col-7">  
           
                <div class="row h-100"  >
                    <div class="col-6">   </div>
                    <div class="col-6"  id="prawaKolumna">    </div>
                </div> 
            </div>

       
            <div class="col-5"> <!--smartfon_komputer--> 
                <img  style="margin-top: 40px; display: block; " src="/assets/images/paczka/smartfon_komputer.png" alt="telefon"   />
            </div> 
            <div class="col-7" style="margin-top:50px; margin-bottom:0px;"> 
                <h3 > <font  style=" color: #00a0e3;">   Zarejestruj się </font>
                i zyskaj dostęp<br />do ładunków w każdej chwili, <br /> w każdym miejscu!</h3>  
           
                <div class="row"  >
                    <div class="col-6">  </div>
                    <div class="col-6" style="margin-top:30px;   "  id="prawaKolumnaStopka">    </div>
                </div> 
            </div>


          
        
    </div>


</div>
 
 