<div style="margin-top: 130px;"></div>     <hr> 
<h4  class="text-center text-primary">Kontakt</h4><h5 style="margin-top: 20px; text-align:center; "></h5>
    
 
<div  style="margin-top:16px; max-width: 600px; margin-left: auto; margin-right: auto;">
    <ejs-grid #grid [dataSource]='forwarders' header  showColumnMenu='false' style="margin-top:16px;   width: 600px !important " >
        <e-columns>
            
            <e-column  style="margin-top:16px;   " headerText='Spedytorzy' textAlign='Center'> </e-column> 
        </e-columns>
        <ng-template #rowTemplate let-data > 
            <tr >
                <td class="rowphoto"  style="width: 80px;   border-bottom: 1pt solid #e0e0e0; background: #f9f9f9; ">
                    <img [src]='data.pic' width="90px"  />
           
                </td>
                <td class="details" >
                    <table class="CardTable" cellpadding="3" cellspacing="2" style="margin-top:16px; max-width:290px;   padding-left: 10px;  height: 80px;">
                        
                        <tbody   >
                            <tr>
                               
                                <td>{{data.forwarderName}} </td>
                            </tr>
                            <tr>
                               
                                <td>{{data.forwarderEmail}} </td>
                            </tr>
                            <tr>
                          

                                <td>{{data.forwarderTel}}</td>
                            </tr>
                           <!--  <tr>
                                <td class="CardHeader">Birth Date</td>
                                <td>{{format(data.BirthDate)}}</td>
                            </tr>
                            <tr>
                                <td class="CardHeader">Hire Date</td>
                                <td>{{format(data.HireDate)}}</td>
                            </tr>-->
                        </tbody>
                    </table>
                </td>
            </tr>
        </ng-template>
    </ejs-grid>


</div>
 