<h4 style="margin-top:130px; "> </h4> 
<div class="control-section">
    <div class="col-lg-12">
         <h4 class="text-center text-primary" style="color:rgba(0, 160, 227)">Utwórz nowe hasło</h4><h5 style="text-align:center; "></h5>
        <hr>        <div class="control_wrapper" id="control_wrapper" style="margin: 25px auto;">
    

            <div class="form-group template"style="padding-top: 23px;">
                <div class="e-float-input" [ngClass]="{'e-success': password.valid}">
                    <input  (ngModelChange)="saverange($event, 'pass1')"    type="password" id="password" name="password" [(ngModel)]="user.username" #password="ngModel" minlength="6" required >
                   
                    <label class="e-float-text" [ngClass]="{'e-label-top':  user.username !== '' , 'e-label-bottom':   user.username == ''}" for="password">Nowe hasło</label>                                        
                </div>

                <span  class="e-error" *ngIf="passwordContains" >
                    * Hasło musi mieć conajmniej 6 znaków  i zawierać jeden znak specjalny 
                </span>
            </div>


            <div class="form-group template">
                <div class="e-float-input" >
                    <input  (ngModelChange)="saverange($event, 'pass2')"    type="password" id="password" name="miejscowoscPoczty" [(ngModel)]="user.token" #password2="ngModel" minlength="6" required >
                   
                    <label class="e-float-text" [ngClass]="{'e-label-top': user.token !== '' , 'e-label-bottom': user.token == ''}" for="miejscowoscPoczty">Powtórz hasło</label>                                        
                </div>

                <span  class="e-error" *ngIf="passwordConfirm" >
                    * Hasła muszą być takie same!
                </span>
            </div>

            
          
              <div class="form-group" style="padding-top: 11px;">
                <div class="submitBtn">
                 <!-- <button class="submit-btn e-btn" id="submit-btn"  (click)= "Submit()">Wyślij prośbę zmian</button>-->
                  <button  style="   margin-right: 20px; margin-bottom: 30px;" class="btn btn-success mr-2" (click)="passwordRemaind()" type="submit">Zatwierdź</button> 

                  <div class="desc"><span></span></div>
                </div>
              </div>


         
      </div>
    </div>
</div>