<h4 style="margin-top:130px; "> </h4> 
<div class="control-section">
    <div class="col-lg-12">
         <h4 class="text-center text-primary" style="color:rgba(0, 160, 227)">Negocjacje</h4><h5 style="text-align:center; "></h5>
        <hr> 
          <div class="control_wrapper" id="control_wrapper" style="margin: 25px auto;">
    
            
          <div *ngIf="accepted; else rejected">
            <h4  style="color:rgb(83, 227, 0); margin-top: 100px;  display: flex;
            justify-content: center;" > Zaakceptowałeś proponowaną kwotę.</h4><h5 style="text-align:center; "></h5>
           
          </div>
          
          <ng-template #rejected>
            <h4  style="color:rgb(227, 91, 0); margin-top: 100px; display: flex;
            justify-content: center;"> Odrzuciłeś proponowaną kwotę.</h4><h5 style="text-align:center; "></h5>
          
          </ng-template> 
      </div>
    </div>
</div>