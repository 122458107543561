import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { company } from '../_models/company';
import { User } from '../_models/user';
import { CompanyService } from '../_services/company.service';

@Component({
  selector: 'app-moja-flota',
  templateUrl: './moja-flota.component.html',
  styleUrls: ['./moja-flota.component.css']
})

 

export class MojaFlotaComponent implements OnInit {

  company:company;
 
 
  
  title = "Stepper input";
  @Input() initialValue = 0;
  @Input() step  = 1;
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() symbol: string;
  @Input() ariaLabelLess: string;
  @Input() ariaLabelMore: string;
  
  renderedValue_ciagnik_siodlowy: string; 
  renderedValue_ciagnik_trojosiowy : string; 
  renderedValue_ciagnik_typu_mega: string;   
  renderedValue_ciagnik_typu_mega_regul: string;  
  renderedValue_value_jumbo_z_przyczepa: string;    
  renderedValue_naczepa_oplandekowana: string;     
  renderedValue_mega_naczepa_oplandekowana: string;  
  renderedValue_mega3m: string;

  constructor(private companyService: CompanyService, private toastr: ToastrService) { }

  async ngOnInit(): Promise<void> {

    
    this.company = {
      companyId:0,
      userId:0,
      kodPocztowy: "",
      nip: "", 
      regon: "",
      statusNip: "",
      nazwa: "",
      wojewodztwo: "",
      powiat: "",
      gmina: "",
      miejscowosc: "", 
      ulica: "",
      nrNieruchomosci: "",
      nrLokalu: "",
      typ: "F",
      silosID: 1,
      dataZakonczeniaDzialalnosci : "2019-01-06T17:16:40",
      miejscowoscPoczty: "", 
      ciagnikSiodlowy:  0,
      ciagnikTrojosiowy:  0,
      ciagnikTypuMega:  0,
      ciagnikTypuMegaReagul:  0,  
      mega3m:0,  
      idKontrah:0,
      accepted: false, 
      datarejstracji :   new Date(), 
      users: [
        {userId: 1, 
          userName: "" ,
          PasswordHash:"",
        PasswordSalt:"" , 
         uzytkownikId: 0,
         usernameDto:null,
        mobile: "",
        mobilePrefix: "",
        email: "",
        idKontrah:0,
        accepted:false,
        datarejstracji: new Date() ,
        isAdmin: false,
        userCompanyId: null,
        UserComapnyCompany: {UserId: 2, nip: "",  nazwa: "",   miejscowosc: "",   ulica: "",  nrNieruchomosci: "",},
        orders: [],
        password: "",
        emailConfirmedToken: "",
        emailConfirmed:false,
        userComapnyCompanyId:0,
        companyId:0,
        isUserAdmin: false 
      },
        
      ], 
      errors: [],
      emailConfirmed:false
    };



    const user: User = JSON.parse(localStorage.getItem('user'))
    this.company = await this.companyService.GetCompany({"UserId" : user.userId, "CompanyId":user.companyId,  "Nip":"2", "Nazwa":"2", "Ulica":"2", "Miejscowosc":"ss", "NrNieruchomosci":"2"});
 
    this.renderedValue_ciagnik_siodlowy = this.company.ciagnikSiodlowy.toString(); 
    this.renderedValue_ciagnik_trojosiowy = this.company.ciagnikTrojosiowy.toString(); 
    this.renderedValue_ciagnik_typu_mega = this.company.ciagnikTypuMega.toString();  
    this.renderedValue_ciagnik_typu_mega_regul = this.company.ciagnikTypuMegaReagul.toString(); 
    this.renderedValue_mega3m = this.company.mega3m.toString(); 
   }


  aktualizujFlote()
  {
    this.companyService.updateFlota(this.company)



          this.companyService.updateFlota(this.company).subscribe(response => {
              console.log(response); 
              this.toastr.success("Zaktualizowano flotę");
            }, error=>{
              console.log(error);
               this.toastr.error(error.error)
            });
  }


 




  toggleMore_mega3m()  {
    if (this.step + this.company.mega3m <= this.max) {
      this.company.mega3m = this.company.mega3m +  this.step;
      this.renderedValue_mega3m = this.company.mega3m.toString()  ;
    }
  } 
  
  toggltoggleLess_mega3m() {
    if (this.company.mega3m - this.step >= this.min) {
      this.company.mega3m = this.company.mega3m - this.step;
      this.renderedValue_mega3m = this.company.mega3m.toString();
    }
  } 


  toggleMore_ciagnik_siodlowy()  {
    if (this.step + this.company.ciagnikSiodlowy <= this.max) {
      this.company.ciagnikSiodlowy = this.company.ciagnikSiodlowy +  this.step;
      this.renderedValue_ciagnik_siodlowy = this.company.ciagnikSiodlowy.toString()  ;
    }
  } 

  toggleLess_ciagnik_siodlowy() {
    if (this.company.ciagnikSiodlowy - this.step >= this.min) {
      this.company.ciagnikSiodlowy = this.company.ciagnikSiodlowy - this.step;
      this.renderedValue_ciagnik_siodlowy = this.company.ciagnikSiodlowy.toString();
    }
  } 

  toggleMore_ciagnik_trojosiowy()  {
    if (this.step + this.company.ciagnikTrojosiowy <= this.max) {
      this.company.ciagnikTrojosiowy = this.company.ciagnikTrojosiowy + this.step;
      this.renderedValue_ciagnik_trojosiowy = this.company.ciagnikTrojosiowy.toString()  ;
    }
  } 

  toggleLess_ciagnik_trojosiowy() {
    if (this.company.ciagnikTrojosiowy - this.step >= this.min) {
      this.company.ciagnikTrojosiowy = this.company.ciagnikTrojosiowy - this.step;
      this.renderedValue_ciagnik_trojosiowy = this.company.ciagnikTrojosiowy.toString();
    } 
  } 

  toggltoggleLess_ciagnik_trojosiowy_typu_mega()  {
    if (this.company.ciagnikTypuMega - this.step >= this.min) {
      this.company.ciagnikTypuMega = this.company.ciagnikTypuMega - this.step;
      this.renderedValue_ciagnik_typu_mega = this.company.ciagnikTypuMega.toString();
    } 
  } 
  toggleMore_ciagnik_trojosiowy_typu_mega() {

    if (this.step + this.company.ciagnikTypuMega <= this.max) {
      this.company.ciagnikTypuMega = this.company.ciagnikTypuMega + this.step;
      this.renderedValue_ciagnik_typu_mega = this.company.ciagnikTypuMega.toString()  ;
    }
  } 



  toggltoggleLess_ciagnik_trojosiowy_typu_mega_regul()  {
    if (this.company.ciagnikTypuMegaReagul - this.step >= this.min) {
      this.company.ciagnikTypuMegaReagul = this.company.ciagnikTypuMegaReagul - this.step;
      this.renderedValue_ciagnik_typu_mega_regul = this.company.ciagnikTypuMegaReagul.toString();
    }
  } 
  toggleMore_ciagnik_trojosiowy_typu_mega_regul() { 
    if (this.step + this.company.ciagnikTypuMegaReagul <= this.max) {
      this.company.ciagnikTypuMegaReagul = this.company.ciagnikTypuMegaReagul + this.step;
      this.renderedValue_ciagnik_typu_mega_regul = this.company.ciagnikTypuMegaReagul.toString()  ;
    }
  }  
}
