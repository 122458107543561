
<h4 style="margin-top:130px; "> </h4> 
<hr>
<div class="row">
    <div class="col-sm-4">

    </div>
<div class="col-sm-4" style="text-align: center; ">
    <h4  style="text-align: center; margin-top: 10px;  "   class="text-center text-primary">Lista Przewoźników</h4> 

</div>


<div class="col-sm-4" style="text-align: center; ">
    <button style="text-align: center;  width: 180px; margin-top: 8px;"   (click)="register()" class="btn btn-success" type="submit">Nowy Przewoźnik</button> 

</div>

</div>

      <br>
   <ejs-grid   #Grid [dataSource]='companys$ | async' [editSettings]='editSettings' [toolbar]='toolbar'
  (actionComplete)="actionComplete($event)"   [childGrid]='childGrid' (rowDataBound)="rowDataBound($event)" 
   [allowFiltering]='true' [pageSettings]='pageSettings' [allowPaging]="true" [filterSettings]='filterOptions' 
   [allowSorting]="true"   [allowPdfExport]="true"     (actionBegin)='actionBegin($event)'
   >
<!--<ejs-grid #grid [dataSource]='data' [allowPaging]='true' [allowFiltering]='true' [allowSelection]='true'
   [selectionSettings]='selectionOptions' [editSettings]='editSettings' [toolbar]='toolbar' [pageSettings]='pageOptions' 
   [filterSettings]='filterOptions' (rowDataBound)='rowDataBound($event)'   (actionComplete)="actionComplete($event)">-->
        <e-columns>
            <e-column [isPrimaryKey]='true' field='companyId' headerText='Id' textAlign='Left' width=90></e-column>

            <e-column  [valueAccessor]='valueAccess'   field='datarejstracji' headerText='Data rejestracji' textAlign='Right'  width=122></e-column> 
            <e-column field='nip' headerText='Nip' textAlign='Center' width=140></e-column> 
     
         <e-column field='nazwa' headerText='Nazwa Przewoźnika' textAlign='Left' headerTextAlign="Center"  width=450></e-column>  
         <e-column field='miejscowosc' headerText='      Miejscowość' textAlign='Left'headerTextAlign="Center"   width=170></e-column>  
         <e-column field='kodPocztowy' headerText='Kod pocztowy' textAlign='Left'  textAlign='Left'headerTextAlign="Center"   width=130></e-column>  
         <e-column field='ulica' headerText='Ulica' textAlign='Left'   textAlign='Left'headerTextAlign="Center"  width=190></e-column>  
         <e-column field='nrNieruchomosci' headerText='Nr domu' textAlign='Left'   headerTextAlign="Center" width=110></e-column>  
         <e-column field='nrLokalu' headerText='Lokal' textAlign='Left'   headerTextAlign="Center"width=90></e-column>  
         <e-column field='accepted' editType= 'booleanedit' displayAsCheckBox="true" headerText='Aktywny'  headerTextAlign="Center"    width=90 ></e-column> 

         <e-column field='emailConfirmed' headerText='Weryfikacja e-mail' textAlign='Center' width=150  editType= 'booleanedit' displayAsCheckBox="true"></e-column>

        <e-column field='userId' headerText='userId' textAlign='Left' width=70></e-column> 
  
       

        <e-column field='idKontrah' headerText='Id Speed' textAlign='Right' width=100></e-column>
       
       
        </e-columns>

        <ng-template #editSettingsTemplate let-data>
        <div style="  height:630px;"  ngForm #orderForm="ngForm">

        

      
     <div class="form-row" style="margin-top: 18px !important;" >  
          <div class="form-group col-6">
            <div class="row">
              <div class="col-12">
                  <ejs-checkbox label="Zatwierdzony" (change)="changeAccepted($event)" [checked]="orderData.accepted"></ejs-checkbox>  
              </div>
            </div>
            <div class="row"  style="margin-top: 18px !important;" >  
              <div class="col-12">
                <ejs-checkbox style="margin-bottom:10px; margin: top 8px !important;"  label="Email potwierdzony" (change)="changeEmail($event)" [checked]="orderData.emailConfirmed"></ejs-checkbox >  
              </div>
            </div>
          </div>

          <div class="form-group col-6"> 
                <div class="e-float-input e-control-wrapper"  >
                    <input style="font-weight: bold; font-size: 15px;;" [(ngModel)]="orderData.idKontrah"   id="idKontrahid" name="idKontrahid" type="text">
                    <span class="e-float-line"></span>
                    <label class="e-float-text e-label-top" for="nipId">Id Kontrahenta</label>
                </div>
          </div>
      </div> 

 
 
  
      <div class="form-row">
         <div class="col-6">
            <h5 style="color: rgba(0, 160, 227)" class="e-float-text e-label-top" for="CustomerID">Giełda</h5> 
         </div>
         <div class="col-6"> 
          <h5 style="color: rgba(0, 160, 227)" class="e-float-text e-label-top" for="CustomerID">Speed</h5> 
         </div> 
      </div>



         <div class="form-row">
             <div class="col-6">
                 <div class="e-float-input e-control-wrapper"  >
                     <input [(ngModel)]="orderData.nip"  style="background-color: #d3d3d342;"  readonly  id="nipId" name="nipId" type="text">
                     <span class="e-float-line"></span>
                     <label class="e-float-text e-label-top" for="nipId">NIP</label>
                 </div>
                 
             </div>
             <div class="col-6">
                 <div class="e-float-input e-control-wrapper" >
                     <input [(ngModel)]="companyMaszon.nip" style="background-color: #d3d3d342;"  readonly id="MaszonNip" name="MaszonNip" type="text" #CustomerID="ngModel">
                     <span class="e-float-line"></span>
                     <label class="e-float-text e-label-top" for="MaszonNip">NIP</label>
                 </div> 
             </div>
         </div>

         <div class="form-row">
             

            <div class="col-6">
                <div class="e-float-input e-control-wrapper" >
                    <textarea style='resize: none;' class='form-control' rows="3"   id="nazwaIdMaszon" name="nazwaIdMaszon"
                     [(ngModel)]="orderData.nazwa"></textarea> 
                    <span class="e-float-line"></span>
                    <label class="e-float-text e-label-top" for="MaszonNazwa">Nazwa firmy</label>
                </div> 
            </div>
            <div class="col-6">
                <div class="e-float-input e-control-wrapper" >
                    <textarea style='resize: none;' class='form-control' style="background-color: #d3d3d342;"  readonly rows="3" id="nazwaId" name="nazwaId"
                     [(ngModel)]="companyMaszon.nazwa"></textarea>

                    <span class="e-float-line"></span>
                    <label class="e-float-text e-label-top" for="MaszonNazwa">Nazwa firmy</label>
                </div> 
            </div>
        </div>


        <div class="form-row">
         <div class="col-6">
             <div class="e-float-input e-control-wrapper"  >
                 <input [(ngModel)]="orderData.miejscowosc"     id="miejscowoscId" name="miejscowoscId" type="miejscowoscId"  
                 >
                 <span class="e-float-line"></span>
                 <label class="e-float-text e-label-top" for="miejscowoscId">Miejscowość</label>
             </div>
             
         </div>
         <div class="col-6">
             <div class="e-float-input e-control-wrapper" >
                 <input [(ngModel)]="companyMaszon.miejscowosc"  style="background-color: #d3d3d342;"  readonly id="Maszonmiejsc" name="Maszonmiejsc" type="text" #Maszonmiejsc="ngModel">
                 <span class="e-float-line"></span>
                 <label class="e-float-text e-label-top" for="Maszonmiejsc">Miejscowość</label>
             </div> 
         </div>
     </div>

     <div class="form-row">
      <div class="col-6">
          <div class="e-float-input e-control-wrapper"  >
              <input [(ngModel)]="orderData.kodPocztowy"     id="kodPocztowyId" name="kodPocztowyId" type="kodPocztowyId"  
              >
              <span class="e-float-line"></span>
              <label class="e-float-text e-label-top" for="kodPocztowyId">Kod pocztowy</label>
          </div>
          
      </div>
      <div class="col-6">
          <div class="e-float-input e-control-wrapper" >
              <input [(ngModel)]="companyMaszon.kodPocztowy" style="background-color: #d3d3d342;"  readonly id="MaszonMiejscowosc" name="MaszonkodPocztowy" type="text" #MaszonkodPocztowy="ngModel">
              <span class="e-float-line"></span>
              <label class="e-float-text e-label-top" for="MaszonkodPocztowy">Kod pocztowy</label>
          </div> 
      </div>
  </div>


   <div class="form-row">
      <div class="col-6">
          <div class="e-float-input e-control-wrapper"  >
              <input [(ngModel)]="orderData.ulica"     id="ulicaId" name="ulicaId" type="ulicaId"  
              >
              <span class="e-float-line"></span>
              <label class="e-float-text e-label-top" for="ulicaId">Ulica</label>
          </div>

      </div>
      <div class="col-6">
          <div class="e-float-input e-control-wrapper" >
              <input [(ngModel)]="companyMaszon.ulica" style="background-color: #d3d3d342;"  readonly id="MaszonUlica" name="MaszonUlica" type="text" #MaszonUlica="ngModel">
              <span class="e-float-line"></span>
              <label class="e-float-text e-label-top" for="MaszonUlica">Ulica</label>
          </div> 
      </div>
   </div>


   <div class="form-row">
      <div class="col-6">
          <div class="e-float-input e-control-wrapper"  >
              <input [(ngModel)]="orderData.nrNieruchomosci"   id="nrNieruchomosciId" name="nrNieruchomosciId" type="text"  
              >
              <span class="e-float-line"></span>
              <label class="e-float-text e-label-top" for="ulicaId">Nr nieruchomości</label>
          </div>

      </div>
      <div class="col-6">
          <div class="e-float-input e-control-wrapper" >
              <input [(ngModel)]="companyMaszon.nrNieruchomosci" style="background-color: #d3d3d342;"  readonly id="MaszonNrNieruchomosci" name="MaszonNrNieruchomosci" type="text" #MaszonNrNieruchomosci="ngModel">
              <span class="e-float-line"></span>
              <label class="e-float-text e-label-top" for="MaszonNrNieruchomosci">Nr nieruchomości</label>
          </div> 
      </div>
   </div>

   <div class="form-row">
      <div class="col-6">
          <div class="e-float-input e-control-wrapper"  >
              <input [(ngModel)]="orderData.nrLokalu"   id="nrLokaluId" name="nrLokaluId" type="text"  
              >
              <span class="e-float-line"></span>
              <label class="e-float-text e-label-top" for="nrLokaluId">Nr lokalu</label>
          </div>

      </div>
      <div class="col-6">
          <div class="e-float-input e-control-wrapper" >
              <input [(ngModel)]="companyMaszon.nrLokalu" style="background-color: #d3d3d342;"  readonly id="MaszonNrLokaluId" name="MaszonNrLokaluId" type="text" #MaszonNrNieruchomosci="ngModel">
              <span class="e-float-line"></span>
              <label class="e-float-text e-label-top" for="MaszonNrLokaluId">Nr lokalu</label>
          </div> 
      </div>
   </div>


   <div class="form-row">
      <div class="col-6">
          <div class="e-float-input e-control-wrapper"  >
              <input [(ngModel)]="orderData.users[0].email" style="background-color: #d3d3d342;" readonly id="emailId" name="emailId" type="text"  
              >
              <span class="e-float-line"></span>
              <label class="e-float-text e-label-top" for="emailId">E-mail</label>
          </div>

      </div>
      <div class="col-6">
          <div class="e-float-input e-control-wrapper" >
              <input [(ngModel)]="companyMaszon.users[0].email" style="background-color: #d3d3d342;"  readonly id="MaszonEmail" name="MaszonEmail" type="text" #MaszonNrNieruchomosci="ngModel">
              <span class="e-float-line"></span>
              <label class="e-float-text e-label-top" for="MaszonEmail">E-mail</label>
          </div> 
      </div>
   </div>


   <div class="form-row">
    <div class="col-2">
        <div class="e-float-input e-control-wrapper"  >
            <input [(ngModel)]="orderData.users[0].mobilePrefix" style="background-color: #d3d3d342;"  readonly id="mobilePrefixId" name="mobilePrefixId" type="text"  
            >
            <span class="e-float-line"></span>
            <label class="e-float-text e-label-top" for="mobilePrefixId">Prefix</label>
        </div> 
    </div>
      <div class="col-4">
          <div class="e-float-input e-control-wrapper"  >
              <input [(ngModel)]="orderData.users[0].mobile" style="background-color: #d3d3d342;"  readonly id="mobileId" name="mobileId" type="text"  
              >
              <span class="e-float-line"></span>
              <label class="e-float-text e-label-top" for="mobileId">Nr telefonu</label>
          </div> 
      </div>
      <div class="col-6">
          <div class="e-float-input e-control-wrapper" >
              <input [(ngModel)]="companyMaszon.users[0].mobile" style="background-color: #d3d3d342;"  readonly id="MaszonMobile" name="MaszonMobile" type="text" #MaszonNrNieruchomosci="ngModel">
              <span class="e-float-line"></span>
              <label class="e-float-text e-label-top" for="MaszonMobile">Nr telefonu</label>
          </div> 
      </div>
   </div>
 
 


      

     
     </div> 
 </ng-template>
    </ejs-grid> 



 


      