import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as $ from 'jquery';
window["jQuery"] = $;
window["$"] = $;
import 'jsrender';
import '../node_modules/syncfusion-javascript/Content/ej/web/material/ej.web.all.min.css';
import { registerLicense } from '@syncfusion/ej2-base';

 registerLicense('ORg4AjUWIQA/Gnt2VVhkQlFac1tJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkxiXH9YdXZVQmlVU0E=');

if (environment.production) {
  enableProdMode();
  
}

 


let onDeviceReady = () => {
  platformBrowserDynamic().bootstrapModule(AppModule);
};




document.addEventListener('deviceready', onDeviceReady, false)



