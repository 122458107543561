import { Injectable, APP_INITIALIZER, OnInit } from '@angular/core';  
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as rx from 'rxjs/Rx'
import { Observable } from 'rxjs/internal/Observable';
 

@Injectable()
export class ConfigService implements OnInit {
    ip4: Object;

    async SetApi() { 

             
      // await this.getIPAddress().then(ref=>{

       //  this.ip4 = ref;
        
         
    
            this._env = 'development';
            
            if (environment.production)
                this._env = 'production'; 
    
          //  if(this.IsKurwaaszon())      
        //        this._env = 'maszon'; 


                console.log(this._env)
    
                return  this._env;
    

      // }) 
    }
  

    private _config: Object
    private _env: string;
    private ip: string;
    ip$: rx.Observable<Object>;

    constructor(private _http: HttpClient) {}



    ngOnInit(): void {
  

    }



    async load() {  


        await  this.SetApi().then(ref=>{

           

        return new Promise((resolve, reject) => { 
            this._http.get('/assets/config/' + this._env + '.json')
         
                .subscribe((data) => {
                    this._config = data;
                    resolve(true);
                },
                
                (error: any) => {
           
                    return Observable.throw(error.json().error || 'Server error');
                });
        });

    });

    }

    // Is app in the development mode?
    isDevmode() {
        return this._env === 'development';
    }
    // Gets API route based on the provided key
    getApi(key: string): string {
        return this._config["API_ENDPOINTS"][key];
    }
    // Gets a value of specified property in the configuration file
    get(key: any) {
        return this._config[key];
    }

   public async getIPAddress()  
   {  
    return await this._http.get("https://api.ipify.org/?format=json").toPromise();  
   }
   

   IsKurwaaszon() {

    let ip =  this.ip4["ip"].toString();
    return  ip  === "213.76.166.34";
  }
    
}

export   function ConfigFactory(config: ConfigService) {
 
    return () => config.load(); 
}

export function init() {
    return {
        provide: APP_INITIALIZER,
        useFactory: ConfigFactory,
        deps: [ConfigService],
        multi: true
    }
}

const ConfigModule = {
    init: init
}



export { ConfigModule };