import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account.service';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.css']
})
export class RestorePasswordComponent implements OnInit {
  passwordContains: boolean;
  passwordConfirm: boolean;
 
  ec:string
  user:User;
  constructor(private activatedRoute: ActivatedRoute, private accountService: AccountService, private toastr:ToastrService, private router: Router) { 

    this.activatedRoute.queryParams.subscribe(params => {
      this.ec = params['ec'];
    
  });
}

  ngOnInit(): void {

    this.user = {username: "",
      token: "",
      isAdmin:false,
      userId:0,
      companyId:0,
      isUserAdmin:false ,
      transportStatus: new Date,
      }
  }

  passwordRemaind()
  {

    this.user.token = this.ec;
    let res=  this.accountService.postnewpassword(this.user).subscribe(response => {
      this.toastr.success("Nowe hasło zostało utworzone")
      setTimeout(() => { this.router.navigate(['']);  }, 3000);  //5s
     }, error => {
       this.toastr.error("Nie udało się ustawić nowego hasła")
     }); 
  }


  saverange(newValue, area) {
     
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
   
   
    if(area == "pass1")
    {
   
           if(newValue.length > 0 && newValue != "password")
           { 
             if(format.test(newValue)){
               this.passwordContains = false;
             } else {
             this.passwordContains = true;
             }
           } 
    }
   
    if(area == "pass2")
    {
   
           if(this.user.username != newValue)
           { 
             this.passwordConfirm=true;
           } 
           else 
           {
            this.passwordConfirm=false;
           }
    }
   }

}
