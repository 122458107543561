<div style="margin-top:130px;"></div>
<ng-container >

    <button (click)="get500Error()" class="btn btn-outline-primary mr-3">Test 500 Error</button>
    <button (click)="get400Error()" class="btn btn-outline-primary mr-3">Test 400 Error</button>
    <button (click)="get401Error()" class="btn btn-outline-primary mr-3">Test 401 Error</button>
    <button (click)="get404Error()" class="btn btn-outline-primary mr-3">Test 404 Error</button>
    <button (click)="get400ValidationError()" class="btn btn-outline-primary mr-3">Test 400 Test 500 Validation Error</button>

    <div class="row mt-5" *ngIf="validationErrors.length > 0">
<ul class="text-danger">
    <li *ngFor="let error of validationErrors">
        {{error}}
    </li>
</ul>
    </div>
</ng-container>

 