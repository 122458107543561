import { UserRegister } from "./userRegister";

export interface placeOrder {
    orderId:number,
            price: number;
            comment: string;
            username: string;
            userId: Number;
            zlecenieId: number;
            user:UserRegister;
            first: string;
            last: string;
            shoes: string;
            mobilePrefix: string;
            truck: string;
            trailer: string;
            datA_RZ_P: Date;
            datA_RZ: Date;
            datA_RZ_K: Date;
            datA_RZ_W: Date;
            lokalizacja: string;
            spanRow: number;
            status : string;
            typ: string;
            zaakceptowane: boolean;
            rejected: boolean;
            offerdate: Date;
            waluta: string;
            cena: number;
            iD_ZLECENIA_ZA_WY: number;
            qtyDate: number;
            spedytor: string;
            spedytorEmail: string;
            spedytorTel: string;
            kupTeraz: number;
            firma: string;
            oferowanaCena: number;
            isAttachment: boolean;
            skonto: boolean;
            expanded : expandedRow[]; 
            data_: Date;
            data_WY: Date;
            data_Godz: string; 
            data_Godz_WY: string; 
            data_Godz_Od : string;   
            data_Godz_Do: string; 
            userName: string; 
            userEmail: string;
            userKom: string; 
            trasa: string; 
            isNegotiation: number;
            przewoznik: string;
            komentarz: string;
}


export interface placedOrder {
    rejected: boolean;
    orderId: number;
    zlecenieId: number;
    przewoznik: string;
    oferowanaCena: number;
    komentarz: string;
    offerdate: string;
    offertime: string;
    cena: number;
    kupTeraz: number;
    waluta: string;
    zaakceptowane: Boolean;
    userId: Number;
    
}


export class  expandedRow {
    datA_RZ_P: Date;
    datA_RZ: Date;
    datA_RZ_K: Date;
    datA_RZ_W: Date; 
    lokalizacja: string; 
    status : string;
    typ : string;
    qtyDate: number;
    iD_ZLECENIA_ZA_WY: number;
    data_: string;
    data_Godz: string;
  data_Godz_Od: string;
  data_Godz_Do: string;
}

 