
 
<div style="margin-top:130px;"></div>
<div class="control-container"  >  
  <form id="formId" #registerForm="ngForm"     novalidate="">
    <ejs-carousel [selectedIndex]="selectedIndex" [autoPlay]="false" [showIndicators]="false" [scrollLeft]="false"  [draggable]="false"
    [buttonsVisibility]="true" [enableTouchSwipe]="false"  [loop]="false"
    
    >
      <e-carousel-items >
    
        <e-carousel-item  >
          <ng-template #template>
            <figure class="img-container">
           
                     
                        <h4 class="text-center text-primary">Rejestracja przewoźnika</h4> <h5 style="text-align:center;">Dane firmy (1/3)</h5>
            <hr>
                <div class="control_wrapper" id="control_wrapper" style="margin: 25px auto;">
                  
                            <div class="form-group template">
                                <div class="e-float-input" >
                                    <input    (input)="CheckIsValid()" style="font-size: 22px;"  type="text" id="nip"  [ngModelOptions]="{standalone: true}"   [(ngModel)]="company.nip"   title="Pod swój nr NIP, sprawdzimy czy Twój numer juz istnieje w naszej bazie." required>
                                   
                                    <label class="e-float-text"  for="nip">Podaj NIP</label>                        
                                </div>
                                <span class="e-error" *ngIf="nip.errors && nip.errors.required && (nip.dirty || nip.touched)" >
                                    * Podaj swój nip
                                </span>  
                            </div>

                            


                            <div *ngIf="isShown">
                            <div class="form-group template">
                                <div class="e-float-input">
                                    <input (input)="CheckIsValid()" type="text" id="nazwa" [ngModelOptions]="{standalone: true}" #nazwa='ngModel' [(ngModel)]="company.nazwa"  required>
                                   
                                    <label class="e-float-text" [ngClass]="{'e-label-top': company.nazwa !== '' , 'e-label-bottom':  company.nazwa == ''}" for="nazwa">Nazwa</label>                        
                                </div>
                                <span class="e-error" *ngIf="nazwa.errors && nazwa.errors.required && (nazwa.dirty || nazwa.touched)" danger padding-left>
                                    * Podaj nazwę firmy
                                </span>
                            </div>
                            
            
                            <div class="form-group template">
                                <div class="e-float-input"  >
                                    <input (input)="CheckIsValid()" type="text" id="kodPocztowy" [ngModelOptions]="{standalone: true}"   [(ngModel)]="company.kodPocztowy" #kodPocztowy="ngModel" minlength="6" required >
                                   
                                    <label class="e-float-text" [ngClass]="{'e-label-top': company.kodPocztowy !== '' , 'e-label-bottom':  company.kodPocztowy == ''}" for="kodPocztowy">Kod pocztowy</label>                            
                                </div>
                                <span class="e-error" *ngIf="kodPocztowy.errors && kodPocztowy.errors.required && (kodPocztowy.dirty || kodPocztowy.touched)" >
                                    * Podaj kod pocztowy
                                </span>
                                <span  class="e-error" *ngIf="kodPocztowy.errors && kodPocztowy.errors.minlength" >
                                    * Podaj kod pocztowy
                                </span>
                            </div>
            
            
                            <div class="form-group template">
                                <div class="e-float-input" >
                                    <input (input)="CheckIsValid()" type="text" id="ulica"    [ngModelOptions]="{standalone: true}" [(ngModel)]="company.ulica" #ulica="ngModel" minlength="6" required >
                                   
                                    <label class="e-float-text" [ngClass]="{'e-label-top': company.ulica !== '' , 'e-label-bottom':  company.ulica == ''}" for="ulica">Ulica</label>                            
                                </div>
                               
                            </div>
                            <div class="form-group template">
                                <div class="e-float-input" >
                                    <input (input)="CheckIsValid()" type="text" id="nrNieruchomosci" [ngModelOptions]="{standalone: true}"  [(ngModel)]="company.nrNieruchomosci" #nrNieruchomosci="ngModel" minlength="1" required >
                                   
                                    <label class="e-float-text" [ngClass]="{'e-label-top': company.nrNieruchomosci !== '' , 'e-label-bottom':  company.nrNieruchomosci == ''}" for="nrNieruchomosci">Nr nieruchomosci</label>                            
                                </div>
                                <span class="e-error" *ngIf="nrNieruchomosci.errors && nrNieruchomosci.errors.required && (nrNieruchomosci.dirty || nrNieruchomosci.touched)" >
                                    *  Podaj numer budynku
                                </span>
                                <span  class="e-error" *ngIf="nrNieruchomosci.errors && nrNieruchomosci.errors.minlength" >
                                    * Podaj numer budynku
                                </span>
                            </div>
            
                            <div class="form-group template">
                                <div class="e-float-input">
                                    <input type="text" id="nrLokalu" [ngModelOptions]="{standalone: true}" [(ngModel)]="company.nrLokalu" #nrLokalu="ngModel" minlength="1"   >
                                   
                                    <label class="e-float-text" [ngClass]="{'e-label-top': company.nrLokalu !== '' , 'e-label-bottom':  company.nrLokalu == ''}" for="nrLokalu">Nr lokalu</label>                            
                                </div>
                               
                            </div>
             
            
                                        
                          
                            <input  [required]="true" (change)="AcceptedCondition()" [ngModelOptions]="{standalone: true}"   style="  margin-left:5px;" class="form-check-input" type="checkbox" value="" id="defaultCheck1" required>
                                     <div class="desc" style="width: 95%; margin-left:18px;"><p > * Akceptacja warunków
                                      <a href="/regulations"  target="_blank">(regulamin)</a>
                                      <span  class="e-error" *ngIf="!myVar1" >
                                        * Musisz zaakceptować warunki 
                                    </span>

                                     </p>  </div>                     
                                   
                               
                                       

                            </div>    

                          
                            <div class="form-row">
                              <div class="col">
                                <button   class="btn btn-success mr-2" type="submit"
                                style="padding:5px;margin-bottom:20px; height: 40px;   width: 200px; "  (click)="getCompanyByNip()" type="submit">Pobierz dane z GUS</button> 
                              </div>
                              <div class="col">
                                <button *ngIf="isShown" style="  float: right;  " class="btn btn-success mr-2" (click)="nextBtnClick()" type="submit"
                                [disabled]="firstPageButton && !myVar1" (Mouseover)="CheckIsValid()"
                                >Dalej ></button> 
                              </div>
                          </div> 

                            
                </div>
 
            
            </figure>
          </ng-template>
        </e-carousel-item>
     
		
        <e-carousel-item>
          <ng-template #template>
            <figure class="img-container">

                <h4 class="text-center text-primary">Rejestracja przewoźnika</h4><h5 style="text-align:center;">Dane kontaktowe (2/3)</h5>
                <hr>
                <div class="control_wrapper" id="control_wrapper" style="margin: 25px auto;">

                  <div class="form-group template">
                    <div id="userName-no" class="e-float-input" [ngClass]="{'e-success': userName.valid}">
                        <input (ngModelChange)="saverange($event, 'userName')"  type="text" id="UserNameno" name="userName" [(ngModel)]="userRegisters[0].userName" #userName="ngModel" minlength="3" required  >
                       
                        <label class="e-float-text" [ngClass]="{'e-label-top': company.userName !== '' , 'e-label-bottom':  userRegisters[0].userName == ''}" for="name">Login</label>                                                        
                    </div>
                    <span class="e-error" *ngIf="userName.errors && userName.errors.required && (userName.dirty || userName.touched)" >
                        * Podaj swój Login
                    </span>
                    <span  class="e-error" *ngIf="userName.errors && userName.errors.pattern && (userName.dirty || userName.touched)" >
                        * Podaj swój Login
                    </span>
                </div>



                <div class="form-group template">
                  <div class="e-float-input" [ngClass]="{'e-success': password.valid}">
                      <input  (ngModelChange)="saverange($event, 'pass1')"  (change)="onPasswordChange()" autocomplete="off" type="password" id="password" name="password" [(ngModel)]="userRegisters[0].password" #password="ngModel" minlength="6" required >
                     
                      <label class="e-float-text" [ngClass]="{'e-label-top': userRegisters[0].password !== '' , 'e-label-bottom':  userRegisters[0].password == ''}" for="password">Hasło</label>                                        
                  </div>
 
                  <span  class="e-error" *ngIf="passwordContains" >
                      * Hasło musi mieć conajmniej 6 znaków  i zawierać jeden znak specjalny 
                  </span>
              </div>


           

              <div class="form-group template">
                <div class="e-float-input" >
                    <input  (ngModelChange)="saverange($event, 'pass2')"    type="password" id="password" autocomplete="off" name="miejscowoscPoczty" [(ngModel)]="company.miejscowoscPoczty" #password="ngModel" minlength="6" required >
                   
                    <label class="e-float-text" [ngClass]="{'e-label-top': company.miejscowoscPoczty !== '' , 'e-label-bottom':  company.miejscowoscPoczty == ''}" for="miejscowoscPoczty">Powtórz hasło</label>                                        
                </div>

                <span  class="e-error" *ngIf="passwordConfirm" >
                    * Hasła muszą być takie same!
                </span>
            </div>
           
              
			  
                
                <div class="form-group row"  >
          

                 <div class="form-group template">
                  <div id="mobile-no" class="e-float-input" [ngClass]="{'e-success': mobile.valid}">
                      <input (ngModelChange)="saverange($event,  'mobile')"  type="text" id="mobileno" name="mobile" [(ngModel)]="userRegisters[0].mobile" #mobile="ngModel" minlength="7" required pattern="[- +()0-9]+">
                     
                      <label class="e-float-text" [ngClass]="{'e-label-top': userRegisters[0].mobile !== '' , 'e-label-bottom':  userRegisters[0].mobile == ''}" for="mobile">Telefon</label>                                                        
                  </div>
                  <span class="e-error" *ngIf="mobile.errors && mobile.errors.required && (mobile.dirty || mobile.touched)" >
                    * Wprowadź poprawny numer telefonu
                  </span>
                  <span  class="e-error" *ngIf="mobile.errors && mobile.errors.pattern && (mobile.dirty || mobile.touched)" >
                      * Wprowadź poprawny numer telefonu
                  </span>
              </div>
                
                  </div>

                    <div class="form-group template">
                        <div id="mobile-no" class="e-float-input" [ngClass]="{'e-success': email.valid}">
                            <input  (ngModelChange)="saverange($event,  'email')"  type="text" id="email" name="email" [(ngModel)]="userRegisters[0].email" #email="ngModel" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                           
                            <label class="e-float-text" [ngClass]="{'e-label-top': userRegisters[0].email !== '' , 'e-label-bottom':  userRegisters[0].email == ''}" for="name">E-mail</label>                                                
                        </div>
                        <span class="e-error" *ngIf="email.errors && email.errors.required && (email.dirty || email.touched)" >
                              * Wprowadź poprawny adres e-mail
                        </span>
                        <span  class="e-error" *ngIf="email.errors && email.errors.pattern && (email.dirty || email.touched)" >
                          * Wprowadź poprawny adres e-mail
                        </span>
                    </div>

         
                


                <div class="form-row">
                  <div class="col">
                    <button *ngIf="isShown" style="  float: left; margin-top: 30px; margin-bottom: 30px;"   class="btn btn-light" (click)="prevBtnClick()"> Cofnij</button>

                  </div>
                  <div class="col">
                    <button *ngIf="isShown" style="  float: right; margin-top: 30px; margin-bottom: 30px;"   class="btn btn-success mr-2" (click)="nextBtnClick()" type="submit"
                    [disabled]="!secondPageButton"   
                    >Dalej ></button> 
                  </div>
              </div>  

            </div>
             <!--  <figcaption style="background-color:#334c48; margin-top: 140px;  ;" class="img-caption">Dane Kontaktowe</figcaption>-->
            </figure>
          </ng-template>
        </e-carousel-item>


        <e-carousel-item>
          <ng-template #template>
           
 

                       
 
 
                      <figure class="img-container" style="margin-top: 30px;">

                        <h4 class="text-center text-primary">Rejestracja przewoźnika</h4><h5 style="text-align:center;">Flota (3/3)</h5>
                        <hr>
                        <div  class="control_wrapper" id="control_wrapper" style="margin: 25px auto;">  
                            <div   class="container">


                              <div class="row" style="margin-top: 25px;">
                                <div class="col" style="width:80px;  padding-left:0">                          
                                  <img  width="105" height="35" src="/assets/images/zestaw_niesym.png?" >                         
                                </div>
                                <div class="col-5" style="width: 145px;">                        
                                       <h4 style="  text-align: center; font-size: 15px;color:#696c77;">JUMBO 7,7+7,7</h4>   <!--Ciągnik trójosiowy-->
                                 </div>
                                 <div class="col" style="float:right; padding-right: 1px; padding-left: 1px;">   
                                  <div class="stepper-input stepper__input">
                                          <button class="toggle-less"  (click)="toggleLess_ciagnik_trojosiowy()">
                                            <img src="https://angular-stepper-input.stackblitz.io/assets/minus.svg" [attr.alt]="ariaLabelLess" />
                                          </button>
                                          <div class="stepper-input-field"  aria-atomic="true" aria-live="polite" aria-relevant="all">
                                             {{renderedValue_ciagnik_trojosiowy}}  
                                          </div>
                                          <button class="toggle-more" (click)="toggleMore_ciagnik_trojosiowy()">
                                            <img src="https://angular-stepper-input.stackblitz.io/assets/plus.svg" [attr.alt]="ariaLabelMore" />
                                          </button>
                                      </div> 
                                  </div>
                              </div> 



                              <div class="row" style="margin-top: 25px;">
                                <div class="col" style="width:80px;  padding-left:0">                        
                                  <img  width="105" height="35" src="/assets/images/mega.png?" >
                                  </div>
                                  <div class="col-5" style="width: 145px;">  
                                        <h4 style=" text-align: center; font-size: 15px; color:#696c77;">JUMBO 8+7 (38 EUROPALET)</h4>   <!--Ciągnik typu mega-->                            
                                  </div>
                                  <div class="col" style="float:right; padding-right: 1px; padding-left: 1px;">   
                                        <div class="stepper-input stepper__input">
                                            <button class="toggle-less"  (click)="toggltoggleLess_ciagnik_trojosiowy_typu_mega()">
                                              <img src="https://angular-stepper-input.stackblitz.io/assets/minus.svg" [attr.alt]="ariaLabelLess" />
                                            </button>
                                            <div class="stepper-input-field"  aria-atomic="true" aria-live="polite" aria-relevant="all">
                                               {{renderedValue_ciagnik_typu_mega}}  
                                            </div>
                                            <button class="toggle-more" (click)="toggleMore_ciagnik_trojosiowy_typu_mega()">
                                              <img src="https://angular-stepper-input.stackblitz.io/assets/plus.svg" [attr.alt]="ariaLabelMore" />
                                            </button>
                                        </div> 
                                    </div>
                              </div>



                              <div class="row" style="margin-top: 25px; margin-bottom: 30px;">
                                <div class="col" style="width:80px;  padding-left:0">   
                                  <img  width="105" height="35" src="/assets/images/mega_standard.png?" >
                                </div>
                                <div class="col-5" style="width: 145px;">     
                                         <h4 style=" text-align: center; font-size: 15px; color:#696c77;">JUMBO 9,1+6,3</h4>  <!--Ciągnik typu mega</h4><p>regul. wysokość sidła)-->
                                     </div>
                                     <div class="col" style="float:right; padding-right: 1px; padding-left: 1px;">   
                                      <div class="stepper-input stepper__input">
                                            <button class="toggle-less"  (click)="toggltoggleLess_ciagnik_trojosiowy_typu_mega_regul()">
                                              <img src="https://angular-stepper-input.stackblitz.io/assets/minus.svg" [attr.alt]="ariaLabelLess" />
                                            </button>
                                            <div class="stepper-input-field"  aria-atomic="true" aria-live="polite" aria-relevant="all">
                                               {{renderedValue_ciagnik_typu_mega_regul}}  
                                            </div>
                                            <button class="toggle-more" (click)="toggleMore_ciagnik_trojosiowy_typu_mega_regul()">
                                              <img src="https://angular-stepper-input.stackblitz.io/assets/plus.svg" [attr.alt]="ariaLabelMore" />
                                            </button>
                                        </div> 
                                    </div>
                               </div>



                              <div class="row" style="margin-top: 25px;">
                  
                                <div class="col" style="width:80px; padding-left:0">                            
                                  <img    width="105" height="35" src="/assets/images/zestaw_sym.png?" >                         
                                </div>  
                                <div class="col-5" style="width: 145px;">     
                                  <h4 style=" text-align: center; font-size: 15px; color:#696c77;">STANDARD</h4>  <!--Ciągnik typu mega</h4><p>regul. wysokość sidła)-->
                              </div>
                              <div class="col" style="float:right; padding-right: 1px; padding-left: 1px;">   
                                <div class="stepper-input stepper__input">
                                            <button class="toggle-less"  (click)="toggleLess_ciagnik_siodlowy()">
                                              <img src="https://angular-stepper-input.stackblitz.io/assets/minus.svg" [attr.alt]="ariaLabelLess" />
                                            </button>
                                            <div class="stepper-input-field"  aria-atomic="true" aria-live="polite" aria-relevant="all">
                                               {{renderedValue_ciagnik_siodlowy}}  
                                            </div>
                                            <button class="toggle-more" (click)="toggleMore_ciagnik_siodlowy()">
                                              <img src="https://angular-stepper-input.stackblitz.io/assets/plus.svg" [attr.alt]="ariaLabelMore" />
                                            </button>
                                        </div> 
                                    </div>
                              </div> 
                  
                               
                   

                                        <div class="row" style="margin-top: 25px; margin-bottom: 30px;">
                                          <div class="col" style="width:80px; padding-left:0"> 
                                            <img  width="105" height="35" src="/assets/images/mega_standard.png?" >
                                          </div>
                                          <div class="col-5" style="width: 145px;">     
                                            <h4 style=" text-align: center; font-size: 15px; color:#696c77;">MEGA (3m)</h4>  <!--Ciągnik typu mega</h4><p>regul. wysokość sidła)-->
                                               </div>
                                               <div class="col" style="float:right; padding-right: 1px; padding-left: 1px;">   
                                                <div class="stepper-input stepper__input">
                                                      <button class="toggle-less"  (click)="toggltoggleLess_mega3m()">
                                                        <img src="https://angular-stepper-input.stackblitz.io/assets/minus.svg" [attr.alt]="ariaLabelLess" />
                                                      </button>
                                                      <div class="stepper-input-field"  aria-atomic="true" aria-live="polite" aria-relevant="all">
                                                         {{renderedValue_mega3m}}  
                                                      </div>
                                                      <button class="toggle-more" (click)="toggleMore_mega3m()">
                                                        <img src="https://angular-stepper-input.stackblitz.io/assets/plus.svg" [attr.alt]="ariaLabelMore" />
                                                      </button>
                                                  </div> 
                                              </div> 
                                            </div> 
                   
                                        <div class="row" style="margin-bottom: 20px !important;"> 
                                           
                                          <div class="col-5">
                                            <button *ngIf="isShown" style="float:right; width: 180px;"     class="btn btn-light" (click)="prevBtnClick()">Cofnij</button>
                        
                                          </div>
                                          <div class="col-7" >

                                            <div class="row" style="margin-top: 0px !important;">
                                              <div class="col-12" >
                                                <button    [disabled]="!thirdPageButton"  *ngIf="isShown"  style="float:right;  width: 180px; margin-right: -20px"   class="btn btn-light" (click)="register()" type="submit">Zakończ rejestrację</button> 
                                              </div>
                                               
                                              <div class="col-12" >
                                                <span style="color: #696c77; float:right;"   class="e-error" *ngIf="!thirdPageButton" >
                                                  * Wymagane podanie floty
                                            </span>
                                          </div>
                                              </div>
                                          </div>
                                      </div>  


                                        
                            </div> 
                  
                  
                        </div> 
                  
                     
                    </figure>
                          
                                
                                
                              

                   
            
          </ng-template>
        </e-carousel-item>

        <e-carousel-item>
          <ng-template #template>
            <figure class="img-container">  
               <div style="margin-top: 30px;"  >
          <h1><p style="text-align: center; color:rgba(0, 160, 227);">Dziękujemy za rejestrację!</p></h1>
          <h5 style="text-align: center; color:grey">Na Twój adres e-mail została wysłana prośba o weryfikację konta. Będzie ono aktywne po akceptacji Administratora.</h5> 
        <p class="lead"> </p>
       <!-- <div class="text-center">
                <button (click)="registerToogle()" class="btn btn-primary btn-lg mr-2">Rejestracja</button>
                <button class="btn btn-info btn-lg">Zaloguj</button>
        </div>-->
          </div>

            </figure>
          </ng-template>
        </e-carousel-item>
       
      </e-carousel-items>
    </ejs-carousel>
  </form>
  </div> 