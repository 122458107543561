import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-regulamin',
  templateUrl: './regulamin.component.html',
  styleUrls: ['./regulamin.component.css']
})
export class RegulaminComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
