
<h1 mat-dialog-title>Uzupełnij godziny!</h1>
<mat-dialog-content  > 

    <ejs-grid height="500" #grid [dataSource]='data'   
     (queryCellInfo)="queryCellInfo($event)" 
    (queryCellInfo)='queryCellInfoEvent($event)'
    (dblclick)='recordDoubleClick($event)' 
    > 
        <e-columns>
          <e-column [customAttributes]='customAttributes' field='zlecenieId' headerText='Zlecenie' [disableHtmlEncode]="false" width='90'></e-column> 
          <e-column  
          field="iD_ZLECENIA_ZA_WY"
          headerText="Statusy"
          textAlign="Center"
          width="130"
        >
         <ng-template #template let-data>
            <div >
              <button   content="Uzupełnij"   ejs-button  cssClass="e-flat"   (click)="setStatus($event, data, data.iD_ZLECENIA_ZA_WY)" ></button>
            </div>
          </ng-template>
        </e-column>
          <!--<e-column   field='qtyDate' [valueAccessor]='valueAccessqtyDate' headerText='Postęp'  [disableHtmlEncode]="false" width='80'    ></e-column> 
          <e-column field='datA_RZ_P' headerText='Przyjazd' [valueAccessor]='valueAccessDatadatA_RZ_P' [disableHtmlEncode]="false" width='140' editType= 'datetimepickeredit' [edit]='dpParams'   ></e-column> -->
          <e-column field='datA_RZ_P' headerText='Przyjazd' [valueAccessor]='valueAccessDatadatA_RZ_P'   width='120'   ></e-column> 
          <e-column field='datA_RZ' headerText='Początek' [valueAccessor]='valueAccessDatadatA_RZ'   width='120'   ></e-column> 
          <e-column field='datA_RZ_K' headerText='Koniec' [valueAccessor]='valueAccessDatadatA_RZ_K' [disableHtmlEncode]="false" width='120'    ></e-column> 

          
             <e-column field='typ' headerText='Typ' [disableHtmlEncode]="false" width='70'></e-column> 
            <e-column field='lokalizacja' headerText='Lokalizacja'   width='250'></e-column> 
             <e-column field='data_Godz' [valueAccessor]='valueAccessdata_Godz'  headerText='Data i godzina' [disableHtmlEncode]="false" width='180'></e-column> 
            

 
     
        </e-columns>
        </ejs-grid>
        
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close  (click)="closeDialog()">Zamknij</button>
</mat-dialog-actions>