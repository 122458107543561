import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { company } from '../_models/company';
import { AccountService } from '../_services/account.service';
import { GusService } from '../_services/gus.service';  
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import {  EmitType } from '@syncfusion/ej2-base';
import { Router } from '@angular/router'; 
import { FormValidator, FormValidatorModel } from '@syncfusion/ej2-inputs'; 
import { FormControl, FormGroup, Validators, FormsModule, AbstractControl, FormBuilder } from '@angular/forms';
import { FormValidators } from '@syncfusion/ej2-angular-inputs';
import { UserRegister } from '../_models/userRegister';

@Component({
  selector: 'app-resgister',
  templateUrl: './resgister.component.html',
  styleUrls: ['./resgister.component.css']
})





export class ResgisterComponent implements OnInit {
  userForm: any;
  formBuilder: any;
 
  form: FormGroup = new FormGroup({});


 

  title = "Stepper input";
  @Input() initialValue = 0;
  @Input() step  = 1;
  @Input() min: number = 0;
  @Input() max: number = 10;
  @Input() symbol: string;
  @Input() ariaLabelLess: string;
  @Input() ariaLabelMore: string;

  myVar1 = false;
  renderedValue_ciagnik_siodlowy: string; 
  renderedValue_ciagnik_trojosiowy : string; 
  renderedValue_ciagnik_typu_mega: string;   
  renderedValue_ciagnik_typu_mega_regul: string;  
  renderedValue_value_jumbo_z_przyczepa: string;    
  renderedValue_naczepa_oplandekowana: string;     
  renderedValue_mega_naczepa_oplandekowana: string;  
  renderedValue_mega3m: string; 

  public selectedIndex: number = 0;
  @ViewChild('Dialog')
  public dialogObj: DialogComponent;

  public confirm_password: string ;
  public confirm_password_boolean:   boolean = true; ;

  public width: string = '335px';
  public visible: boolean = false;
  public multiple: boolean = false;
  public showCloseIcon: Boolean = true;
  public formHeader: string = 'Success';
  public content: string = 'You have successfully registered, Thank you.';
  public target: string = '#control_wrapper';
  public isModal: boolean = true;
  public animationSettings: object = {
    effect: 'Zoom'
  };
  public UserDetails: UserDetails;

  @Output() cancelRegister = new EventEmitter();
  model:any={};
  company:company;
  userRegister:UserRegister;
  userRegisters:UserRegister[];
  isShown: boolean = false ; // hidden by default
  isShownDanezGus: boolean = true ; // hidden by default

  firstPageButton: boolean = false ; // hidden by default
  secondPageButton: boolean = false ; // hidden by default
  thirdPageButton: boolean = false ; // hidden by default
passwordMin: boolean = false ;
passwordContains: boolean = false ;
passwordConfirm: boolean = false ;


  reactForm: FormGroup;

  constructor(private accountService: AccountService, private toastr: ToastrService, private gusService: GusService,
     private router: Router, private builder: FormBuilder, private ElByClassName: ElementRef, private fb: FormBuilder) {
 
    this.createForm();

    this.form = fb.group({
   //  password: ['', [Validators.required]],
   //  confirm_password: ['', [Validators.required]]
   //}, { 
   //  validator: ConfirmedValidator('password', 'confirm_password')
    }
    )

   }

   get f(){
    return this.form.controls;
  }
   
  submit(){
    console.log(this.form.value);
  }

  ngOnInit(): void {


 

    let formId: HTMLElement = <HTMLElement>document.getElementById('formId');
   // document.getElementById('formId').addEventListener(
   //   'submit',
   //   (e: Event) => {
   //     e.preventDefault();
   //     if (this.reactForm.valid) {
   //       alert('Customer details added!');
   //       this.reactForm.reset();
   //     }
   //   });
 

   this.userRegisters = [
    {userId: 1, 
      userName: "" ,
      PasswordHash:"",
    PasswordSalt:"" , 
     uzytkownikId: 0,
     usernameDto:null,
    mobile: "",
    mobilePrefix: "",
    email: "",
    idKontrah:0,
    accepted:false,
    datarejstracji: new Date() ,
    isAdmin: false,
    userCompanyId: null,
    UserComapnyCompany: {UserId: 2, nip: "",  nazwa: "",   miejscowosc: "",   ulica: "",  nrNieruchomosci: "",},
    orders: [],
    password: "",
    emailConfirmedToken: "",
    emailConfirmed:false,
    userComapnyCompanyId:0,
    companyId:0,
    isUserAdmin: false 
  },
    
  ];

   
//  this.userRegister = 
//  {UserId: 1, userName: "", uzytkownikId: "",
//  mobile: "",
//  email: "",
//  idKontrah:0,
//  accepted:false,
//  datarejstracji: new Date() ,
//  userCompanyId:0,
//  isAdmin:false,
//  password:"",
//  PasswordHash:"",
//  PasswordSalt:"" } 
//  
// ;

   this.company = {
    companyId:0,
    userId:0,
    kodPocztowy: "",
    nip: "", 
    regon: "",
    statusNip: "",
    nazwa: "",
    wojewodztwo: "",
    powiat: "",
    gmina: "",
    miejscowosc: "", 
    ulica: "",
    nrNieruchomosci: "",
    nrLokalu: "",
    typ: "F",
    silosID: 1,
    dataZakonczeniaDzialalnosci : "2019-01-06T17:16:40",
    miejscowoscPoczty: "", 
    mega3m : 0, 
    ciagnikSiodlowy:  0,
    ciagnikTrojosiowy:  0,
    ciagnikTypuMega:  0,
    ciagnikTypuMegaReagul:  0, 
    idKontrah:0,
    accepted: false, 
    datarejstracji :   new Date(), 
    users:  this.userRegisters, 
    errors: [],
    emailConfirmed:false
  };

    
      this.renderedValue_ciagnik_siodlowy = this.company.ciagnikSiodlowy.toString(); 
      this.renderedValue_ciagnik_trojosiowy = this.company.ciagnikTrojosiowy.toString(); 
      this.renderedValue_ciagnik_typu_mega = this.company.ciagnikTypuMega.toString();  
      this.renderedValue_ciagnik_typu_mega_regul = this.company.ciagnikTypuMegaReagul.toString(); 
      this.renderedValue_mega3m = this.company.mega3m.toString(); 
     
  }


 

  createForm() {
    this.reactForm  = new FormGroup({
      'nip': new FormControl('', [FormValidators.required]),
      'miejscowosc': new FormControl('', [FormValidators.required]),
      'nazwa': new FormControl('', [FormValidators.required]),
      'kodPocztowy': new FormControl('', [FormValidators.required]),
      'ulica': new FormControl('', [FormValidators.required]),
    });
  }
 
  get nip() { return this.reactForm.get('nip'); }
  get miejscowosc() { return this.reactForm.get('miejscowosc'); }
  get nazwa() { return this.reactForm.get('nazwa'); }
  get kodPocztowy() { return this.reactForm.get('kodPocztowy'); }
  get ulica() { return this.reactForm.get('ulica'); }

onSubmit() {
    alert("Form Submitted!");
}

  async getCompanyByNip()
  {

    let sprawdz: company;
    this.isShown = false;
    this.isShownDanezGus=false;
       sprawdz = await this.gusService.GetCompanyByNip(this.company.nip);
     if(sprawdz.nip == null)
     {
      this.toastr.info("Nie znaleziono w Gus")
     }
     else
     { 
      this.toastr.success("Pobrano z GUS")
      this.company = sprawdz;
      this.isShown = true;
      this.firstPageButton=false;
     }
     //this.company = await this.gusService.GetCompanyByNip(this.company.nip);
     this.reactForm.value.nip =  this.company.nip;
     this.reactForm.value.miejscowosc =  this.company.miejscowosc;
     this.reactForm.value.nazwa =  this.company.nazwa;
     this.reactForm.value.kodPocztowy =  this.company.kodPocztowy;
     this.reactForm.value.ulica =  this.company.ulica;
 
  
     this.company.ciagnikSiodlowy = 0;
     this.company.ciagnikTrojosiowy= 0,
     this.company.ciagnikTypuMega=  0,
     this.company.ciagnikTypuMegaReagul=  0   
     this.company.mega3m =  0   
     
     this.company.dataZakonczeniaDzialalnosci = "2019-01-06T17:16:40"
   
     this.CheckIsValid();

  if(await this.accountService.existsinmaszon(this.company))
  {   
     if(this.company.nip != null)
     {
     this.toastr.success("Nip jest w bazie M. " + this.company.nip);
     }
  }
}

  register3(){}

  instanceOfA(object: any): object is company {
    return true;
  }

  async register(){ 
 
    
    this.company.users = this.userRegisters;

     

    let result =  await this.accountService.register(this.company);

    if(this.instanceOfA(result)) {  
      console.log(result); 

       let mail = {
        "ToEmail":result.users[0].email,
        "subject":"Potwierdzenie",
        "body":"Body" ,
        "EmailConfirmedToken":result.users[0].emailConfirmedToken,
        "UserId":result.users[0].userId,
        "UserName":result.users[0].userName
      }
  
      
      let resspone =  this.accountService.sendEmailConfirm(mail).subscribe(response => {
        console.log(response);
       });


       this.nextBtnClick();
       this.cancel();  
        setTimeout(() => { this.router.navigate(['']);  }, 15000);  //5s

       this.company = null;
     }
     else{ 
      this.toastr.error("Nie udało się zarejstrować");
      this.company = null;
     }

  

    
  }

  cancel(){
    this.cancelRegister.emit(false);
  }


  public dlgBtnClick: EmitType<object> = () => {
    this.dialogObj.hide();
  }
  public dlgButtons: Object[] = [{ click: this.dlgBtnClick.bind(this), buttonModel: { content: 'Ok', isPrimary: true } }];
    @ViewChild('formElement') element: any;

   public Submit(): void {
    this.onFormSubmit();
  }
 public onFormSubmit(): void {
   this.dialogObj.show();
 
   this.UserDetails = {
    nip: '',
    firstname: '',
    lastname: '',
    username: '',
    password: '',
    email:'',
    mobile: ''
  };

 }


 onPasswordChange() {

  if(this.userRegisters[0].password==this.confirm_password)
  {
    this.confirm_password_boolean=true;
  }
  else{
    this.confirm_password_boolean=false;
  }
 // if (this.confirm_password.value == this.password.value) {
 //   this.confirm_password.setErrors(null);
 // } else {
 //   this.confirm_password.setErrors({ mismatch: true });
 // }
}


 


 public prevBtnClick() {
  let carouselObj = (document.querySelector(".e-carousel") as any).ej2_instances[0];
  carouselObj.prev();
    }
  
    public nextBtnClick() {
  let carouselObj = (document.querySelector(".e-carousel") as any).ej2_instances[0];
  this.CheckIsValid();
  this.CheckIsSecondValid();
  carouselObj.next();


  let elements = document.querySelectorAll('e-next');

  const btnElement = (<HTMLElement>this.ElByClassName.nativeElement).querySelector(
    'e-next'
    );

    var domRepresentation = document.getElementsByClassName('e-next');
 

    }

    toggleMore_mega3m()  {
      if (this.step + this.company.mega3m <= this.max) {
        this.company.mega3m = this.company.mega3m +  this.step;
        this.renderedValue_mega3m = this.company.mega3m.toString()  ;
      }
      this.CheckIsThirdValid();
    } 
    
    toggltoggleLess_mega3m() {
      if (this.company.mega3m - this.step >= this.min) {
        this.company.mega3m = this.company.mega3m - this.step;
        this.renderedValue_mega3m = this.company.mega3m.toString();
      }
      this.CheckIsThirdValid();
    } 
 

    toggleMore_ciagnik_siodlowy()  {
      if (this.step + this.company.ciagnikSiodlowy <= this.max) {
        this.company.ciagnikSiodlowy = this.company.ciagnikSiodlowy +  this.step;
        this.renderedValue_ciagnik_siodlowy = this.company.ciagnikSiodlowy.toString()  ;
      }
      this.CheckIsThirdValid();
    } 
  
    toggleLess_ciagnik_siodlowy() {
      if (this.company.ciagnikSiodlowy - this.step >= this.min) {
        this.company.ciagnikSiodlowy = this.company.ciagnikSiodlowy - this.step;
        this.renderedValue_ciagnik_siodlowy = this.company.ciagnikSiodlowy.toString();
      }
      this.CheckIsThirdValid();
    } 

    toggleMore_ciagnik_trojosiowy()  {
      if (this.step + this.company.ciagnikTrojosiowy <= this.max) {
        this.company.ciagnikTrojosiowy = this.company.ciagnikTrojosiowy + this.step;
        this.renderedValue_ciagnik_trojosiowy = this.company.ciagnikTrojosiowy.toString()  ;
      }
      this.CheckIsThirdValid();
    } 
  
    toggleLess_ciagnik_trojosiowy() {
      if (this.company.ciagnikTrojosiowy - this.step >= this.min) {
        this.company.ciagnikTrojosiowy = this.company.ciagnikTrojosiowy - this.step;
        this.renderedValue_ciagnik_trojosiowy = this.company.ciagnikTrojosiowy.toString();
      } 
      this.CheckIsThirdValid();
    } 

    toggltoggleLess_ciagnik_trojosiowy_typu_mega()  {
      if (this.company.ciagnikTypuMega - this.step >= this.min) {
        this.company.ciagnikTypuMega = this.company.ciagnikTypuMega - this.step;
        this.renderedValue_ciagnik_typu_mega = this.company.ciagnikTypuMega.toString();
      } 
      this.CheckIsThirdValid();
    } 
    toggleMore_ciagnik_trojosiowy_typu_mega() {
  
      if (this.step + this.company.ciagnikTypuMega <= this.max) {
        this.company.ciagnikTypuMega = this.company.ciagnikTypuMega + this.step;
        this.renderedValue_ciagnik_typu_mega = this.company.ciagnikTypuMega.toString()  ;
      }
      this.CheckIsThirdValid();
    } 



    toggltoggleLess_ciagnik_trojosiowy_typu_mega_regul()  {
      if (this.company.ciagnikTypuMegaReagul - this.step >= this.min) {
        this.company.ciagnikTypuMegaReagul = this.company.ciagnikTypuMegaReagul - this.step;
        this.renderedValue_ciagnik_typu_mega_regul = this.company.ciagnikTypuMegaReagul.toString();
      }

      this.CheckIsThirdValid();
    } 
    toggleMore_ciagnik_trojosiowy_typu_mega_regul() { 
      if (this.step + this.company.ciagnikTypuMegaReagul <= this.max) {
        this.company.ciagnikTypuMegaReagul = this.company.ciagnikTypuMegaReagul + this.step;
        this.renderedValue_ciagnik_typu_mega_regul = this.company.ciagnikTypuMegaReagul.toString()  ;
      }

      this.CheckIsThirdValid();
    } 

 
    getSomeClass(){
     
    }
    CheckIsSecondValid() {
      if (this.IsValidEmail(this.userRegisters[0].email) 
      && this.IsValidMobile(this.userRegisters[0].mobile)  
       && this.userRegisters[0].password != "" 
       && this.userRegisters[0].userName != ""  
       &&  this.company.miejscowoscPoczty != ""   
       )
    {
      this.secondPageButton = true;
    }
    else
    {
      this.secondPageButton = false;
    }


    this.CheckIsThirdValid();

  }




  IsValidMobile(mobile)
{
 
  if (mobile == "" ) { 
    return false;
  }  
  if (!isNaN(mobile.replace(/\s/g, ""))) {
    
    if(mobile.toString().length > 6)
    {
   
      return true;
    }
      else
      return false;
  }
  else  {     
    return false;
  } 
  return false;
}


IsValidEmail(  email)
{
 
  if (email == "" ) {
     
    return false;
  }

 var re = new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$");
 
  if (re.test(email)) { 
    return true;
  }
  else  {     
    return false;
  } 
}


  CheckIsThirdValid() {

        if(this.secondPageButton == false)
        {
        this.thirdPageButton = false
        return;
        }

    if (this.renderedValue_ciagnik_siodlowy != "0"  
    ||   this.renderedValue_ciagnik_trojosiowy  != "0" 
    || this.renderedValue_ciagnik_typu_mega!= "0"  
    ||   this.renderedValue_ciagnik_typu_mega_regul != "0"   
    ||   this.renderedValue_mega3m != "0"   
     )
  {
    this.thirdPageButton = true;
  }
  else
  {
    this.thirdPageButton = false;
  }
}


  

  AcceptedCondition()
  {

      
    this.myVar1 = !this.myVar1;
    
    this.CheckIsValid() ;

  }
   
  CheckIsValid() {
    if (this.myVar1 && this.company.nip != ""  && this.company.miejscowosc != ""  && this.company.nazwa != "" && this.company.kodPocztowy != ""    && this.company.nrNieruchomosci != "")
     {
    this.firstPageButton = false;
 
   }
     else
   {
   this.firstPageButton = true;
 
  }
}


saverange2(newValue, area) {

  if(area == "pass2")
  {
 
         if(this.userRegister.password != newValue)
         { 
           this.passwordConfirm=true;
         } 
         else 
         {
          this.passwordConfirm=false;
         }
  }
}

saverange(newValue, area) {
  this.CheckIsSecondValid();
 // this.range = newValue;
 // this.Platform.ready().then(() => {
 //    this.rootRef.child("users").child(this.UserID).child('range').set(this.range)

 var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;


 if(area == "pass1")
 {

        if(newValue.length > 0 && newValue != "password")
        { 
          if(format.test(newValue)){
            this.passwordContains = false;
          } else {
          this.passwordContains = true;
          }
        } 
 }

 if(area == "pass2")
 {

        if(this.userRegisters[0].password != newValue)
        { 
          this.passwordConfirm=true;
        } 
        else 
        {
         this.passwordConfirm=false;
        }
 }


//passwordMin: boolean = false ; 
//passwordContains: boolean = false ;
//passwordConfirm: boolean = false ;
 switch(area) {
  case 'email':
    this.userRegisters[0].email = newValue;
    this.CheckIsSecondValid();
    break;
  case 'mobile':
    this.userRegisters[0].mobile = newValue;
    this.CheckIsSecondValid();
    break;
    case 'userName':
      this.userRegisters[0].userName = newValue;
      this.CheckIsSecondValid();
      break;
    case 'pass1':
        this.userRegisters[0].password = newValue;
        this.CheckIsSecondValid();
        break;
    case 'pass2':
        this.company.miejscowoscPoczty  = newValue;
         this.CheckIsSecondValid();
         break;
  default:
    // code block
}
   
} 

}
export interface UserDetails {
  nip: string;
  firstname: string;
  lastname: string;
  username: string;
  password: string;
  email: string;
  mobile: string;
}





export function ConfirmedValidator(controlName: string, matchingControlName: string){
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
          return;
      }
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ confirmedValidator: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}

