import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TimerclockService } from '../_services/timerclock.service';
import { ConfigService } from '../_services/configService';

@Injectable()
export class RemainderInterceptorInterceptor implements HttpInterceptor {
  
  baseUrl: string;

  constructor(private router: Router, private toastr: ToastrService
    
    //, private  mts: TimerclockService 
    ) {
     
    }
  
  
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    //var t = this.mts.download2222()
    return next.handle(request);
  }
}
