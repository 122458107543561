import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule} from '@angular/common/http' 
 
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavComponent } from './nav/nav.component';
import { HomeComponent } from './home/home/home.component';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ResgisterComponent } from './resgister/resgister.component';
import { ZleceniaComponent } from './zlecenia/zlecenia.component';
import { RouterModule, Routes } from '@angular/router';
 
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './_guards/auth.guard'; 
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { SharedModule } from './_modules/shared.module';
import { TestErrorsComponent } from './errors/test-errors/test-errors.component';
import { ErrorInterceptor } from './_interceptors/error.interceptor'; 
import { NotFoundComponent } from './errors/not-found/not-found.component';
import { ServerErrorComponent } from './errors/server-error/server-error.component';  
import {CUSTOM_ELEMENTS_SCHEMA} from '@angular/core'; 
import { MojeTransportyComponent } from './moje-transporty/moje-transporty.component';
import { MojaFlotaComponent } from './moja-flota/moja-flota.component';
import { DaneFirmyComponent } from './dane-firmy/dane-firmy.component'; 
import { PasswordPatternDirective } from './resgister/directives/password-pattern.directive'; 
import { GoogleMapsModule } from '@angular/google-maps';  
import { FieldErrorDisplayComponent } from './dane-firmy/field-error-display.component'; 
import { EmailConfirmComponent } from './user-results/email-confirm/email-confirm/email-confirm.component';
import { MojeKontaComponent } from './moje-konta/moje-konta/moje-konta.component'; 
import { TreeGridModule } from '@syncfusion/ej2-angular-treegrid'; 
import { CarriersComponent } from './carriers/carriers.component';
import { PasswordRemainderComponent } from './moje-konta/password-remaind/password-remainder/password-remainder.component';
import { RestorePasswordComponent } from './moje-konta/restore-password/restore-password.component';
//import { EJAngular2Module } from 'ej-angular2';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { CheckEmailBoxComponent } from './moje-konta/check-email-box/check-email-box.component';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ConfigModule, ConfigService } from './_services/configService';
import { LoaderComponent } from './loader/loader.component';
import { LoaderInterceptor } from './_interceptors/loader.interceptor';
import { RegulaminComponent } from './moje-konta/regulamin/regulamin.component';
import { ContactsComponent } from './moje-konta/contacts/contacts.component';
import { NegotiationComponent } from './moje-konta/negotiation/negotiation.component'; 
import { RemainderInterceptorInterceptor } from './_interceptors/remainder-interceptor.interceptor';
import { ConfirmationDialogComponent } from './_services/confirmation-dialog/confirmation-dialog/confirmation-dialog.component';
import { TimerclockService } from './_services/timerclock.service';
import { DialogService } from './_services/confirmation-dialog/dialog';
import { MatButtonModule } from '@angular/material/button';
import { StatusDialogComponent } from './_services/status-dialog/status-dialog.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

const routes: Routes = [
   { path: 'second-component', component: ResgisterComponent },
  { path: '', component: HomeComponent },
  { path: 'orders', component: ZleceniaComponent, canActivate:[AuthGuard] },
  

    {
      path: 'carriers', component: CarriersComponent,
      canActivate: [AuthGuard],
      data: {
        role: 'ROLE_ADMIN'
      }
    },
    { path: 'alert', component: ConfirmationDialogComponent },
    { path: 'status', component: StatusDialogComponent },
  { path: 'owntransport', component: MojeTransportyComponent, canActivate:[AuthGuard] },
  { path: 'owntruck', component: MojaFlotaComponent, canActivate:[AuthGuard] },
  { path: 'company', component: DaneFirmyComponent, canActivate:[AuthGuard] },
  { path: 'users', component: MojeKontaComponent, canActivate:[AuthGuard] },
  { path: 'forwarders', component: ContactsComponent, canActivate:[AuthGuard] },

  
  {path: 'negotiation', component: NegotiationComponent},
  {path: 'regulations', component: RegulaminComponent},
  {path: 'checkemailboxcomponent', component: CheckEmailBoxComponent},
  {path: 'passwordremainder', component: PasswordRemainderComponent},
  {path: 'changepasswordbytoken', component: RestorePasswordComponent},
  {path: 'emailconfirm', component: EmailConfirmComponent},
  {path: 'errors', component: TestErrorsComponent},
  {path: 'not-found', component: NotFoundComponent},
  {path: 'server-error', component: ServerErrorComponent},
  { path: '**', component: AppComponent, pathMatch:'full' },
];

@NgModule({
  declarations: [
    ZleceniaComponent,
    AppComponent,
    NavComponent,
    HomeComponent,
    ResgisterComponent, 
    TestErrorsComponent,
    NotFoundComponent,
    ServerErrorComponent,   CarriersComponent, PasswordPatternDirective  , 
    FieldErrorDisplayComponent ,
    DaneFirmyComponent, MojeTransportyComponent, MojaFlotaComponent, EmailConfirmComponent, MojeKontaComponent, 
    PasswordRemainderComponent, RestorePasswordComponent, CheckEmailBoxComponent, LoaderComponent, RegulaminComponent, ContactsComponent, NegotiationComponent, ConfirmationDialogComponent, StatusDialogComponent 
  ],
  imports: [
    BrowserModule,   DropDownListModule, 
    HttpClientModule,HttpClientJsonpModule,
    BrowserAnimationsModule, 
    SharedModule,  TreeGridModule ,MatDialogModule,MatButtonModule,
     RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})  ,  // EJAngular2Module.forRoot()
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi :true, },
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true, },
    {provide: HTTP_INTERCEPTORS, useClass: RemainderInterceptorInterceptor, multi: true, },
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    { provide: LOCALE_ID, useValue: 'pl' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'PLN' },
   {provide: LocationStrategy, useClass: PathLocationStrategy},

  ConfigService, TimerclockService, DialogService,  ConfigModule.init(),
],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  
})
export class AppModule { }
platformBrowserDynamic().bootstrapModule(AppModule);