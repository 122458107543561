import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastrModule } from 'ngx-toastr';
import { FormValidator,     MaskedTextBoxModule, NumericTextBox, NumericTextBoxModule, SliderModule, TextBoxModule, UploaderModule } from '@syncfusion/ej2-angular-inputs'; 
import { DialogModule, TooltipModule } from '@syncfusion/ej2-angular-popups'; 
import { CarouselModule,  SidebarModule,  TabModule,  ToolbarComponent  } from '@syncfusion/ej2-angular-navigations';   
import { GridAllModule,  GridModule} from '@syncfusion/ej2-angular-grids';
import {MatButtonModule} from '@angular/material/button';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ValidateEqualModule } from 'ng-validate-equal'; 
import { GoogleMapsModule } from '@angular/google-maps';    
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import {
  NgxMatDateAdapter,
  NgxMatDateFormats,
  NgxMatDatetimePickerModule, 
  NgxMatNativeDateModule, 
  NgxMatTimepickerModule, 
  NGX_MAT_DATE_FORMATS
} from '@angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { CustomNgxDatetimeAdapter } from '../CustomNgxDatetimeAdapter';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns'; 
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MojeTransportyComponent } from '../moje-transporty/moje-transporty.component';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
 
 

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    
    BsDropdownModule.forRoot()  ,
    ToastrModule.forRoot({
     positionClass :'toast-bottom-right'
   }),
   MaskedTextBoxModule, NumericTextBoxModule, DialogModule, CarouselModule  ,GridModule ,  
    FormsModule, ReactiveFormsModule, SidebarModule, SliderModule, 
   ValidateEqualModule, GoogleMapsModule,     RichTextEditorModule, ButtonModule,  NgxMatDatetimePickerModule,
   NgxMatTimepickerModule,
   NgxMatNativeDateModule ,  MatDatepickerModule,NgxMatMomentModule,
   MatInputModule, UploaderModule, TabModule , CheckBoxModule, MatProgressSpinnerModule, MultiSelectModule ,  TooltipModule
],
  exports:[ 
    BsDropdownModule,
    ToastrModule, MaskedTextBoxModule, NumericTextBoxModule, TextBoxModule, DialogModule,
     CarouselModule ,GridModule ,   FormsModule, ReactiveFormsModule , 
    SidebarModule, SliderModule, ValidateEqualModule, GoogleMapsModule,   
     RichTextEditorModule, ButtonModule ,  NgxMatDatetimePickerModule,
     NgxMatTimepickerModule,
     NgxMatNativeDateModule,   MatDatepickerModule, NgxMatMomentModule,
     MatInputModule, UploaderModule, TabModule, CheckBoxModule , MatProgressSpinnerModule, MultiSelectModule,  TooltipModule ],
    
     providers: [{ provide: MAT_DATE_LOCALE, useValue: 'pl' }, 
     { provide: MAT_DIALOG_DATA, useValue: {} },
     { provide: MatDialogRef, useValue: {} },
     
    ]

      
  })


export class SharedModule { }
